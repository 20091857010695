<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="cancel-btn-margin bg-white border-0 default-text-light-black-color cursor-pointer border-button"
        [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button class="save-btn-margin inline-flex height-44" (click)="onNextBtnClick()"  [label]="nextBtnLabel"></p-button>
</div>
<p-tabView [(activeIndex)]="activeIndex">
    @for (tab of eventAttributeData?.tabs; track tab; let i = $index) {
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
                <i class="mi-lg details"></i>
                <span>{{ cs.getLabelValue('event.tabs.'+tab.tabCode+'.label',attributeLabels,tab.tabCode) }}</span>
            </div>
        </ng-template>
        <div class="">
            <div>
                <app-mi-form [attributeLabels]="attributeLabels" [attributeValues]="attributeValues"
                    [moduleName]="moduleName" [groups]="tab.groups" (saveData)="onSaveEvents($event)" [showImg]="true"
                    [submitBtnText]="nextBtnLabel" [saveBtnId]="'details'" [activeIndex]="activeIndex" [isMultipleFilesUpload]="false"
                    [multiSelectDisplay]="multiSelectDisplay" [dateFormat]="dateFormat"
                    [previousBtnText]="'Cancel'" (previousClick)="onCancel()"></app-mi-form>
            </div>
        </div>
    </p-tabPanel>
    }
</p-tabView>