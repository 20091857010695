<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white border-button default-text-light-black-color cursor-pointer"
        [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button class="flex align-items-center justify-content-center cursor-pointer add-shift" (click)="onSaveShift()"
        [label]="saveBtnLabel"></p-button>
</div>

<p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
                <i class="mi-lg mi-driver mr-2"></i>
                <span translate>shift.lbl_shift</span>
            </div>
        </ng-template>
        <div class="shift-details mt-2">
                <div class="grid pl-3 pr-3">
                    <div class="col-6 md:col-12 lg:col-6 tab-group">
                        <form  [formGroup]="addShiftForm">
                        <div class="card p-fluid">
                            <label class="w-full mt-0 uppercase text-lg font-semibold" translate>shift.lbl_shift_details</label>
                            <div class="w-full formgrid grid pt-3">
                                <div class="col-12 flex align-items-center">
                                    @for (shift of shiftType; track shift; let i = $index) {
                                    <p-radioButton
                                       formControlName="type"
                                       [inputId]="shift.key"
                                       [value]="shift.key"
                                       (onClick)="shiftTypeChange(shift)"
                                     />
                                       <label [for]="shift.key" class="ml-2 mr-3">
                                        {{shift.name}}
                                       </label>
                                    }

                                </div>
                            </div>

                                @if (selectedShiftType === SHIFT_TYPE.SHIFT_TEMPLATE) {
                                    <div class="w-full formgrid grid pt-3">
                                        <div class="field col-12 align-content-end md:col-6">
                                            <label class="flex" translate>shift.lbl_shift_template   <span class="required ml-1">*</span></label>
                                        </div>
                                        <div class="col-12">
                                            <p-dropdown [options]="shiftTemplates" formControlName="shiftTemplate" (onChange)="onShiftTemplateChange($event)" optionLabel="shiftName" optionValue="shiftTemplateId" 
                                                [placeholder]="selectShiftPlaceholder"></p-dropdown>
        
                                            @if (addShiftForm.controls.shiftTemplate.errors && (addShiftForm.controls.shiftTemplate.dirty || addShiftForm.controls.shiftTemplate.touched)) {
                                                <div class="form-errors text-xs">
                                                <div [hidden]="!addShiftForm.controls.shiftTemplate.errors.required" class="text-err" translate>
                                                    shift.shift_template_is_required
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                }
                               

                                <div class="w-full formgrid grid pt-3">
                                    <div class="field col-12 align-content-end md:col-6">
                                        <label class="flex" translate>shift.lbl_name   <span class="required ml-1">*</span></label>
                                    </div>
                                    <div class="col-12">
                                        <input type="text" (blur)="shiftNameBlur()" pInputText formControlName="name" class="w-full" />
    
                                        @if (addShiftForm.controls.name.errors && (addShiftForm.controls.name.dirty || addShiftForm.controls.name.touched)) {
                                            <div class="form-errors text-xs">
                                            <div [hidden]="!addShiftForm.controls.name.errors.required" class="text-err" translate>
                                                shift.name_is_required
                                            </div>
                                            <div [hidden]="!addShiftForm.controls.name.errors.pattern" class="text-err" translate>
                                                shift.shift_name_pattern
                                            </div>
                                            
                                        </div>
                                        }
                                    </div>
                                </div>
    
                

                            <div class="w-full formgrid grid pt-3">
                                <div class="col-6 pl-0 pr-0">
                                    <div class="field col-12 align-content-end">
                                        <label class="flex" translate>shift.lbl_shift_start_date <span class="required ml-1">*</span></label>
                                    </div>
                                    <div class="col-12">
                                        <p-calendar formControlName="startDate" [showIcon]="true" [dateFormat]="dateFormat" appendTo="body"
                                        (onSelect)="onStartDateSelect($event)" [minDate]="currentDate"></p-calendar>
                                    </div>
                                    @if (addShiftForm.controls.startDate.errors && (addShiftForm.controls.startDate.dirty || addShiftForm.controls.startDate.touched)) {
                                        <div class="form-errors col-12 text-xs">
                                        <div [hidden]="!addShiftForm.controls.startDate.errors.required" class="text-err" translate>
                                            shift.start_date_is_required
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div class="col-6 pl-0 pr-0">
                                    <div class="field col-12 align-content-end">
                                        <label class="flex" translate>shift.lbl_end_date <span class="required ml-1">*</span></label>
                                    </div>
                                    <div class="col-12">
                                        <p-calendar formControlName="endDate" [showIcon]="true" [dateFormat]="dateFormat" appendTo="body"
                                        (onSelect)="onEndDateSelect($event)" [minDate]="startDate" ></p-calendar>
                                    </div>
                                    @if (addShiftForm.controls.endDate.errors && (addShiftForm.controls.endDate.dirty || addShiftForm.controls.endDate.touched)) {
                                        <div class="form-errors col-12 text-xs">
                                        <div [hidden]="!addShiftForm.controls.endDate.errors.required" class="text-err" translate>
                                            shift.end_date_is_required
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>

                            <div class="w-full formgrid grid pt-3">
                                <div class="col-6 pl-0 pr-0">
                                    <div class="field col-12 align-content-end">
                                        <label class="flex" translate>shift.lbl_start_time</label>
                                    </div>
                                    <div class="col-12">
                                        <p-calendar formControlName="startTime" icon="mi-lg mi-endofshift" [showIcon]="true" [showTime]="true" timeOnly="true"
                                            [hourFormat]="hourFormat" [disabled]="disableShiftTimeFields"
                                            (onSelect)="onStartTimeSelect($event)"></p-calendar>
                                    </div>
                                </div>
                                <div class="col-6 pl-0 pr-0">
                                    <div class="field col-12 align-content-end">
                                        <label class="flex" translate>shift.lbl_end_time</label>
                                    </div>
                                    <div class="col-12">
                                        <p-calendar formControlName="endTime" icon="mi-lg mi-endofshift"  [showIcon]="true" [showTime]="true" timeOnly="true"
                                          [minDate]="startTime"  [hourFormat]="hourFormat" [disabled]="disableShiftTimeFields"></p-calendar>
                                    </div>
                                </div>
                            </div>

                            <div class="w-full formgrid grid pt-3 pb-3">
                                <div class="col-12">
                                    <p-selectButton formControlName="days" [options]="dayOptions" [multiple]="true" optionLabel="label"
                                        optionValue="value"></p-selectButton>
                                </div>
                            </div>

                        </div>
                        <!-- Add Break -->
                        @if (showBreak) {
                            <app-add-edit-break
                                [form]="addShiftForm"
                                [selectedShiftType]="selectedShiftType"
                                [shiftTemplateBreakList]="shiftTemplateBreakList"
                                [isEdit]="isEdit"
                            ></app-add-edit-break>
                        }
                    </form>
                    </div>

                    <div class="col-6 md:col-12 lg:col-6 pr-0 tab-group">
                        <div class="card p-fluid">
                            <label class="w-full mt-0 uppercase text-lg font-semibold"
                                translate>shift.lbl_assignment</label>
                            <div class="w-full formgrid grid pt-3">
                                <form [formGroup]="addShiftForm">
                                    <div class="col-12 flex align-items-center pb-1">
                                        @for (assignment of assignmentType; track assignment; let i = $index) {
                                        <p-radioButton
                                          formControlName="assignmentType"
                                          [inputId]="assignment.key"
                                          [value]="assignment.key"
                                          (onClick)="assignmentTypeChange(assignment)"
                                         />
                                           <label [for]="assignment.key" class="ml-2 mr-3">
                                            {{assignment.name}}
                                           </label>
                                        }

                                    </div>
                                </form>

                                <div class="field col-12 mt-5">
                                    <p-panel>
                                        <ng-template pTemplate="header">
                                          <div class="flex default-text-black-color"><span class="font-bold">{{selectedAssignmentType === DRIVER_ASSIGNMENT_TYPE.INDIVIDUAL_DRIVER ? ('lbl_drivers' | translate) : ('shift.groups' | translate)}}</span>
                                            <div class="default-text-black-color">&nbsp;({{ selectedAssignmentType === DRIVER_ASSIGNMENT_TYPE.INDIVIDUAL_DRIVER ? filteredDriverList.length : filteredGroupList.length}})</div>
                                          </div>
                                        </ng-template>
                                        <div>
                                            <form [formGroup]="addShiftForm">
                                                <div class="col-12 mt-3 mb-3">
                                                    <input type="text" formControlName="searchText" (input)="searchDriverOrGroup()" pInputText class="w-full" [placeholder]="selectedAssignmentType === DRIVER_ASSIGNMENT_TYPE.INDIVIDUAL_DRIVER ? ('shift.search_driver' | translate) : ('shift.search_group' | translate)" />
                                                </div>
                                            </form>
                                        </div>
                                        <div id="assignment-scroll" class="all-drivers-container">  
                                          <div  class="all-drivers-list mt-4">
                                            @if (selectedAssignmentType === DRIVER_ASSIGNMENT_TYPE.INDIVIDUAL_DRIVER) {
                                                @for (driver of filteredDriverList; track driver) {
                                                    <div class="col-12 flex mb-3 align-items-center individual-driver">
                                                        <p-checkbox   (onChange)="onIndividualCheckboxChange($event, driver, options)" binary="true"
                                                         name="driver_new"  [(ngModel)]="driver.isSelected"  ></p-checkbox>
                                                         <img [src]="driver.profilePhoto || defaultLoaderImg"class="ml-3 driver-image" />
                                                        <div class="col-12 driver-name-div ml-2">
                                                            <span class="driver-name">{{ driver?.values?.first_name + ' ' + driver?.values?.last_name}}</span>
                                                            <span class="driver-code">{{ driver?.values?.unique_id }}</span>
                                                        </div>
                                                    </div>
                                                }
                                            } @else {
                                                @for (group of filteredGroupList; track group) {
                                                    <div class="col-12 flex mb-3 individual-driver">
                                                        <p-checkbox  (onChange)="onGroupCheckboxChange($event, group)" [binary]="true"
                                                          name="group"   [(ngModel)]="group.isSelected"></p-checkbox>
                                                        <div class="col-12 driver-group-div ml-3">
                                                            <span class="driver-name">{{group?.values?.group_name}}</span>
                                                            <span class="driver-group-count">({{group?.values?.assigned_drivers?.length ? group?.values?.assigned_drivers?.length : 0}} Drivers)</span>
                                                        </div>
                                                    </div>
                                                }
                                            }
                                          </div>
                                        </div>
                                      </p-panel>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-content-end flex-wrap mr-1 mb-3">
                            <button pButton class="mr-2 mt-4 border-600 surface-ground default-text-light-black-color cursor-pointer border-button"
                                [label]="'cancel' | translate" (click)="onCancel()"></button>
                            <p-button class="flex align-items-center justify-content-center mt-4 cursor-pointer" (click)="onSaveShift()"
                                [label]="saveBtnLabel"></p-button>
                        </div>
                    </div>
                </div>
        </div>
       
    </p-tabPanel>
</p-tabView>