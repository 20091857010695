<div class="select-passenger-box">
<div class="text-xl font-semibold mb-3 uppercase " translate>bookings.search_and_select_passenger_header <span class="required">&nbsp;*</span></div>
<div class="flex">
  <div class="flex-grow-1 flex align-items-center justify-content-center mr-2">
    <p-autoComplete [(ngModel)]="selectedPassenger" [suggestions]="suggestions" (onSelect)="onSelectPassenger($event)"
      (completeMethod)="search($event)"
      class="w-full" [placeholder]="'bookings.search_passenger_placeholder'|translate" field="name" [multiple]="true">
      <ng-template let-passenger pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div class="passenger-popup bold-name">
            @if(passenger.passenger_profile_image){
              <img [src]="passenger.passenger_profile_image || defaultLoaderImg" class="vertical-align-middle border-round-sm list-img mr-1" />

            }@else{
              <i class="mi-lg mi-default-user wh-20-m-5 vertical-align-middle border-round-sm"></i>
            }
            <span class="ellipsis mr-2">
              {{passenger.name}}
            </span>
            <span class="unique ellipsis mr-2">
              ({{passenger.unique_id}}) ({{passenger.passenger_type}})
            </span>
          </div>

        </div>
      </ng-template>

    </p-autoComplete>
  </div>
  <div class="flex-none flex align-items-center justify-content-center font-bold ">
      <p-button [label]="'bookings.add_new' | translate" class="add-new" [outlined]="true"
      icon="pi pi-plus-circle" (onClick)="onAddNew()"></p-button>
  </div>
</div>
@if(this.addedPassengerList.length > 0){
<app-added-passenger-input [addedPassengerList]="addedPassengerList" [selectAddedPassenger]="selectAddedPassenger" (onAddedPassenger)="onSelectAddedPassenger($event)" (onCloseRemove)="onRemove($event)" ></app-added-passenger-input>
}
@if(selectAddedPassenger && this.addedPassengerList.length > 0){
<div class="formgrid grid mr-2">
  <div class="field col p-2">
    <i class="{{miIcons.BOOKING_PASSENGER_TYPE_PHOTO}} inline-block cursor-pointer icon-color lock-icon mr-3 mb-0"></i>
    <span class="ellipsis text-color" translate>
      bookings.lbl_photo
    </span>
  </div>
  <div class="field value col p-2">
    @if(selectAddedPassenger?.profile_image){
      <p-image 
      [src]="selectAddedPassenger?.profile_image" 
      alt="Image" 
      width="35"
      class="vertical-align-middle border-round-sm hw-30 list-img mr-2"
      [preview]="true" />

    }@else {
      <p-image 
      [src]="defaultImage" 
      alt="Image" 
      width="35"
      class="vertical-align-middle border-round-sm hw-30 list-img mr-2"
      [preview]="true" />
    }
  </div>

</div>

@for (group of groups; track $index) {

@if(!excludeGroupLabel.includes(group.code)){
<div class="mb-2 mt-4">
  <label class="font-semibold previleges-header "
    translate>{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>
</div>
}
@for (field of group.fields; track $index) {
@if (!excludeAttributeInLoop.includes(field.attributeCode)) { 

<div class="formgrid grid mr-2">
  <div class="field col p-2 text-500 lock-text-wrapper">
    @if (group.code === DETAILS_GROUP_CODE) {
    <i class="{{informationPhoto[field.attributeCode]}} inline-block lock-icon cursor-pointer icon-color mr-2"></i>
    }@else {
    <i class="{{miIcons.BOOKING_LOCK}} inline-block cursor-pointer icon-color lock-icon mr-2 "></i>
    }
    <label class="flex-1 flex font-normal mb-0 ml-2 field-label-wrapper" translate>
       {{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
    </label>
  </div>
  @if(!field.isFormVisible){
    <div class="field value col p-2 text-color">

    @if(field.attributeCode === 'void_date_time'){
          @if(field?.attributeValue){
            {{field?.attributeValue| dateformat}}
           }@else {
             -
           }
    }@else { 
      {{field.attributeValue|| '-'}}
    }

  </div>
  }@else if(field.isFormVisible){
  <div class="field value col p-2 font-semibold text-color " [ngClass]="{'edit': field.attributeCode !== 'trip_notification'}">
    <form [formGroup]="miFormGroup" class="booking-passenger">
      <div class="flex align-items-center">
        <div class="flex-grow-1 align-items-center justify-content-center">
          <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [showImg]=""
            [moduleName]="moduleName"></app-mi-fields>
        </div>
        <div class="flex align-items-center justify-content-center ">
          <p-button class="inline-flex small-btn" (click)="submitData($event)"
            [label]="'bookings.save'| translate"></p-button>
        </div>
      </div>
    </form>
  </div>
  }
</div>

}
}
}

}



<p-toast></p-toast>

</div>