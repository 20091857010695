import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { forkJoin } from 'rxjs';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { ModuleHeaderComponent } from '../../../shared/components/module-header/module-header.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { Column } from '../../../vehicles/models/attribute.models';
import { EntityList } from '../../../vehicles/models/entity.models';
import { ListResponse, Values } from '../../../vehicles/models/listResponse.models';
import { EntityService } from '../../../shared/services/entity.service';
import { ConfigService } from '../../../shared/services/config.service';

@Component({
  selector: 'app-manage-locations',
  standalone: true,
  imports: [BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, MITableComponent, AsyncPipe],
  templateUrl: './manage-locations.component.html',
  styleUrl: './manage-locations.component.scss'
})

export class ManageLocationsComponent implements OnInit {
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLElement>;
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];
  @Input() moduleName: string;
  filterList;
  value: number = 50;
  btnLabel: string;
  items: MenuItem[] | undefined;
  isShowMenu: boolean = true;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  buttons: any[] = [];
  country: Country;
  language: Language;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "",
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  home: MenuItem = {};
  isModal: boolean = false;
  selectedColumns!: Column[];
  deleteVehicleMsg: string;
  deleteVehicleHeaderMsg: string;
  showMenu: boolean = false;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  bulkDeleteLabel: string = this.cs.getLabel('manage_locations.delete');
  bulkUpdateLabel: string = this.cs.getLabel('manage_locations.update_location');
  filterFieldsArray: any[];
  isReady: boolean = false;
  attributeData: any;
  data;
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  currentRow;
  visiblePrintLabel: boolean = false;
  bulkPrintLabel = 'Print';
  showBulkUpdateDialog: boolean = false;
  activeLocationHeaderMsg: string;
  inactiveLocationHeaderMsg: string;
  activeLocationMsg: string;
  inactiveLocationMsg: string
  visible: boolean = false;
  isActive: boolean;
  activeRowItems: MenuItem[] | undefined;
  totalRecords: number = 0;
  bulkUpdateHeader: any;
  attributeIdOfLocationStatus: any;
  reviewHeader: any;
  btnUpdate: any;
  entityNameToDisplay: any;
  miIcons = AppIcons;

  constructor(private manageLocationService: EntityService,
    private cs: CommonBindingDataService, private confirmationService: ConfirmationService, private cd: ChangeDetectorRef,
    private router: Router, private messageService: MessageService, private actRoute: ActivatedRoute,
    private configService: ConfigService) { }

  ngOnInit() {
    this.initializeOtherVariablesAndAPI();
    this.setUpRoutePath();
    this.setUpRowItems();
    this.setupBulkSelectionsItems();
    this.setupModuleHeaderSecondaryBtnMenuItems();
  }

  initializeOtherVariablesAndAPI() {
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.btnLabel = this.cs.getLabel('manage_locations.lbl_new_locations');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.bulkUpdateHeader = this.cs.getLabel('manage_locations.update_location');
    this.entityNameToDisplay = this.cs.getLabel('manage_locations.lbl_entity_name_display');
    this.reviewHeader = this.cs.getLabel('manage_locations.lbl_review_location');
    this.btnUpdate = this.cs.getLabel('manage_locations.btn_bulk_update');
    this.deleteVehicleMsg = this.cs.getLabel('manage_locations.message.confirm_delete_msg');
    this.deleteVehicleHeaderMsg = this.cs.getLabel('manage_locations.message.confirm_delete_header');
    this.activeLocationMsg = this.cs.getLabel("manage_locations.active_location_message");
    this.inactiveLocationMsg = this.cs.getLabel("manage_locations.inactive_location_message");
    this.activeLocationHeaderMsg = this.cs.getLabel("manage_locations.active_location_header_message");
    this.inactiveLocationHeaderMsg = this.cs.getLabel("manage_locations.inactive_location_header_message");
    this.getAttributeData();
    this.getData();
  }

  setUpRoutePath() {
    this.routePath = [
      { label: "Manage Locations", routerLink: 'manage-locations/list' },
    ];
  }

  setUpRowItems() {
    this.rowItems = [
      {
        label: "Edit",
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        }
      },
      {
        label: "Make Location Inactive",
        icon: AppIcons.OVERDUE + " wh-16 bg-red-500",
        iconClass: "bulk-update-icon",
        visible: true,
        command: () => {
          this.makeLocationActiveInactive(this.currentRow);
        },
      },
    ];
  }

  setupBulkSelectionsItems() {
    this.bulkSelectionsItems = [
      {
        label: this.bulkUpdateLabel,
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: () => {
          this.visible = true;
          this.tableRef.showBulkRowDialog();
        },
      },
      {
        label: this.bulkDeleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: (e) => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.BULK);
        },
      },
    ];
  }

  setupModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.cs.getLabel('manage_locations.import_location'),
        icon: AppIcons.UPLOAD + " wh-16",
        command: () => {
          const el: HTMLElement = this.uploader.nativeElement;
          el.click();
        }
      },
      {
        label: this.cs.getLabel('vehicle.download_important_template'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.manageLocationService.downloadImportTemplate(AppSettings.ENTITY_CODE.LOCATION).subscribe(
            (result: any) => {
              const a = document.createElement('a');
              a.href = URL.createObjectURL(result);
              a.download = 'Import Template.xlsx';
              a.click();
            },
            err => {
              console.log(err);
            });
        }
      },
      {
        label: this.cs.getLabel('vehicle.download_important_guide'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.manageLocationService.downloadImportGuide(AppSettings.ENTITY_CODE.LOCATION).subscribe(result => {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(result);
            a.download = 'Import Guide.xlsx';
            a.click();
          },
            err => {
              console.log(err);
            });
        }
      },
      {
        separator: true
      },
      {
        label: this.cs.getLabel('vehicle.export_xlsx_csv'),
        icon: AppIcons.EXPORT + " wh-16",
      },
    ];
  }

  makeLocationActiveInactive(currentRow) {
    const isActive = currentRow.location_status === AppSettings.LOCATION_STATUS.ACTIVE
    const headerMsg = isActive ? this.cs.getLabel('manage_locations.message.inactive_location_header_message') : this.cs.getLabel('manage_locations.message.active_location_header_message');
    const bodyMsg = isActive ? this.cs.getLabel('manage_locations.message.inactive_status_message') : this.cs.getLabel('manage_locations.message.active_status_message')
    this.confirmationService.confirm({
      header: headerMsg,
      message: bodyMsg,
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: this.cs.getLabel('manage_locations.lbl_save_location'),
      rejectLabel: this.cs.getLabel('manage_locations.lbl_cancel'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: isActive ? 'bg-red-500' : 'bg-green-500',
      accept: ($event) => {
        this.tableRef.changeStatusOfEntity();
      },
      reject: () => {
      }
    });
  }

  public onChange(event) {
    for (const file of event.target.files) {
      if (file) {
        if (file.size > AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('err_file_size_exceed_msg') });
        } else {
          const formData = new FormData();
          formData.append("file", file);
          formData.append('forTenantCode', this.configService.getForTenantCode());
          formData.append('entityCode', AppSettings.ENTITY_CODE.LOCATION);
          formData.append('viewCode', AppSettings.VIEW_CODE.EXCEL_IMPORT_VIEW);
          this.cs.importEntity(formData, AppSettings.ENTITY_CODE.LOCATION).subscribe(res => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_file_uploaded_successfully') });
          })
        }
      }
    }

  }

  getAttributeData() {
    this.manageLocationService.getAttributeDefinition(AppSettings.ENTITY_CODE.LOCATION,  AppSettings.VIEW_CODE.BULK_UPDATE_VIEW).subscribe(res => {
      if (res) {
        this.attributeData = res;
        this.isLoading = false;
        this.filterFieldsArray = res.tabs[0].groups[0].fields;
        this.filterFieldsArray.forEach(element => {
          switch (element.attributeCode) {
            case 'location_category':
              element.attributeValue = 'Category';
              element.isDisable = false;
              break;
            case 'location_status':
              element.attributeValue = 'Status';
              element.isDisable = false;
              break;
          }
        });
      }
    });
  }

  setCurrentRowData(event) {
    this.currentRow = event;

    this.rowItems[0].disabled = this.currentRow.deleted;
    this.rowItems[1].disabled = this.currentRow.deleted;
    this.cd.detectChanges();

    if (this.currentRow.location_status === 'Inactive') {
      this.rowItems[1].label = this.cs.getLabel('manage_locations.lbl_make_location_active');
      this.rowItems[1].icon = AppIcons.OVERDUE + " wh-16 bg-green-500",
        this.cd.detectChanges();
    } else {
      this.rowItems[1].label = this.cs.getLabel('manage_locations.lbl_make_location_inactive'),
        this.rowItems[1].icon = AppIcons.OVERDUE + " wh-16 bg-red-500",
        this.cd.detectChanges();
    }
  }

  onChangeStatus(currentRow) {
    const isActive = currentRow.location_status === AppSettings.LOCATION_STATUS.ACTIVE;
    const newStatus = isActive ? AppSettings.LOCATION_STATUS.INACTIVE : AppSettings.LOCATION_STATUS.ACTIVE;
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.LOCATION,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [
        {
          attributeId: this.attributeIdOfLocationStatus,
          attributeValue: currentRow.location_status === AppSettings.LOCATION_STATUS.ACTIVE ? AppSettings.LOCATION_STATUS.INACTIVE : AppSettings.LOCATION_STATUS.ACTIVE
        }
      ]
    };
    this.manageLocationService.saveAttributeData(requestBody.entityCode, currentRow.id, requestBody).subscribe(result => {
      if (newStatus === AppSettings.LOCATION_STATUS.ACTIVE) {
        this.cs.onDisplayMessage(this.data, 'location', AppSettings.MSG_ACTION.ACTIVE, 'success');
      } else {
        this.cs.onDisplayMessage(this.data, 'location', AppSettings.MSG_ACTION.INACTIVE, 'success');
      }
  
      setTimeout(() => {
        this.searchEntity();
      }, 500);
    }, error => {
      this.showErrorMessage(error.errors.general[0].message);
    });
  }

  update() {
    this.onUpdateLocations(this.currentRow.id);
  }

  delete(event: Event, rowData: any, deleteType: string) {
    this.confirmationService.confirm({
      header: this.deleteVehicleHeaderMsg,
      message: this.deleteVehicleMsg,
      acceptIcon: '',
      rejectIcon: '',
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        if (deleteType === 'row') {
          this.onLocationDelete(this.currentRow.id);
        } else {
          this.onBulkDataDeleteEvent(this.currentRow.map(ele => {
            return ele.id
          }));
        }

      },
      reject: () => {
      }
    });
  }

  getTableView() {
    this.manageLocationService.getAttributeDefinition(AppSettings.ENTITY_CODE.LOCATION,  AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW).subscribe(res => {
      if (res) {
        this.isLoading = false;
        this.data = res;
        this.attributeIdOfLocationStatus = res.tabs[0].groups[0].fields.find(element => element.attributeCode === 'location_status')?.attributeId;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);

        this.allColumns = this.cs.getColumns(this.data);
        this.searchEntity();

        this.allColumns?.forEach((key, index) => {
          this.allColumns[index] = key;
          this.allColumns[index].field = key.attributeCode;
          this.allColumns[index].header = this.cs.getLabelValue(
            "location" + ".fields." + key.attributeCode + ".label",
            this.attributeLabels,
            key.attributeCode
          );
        });


        this.filterAvailableColumns = _.clone(this.allColumns);
      }
    });
  }

  getFilterView() {
    this.manageLocationService.getAttributeDefinition(AppSettings.ENTITY_CODE.LOCATION,  AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
        this.filterAttributeLabels = this.cs.getAttributeLabels(filterResponse);
        this.filterList = this.cs.getColumns(filterResponse);
        this.filterList = _.sortBy(this.filterList, 'attributeIndex');
      }
    })
  }


  getData() {

    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
    })
  }


  searchEntity() {
    this.tableData = [];
    this.entityData.countryCode = this.country[0].countryCode;
    this.manageLocationService.searchEntity(AppSettings.ENTITY_CODE.LOCATION, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = this.cs.getTableData(res);
    })
  }


  onAddLocations() {
    this.router.navigate(['app/manage-locations/add',]);
  }

  getLabel(params) {
    return params + 'hi';
  }

  onRowClicked(e: any) {
    alert('Row click : ' + JSON.stringify(e));
  }

  onActionBtnClicked(e: any) {
    alert('Action btn click : ' + JSON.stringify(e));
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  onConfirmDelete(event) {



  }


  onLocationDelete(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.LOCATION,
      entityIds: [
        event
      ]
    };
    this.manageLocationService.deleteEntityActionStatus(requestBody).subscribe({
      next: (res: any) => {
        this.cs.onDisplayMessage(this.attributeData, 'location', AppSettings.MSG_ACTION.DELETE, 'success');
        setTimeout(() => {
          this.searchEntity();
        }, 300);
      },
      error: (error) => {
        this.cs.onDisplayMessage(this.attributeData, 'location', AppSettings.MSG_ACTION.DELETE, 'error');
      }
    })
  }

  onUpdateLocations(locationId) {
    this.router.navigate(['app/manage-locations/add/' + locationId,]);
  }


  onRowSelect(event) {
    if (event.type === 'row') {
      this.router.navigate(['app/manage-locations/' + event.data.id,]);
    }
  }


  onFilterValueChange(event) {
    const vData = event;
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(vData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.entityData.filters.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.entityData.filters = _.filter(_.uniq(this.entityData.filters, function(item, key, a) {
      if (item.attributeValue.length > 0) {
        return item.attributeCode;

      }
    }), function(element) {
      if (element.attributeValue.length > 0) {
        return true;
      }
      return false;
    });
    this.entityData.offset = event.first;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
    this.searchEntity();
  }


  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }


  tabViewChange(event) {
    switch (event.index) {
      case 0:
        this.entityData.actionStatus = '';
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.entityData.filters = [];
        this.searchEntity();
        break;
      case 1:
        this.entityData.actionStatus = AppSettings.VEHICLE_TAB_LIST.ASSIGNED;
        this.searchEntity();
        break;

      case 2:
        this.entityData.actionStatus = AppSettings.VEHICLE_TAB_LIST.UNASSIGNED;
        this.searchEntity();
        break;

      case 3:
        this.entityData.actionStatus = AppSettings.VEHICLE_TAB_LIST.DELETE

        this.searchEntity();
        break;

      default:
        break;
    }
  }

  onBulkDataUpdateEvent(event) {
    setTimeout(() => {
      this.searchEntity();
    }, 500);
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.LOCATION,
      entityIds: event
    };
    this.entityData.actionStatus = AppSettings.VEHICLE_TAB_LIST.DELETE
    this.manageLocationService.deleteEntityActionStatus(requestBody).subscribe({
      next: (res: any) => {
        this.cs.onDisplayMessage(this.attributeData, 'location', AppSettings.MSG_ACTION.BULK_DELETE, 'success');
        setTimeout(() => {
          this.entityData.actionStatus = '';
          this.searchEntity();
        }, 300);
      },
      error: (error) => {
        this.cs.onDisplayMessage(this.attributeData, 'location', AppSettings.MSG_ACTION.BULK_DELETE, 'error');
      }
    })
  }

  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }

  private showErrorMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: this.cs.getLabel(labelKey)
    });
  }
}
