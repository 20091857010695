import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { AppIcons } from '../../../../../shared/app.icons';
import { AppSettings } from '../../../../../shared/app.settings';
import { CommonBindingDataService } from '../../../../../shared/services/common-binding-data.service';

import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SettingsService } from 'app/modules/settings/settings.service';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { Column } from '../../../../../shared/components/mi-table/models/table';
import { ListResponse } from '../../../../../vehicles/models/listResponse.models';
import { SettingsAddVehicleTypesComponent } from './settings-add-vehicle-types/settings-add-vehicle-types.component';
import { ConfigService } from 'app/modules/shared/services/config.service';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-settings-vehicle-types',
  standalone: true,
  imports: [TabViewModule, TranslateModule, ButtonModule, InputTextModule, SettingsAddVehicleTypesComponent, TableModule, MenuModule, FormsModule, NgClass],
  templateUrl: './settings-vehicle-types.component.html',
  styleUrl: './settings-vehicle-types.component.scss'
})
export class SettingsVehicleTypesComponent implements OnInit {
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  @Output() onPageChange: EventEmitter<any> = new EventEmitter();
  miIcons = AppIcons;
  addBtnLabel = this.cs.getLabel('settings.vehicle.lbl_add_vehicle_type');
  editLabel: string = this.cs.getLabel('settings.edit');
  deleteLabel: string = this.cs.getLabel('settings.delete');
  deleteHeaderMsg: string = this.cs.getLabel('settings.vehicle.vehicle_type_delete_header');
  deleteMsg: string = this.cs.getLabel('settings.vehicle.delete_msg');
  isVisible: boolean = false;
  isModal: boolean = false;
  searchValue: string = '';
  totalRecords: number;
  columns;
  tableDataTemp: any = [];
  listColumns;
  pagination: any;
  tableData: any = [];
  cols!: Column[];
  selectedRows;
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  currentRow;
  rowData;
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: this.configService.getForTenantCode(),
    attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.VEHICLE_TYPE
  };

  constructor(private cs: CommonBindingDataService, private settingsService: SettingsService,
    private confirmationService: ConfirmationService, private messageService: MessageService, private configService:ConfigService) { }

  ngOnInit() {
    this.getColumns();
    this.setRowItems();
    this.searchAttributeSettings();
    this.initializeBulkSelectionsItems();
  }

  rowMouseOver(rowData) {
    rowData.showRowMenu = true;
  }

  rowMouseOut(rowData) {
    rowData.showRowMenu = false;
  }

  getColumns() {
    this.cols = [
      { field: 'labelValue', header: AppSettings.SETTINGS_TABLE_HEADERS.NAME },
      { field: 'usage', header: AppSettings.SETTINGS_TABLE_HEADERS.USAGE }
    ];
  }

  public setRowItems() {
    this.rowItems = [
      {
        label: this.editLabel,
        icon: AppIcons.EDIT_2 + " wh-16",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        }
      },
      {
        label: this.deleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-16",
        iconClass: "bulk-update-icon",
        command: (e) => {
          const clonedEvent = _.cloneDeep(e);
          this.delete(clonedEvent, this.selectedRows, AppSettings.DELETE_TYPE.ROW);
        },
      },
    ];
  }


  private initializeBulkSelectionsItems() {
    this.bulkSelectionsItems = [
      {
        label: this.deleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-16",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        style: { 'width': '170px' },
        command: (e) => {
          const clonedEvent = _.cloneDeep(e);
          this.delete(clonedEvent, this.selectedRows, AppSettings.DELETE_TYPE.BULK);
        },
      },
    ];
  }

  update() {
    this.rowData = this.currentRow;
    this.isVisible = true;
  }

  delete(event: Event, rowData: any, deleteType: string) {
    this.confirmationService.confirm({
      header: this.deleteHeaderMsg,
      message: this.deleteMsg,
      acceptIcon: '',
      rejectIcon: '',
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        if (deleteType === AppSettings.DELETE_TYPE.ROW) {
          this.onSettingsDelete(this.currentRow.attributePresetLabelId);
        } else {
          this.onBulkDataDeleteEvent(this.selectedRows.map(ele => {
            return ele.attributePresetLabelId
          }));
        }

      },
      reject: () => {
      }
    });
  }

  onSettingsDelete(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      attributePresetLabelIds: [
        event
      ]
    };
    this.settingsService.deleteAttributeSettings(AppSettings.ENTITY_CODE.VEHICLE, requestBody).subscribe(response => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.vehicle.lbl_vehicle_type_delete_successfully') });
      this.searchAttributeSettings();
    })
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      attributePresetLabelIds: event
    };
    this.settingsService.deleteAttributeSettings(AppSettings.ENTITY_CODE.VEHICLE, requestBody).subscribe(response => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.vehicle.lbl_vehicle_types_delete_successfully') });
      this.selectedRows = [];
      this.searchAttributeSettings();
    })
  }

  onToggleManageColumn(event) {
    this.isModal = true;
  }

  setCurrentRow(rowData) {
    this.currentRow = rowData;
    this.updateContextMenu();
  }

  updateContextMenu() {
    this.rowItems = [
      {
        label: this.editLabel,
        icon: AppIcons.EDIT_2 + " wh-16",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        },
        disabled: !this.currentRow?.customValue
      },
      {
        label: this.deleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-16",
        iconClass: "bulk-update-icon",
        style: { 'opacity': '1', },
        command: () => {
          const clonedEvent = _.cloneDeep(this.currentRow);
          this.delete(clonedEvent, this.selectedRows, AppSettings.DELETE_TYPE.ROW);
        },
        disabled: !this.currentRow?.customValue
      }
    ];
  }

  clearSelected(): void {
    this.selectedRows = [];
  }

  onAddDialogOpen() {
    this.isVisible = true;
    this.rowData = '';
  }

  handleOverlayToggle() {
    this.isVisible = false;
    this.searchAttributeSettings();
  }

  searchAttributeSettings() {
    this.settingsService.searchAttributeSettings(AppSettings.ENTITY_TYPE.VEHICLE, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0];
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = this.cs.getTableDataForSettings(res);
      this.tableDataTemp = this.tableData;
    })
  }

  onSearch(event, settingsData) {
    const value = event.target.value.toLowerCase();
    if (settingsData) {
      this.tableData = this.tableDataTemp.filter(ele => ele?.labelValue.toLowerCase().includes(value));
    }
  }

  clearSearch() {
    this.searchValue = "";
    this.searchAttributeSettings();
    this.onSearchValueChange.emit('');
  }

  onPageChange2(event: PageEvent) {
    this.onPageChange.emit(event);
  }

}
