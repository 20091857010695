import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { MessageService } from 'app/modules/shared/services/message.service';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { filter, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-mi-input-sync-select',
  standalone: true,
  templateUrl: './mi-input-sync-select.component.html',
  styleUrl: './mi-input-sync-select.component.scss',
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, TranslateModule, InputTextModule, ButtonModule]
})
export class MiInputSyncSelectComponent {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  alive = true;
  defaultLbl: string;
  placeholder: any;
  selectedValue: string = undefined;

  constructor(
    private messageService: MessageService,
    private formGroupDirective: FormGroupDirective,
    private entityService: EntityService,
    public cs: CommonBindingDataService,
    private configService: ConfigService,
    private cd: ChangeDetectorRef) {
    this.formName = formGroupDirective.control;
  }

  ngOnInit(): void {
    this.getPresetValues();
    this.defaultLbl = this.cs.getLabel("lbl_please_select")
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
  }

  getPresetValues() {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      attributeCode: this.field.attributeCode,
      searchStr: '',
      usageRequired: false
    }
    this.entityService.getPresetLabels(requestBody, this.moduleName).subscribe({
      next: (result: any) => {
        this.field.presetValues = result.data.map(ele => {
          return {
            labelKey: ele.labelKey,
            labelValue: ele.labelValue
          }
        });
        if (this.moduleName === AppSettings.ENTITY_CODE.PASSENGERS) {
          this.field.presetValues.push({ labelKey: 'Other', labelValue: 'Other' });
        }
      },
      error: (error) => { }
    })

  }

  ngAfterViewInit(): void {
    this.listenForLinkData();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  listenForLinkData() {
    if (!this.field?.dependsOnAttributeId) {
      return;
    }
    this.messageService.message$.pipe(
      filter(v => v.dependsOnAttributeId === this.field.dependsOnAttributeId),
      takeWhile(() => this.alive)
    ).subscribe((v) => {
      this.field.options = v.data
    })
  }

  changedValue(value: string) {
    this.selectedValue = value;
    if (this.selectedValue === "Other") {
      this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(undefined);
    } else {
      this.cd.markForCheck();
    }
  }

}
