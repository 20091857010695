import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { ModuleHeaderComponent } from '../../../shared/components/module-header/module-header.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { ShiftTemplateService } from '../../../shift-template/services/shift-template.service';
import { Column } from '../../../vehicles/models/attribute.models';
import { Values } from '../../../vehicles/models/listResponse.models';
import { ButtonModule } from 'primeng/button';
import { EntityService } from 'app/modules/shared/services/entity.service';

@Component({
  selector: 'app-organization-list',
  standalone: true,
  imports: [BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, MITableComponent, MITableComponent, AsyncPipe, ButtonModule],
  templateUrl: './organization-list.component.html',
  styleUrl: './organization-list.component.scss'
})
export class OrganizationListComponent {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLElement>;
  @Input() moduleName: string;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];

  miIcons = AppIcons;
  value: number = 50;
  btnLabel: string;
  items: MenuItem[] | undefined;
  isShowMenu: boolean = true;
  attributeLabels = {};
  attributeLabelsData: any = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  totalRecords: number = 0;
  tableData: any = [];
  buttons: any[] = [];
  country: Country;
  language: Language;
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'asc',
    status: 'both'
  };
  routePath: MenuItem[] = [];
  home: MenuItem = {};
  isModal: boolean = false;
  selectedColumns!: Column[];
  attributeData: any;
  data;
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  columnsData;
  bulkDeleteLabel: string = this.cs.getLabel('shiftTemplate.delete');
  bulkUpdateLabel: string = this.cs.getLabel('shiftTemplate.lbl_update_shift_template');
  bulkUpdateHeader: any;
  reviewHeader: any;
  showBulkUpdateDialog: boolean = false;
  visible: boolean = false;
  uniqueId: string = 'organization-grid';
  isEntity: boolean = false;
  entityName = AppSettings.ENTITY_TYPE.ORGANIZATION;
  showImportExportBtn: boolean = false;
  btnUpdate: any;
  deleteShiftTemplateMsg: any;
  deleteShiftTemplateHeaderMsg: any;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  currentRow: any;
  entityNameForBulkUpdate = AppSettings.ENTITY_TYPE.SHIFT_TEMPLATE;
  count: any;
  entityNameToDisplay: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cs: CommonBindingDataService,
    private configService: ConfigService,
    private cd: ChangeDetectorRef,
    private entityService: EntityService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.setHeadersAndLabels();
    this.setModuleHeaderSecondaryBtnMenuItems();
    this.setRowSelectionOptions();
    this.setBulkSelectionOptions();
    this.initializeColumn();
  }

  setUpRoutePath() {
    this.routePath = [
      { label: this.cs.getLabel('organization.lbl_organizations'), routerLink: 'organization/list' },
    ];
  }

  initializeColumn() {
    this.allColumns = [
      {
        attributeCode: "orgName",
        attributeValue: 'Name',
        attributeIndex: 0,
        field: "tenantName",
        header: this.cs.getLabel('organization.columns.name')
      },
      {
        attributeCode: "code",
        attributeValue: 'Code',
        attributeIndex: 1,
        field: "tenantCode",
        header: this.cs.getLabel('organization.columns.code')
      },
      {
        attributeCode: "emailAddress",
        attributeValue: 'Email Address',
        attributeIndex: 2,
        field: "email",
        header: this.cs.getLabel('organization.columns.emailAddress')
      },
      {
        attributeCode: "org_status",
        attributeValue: 'status',
        attributeIndex: 2,
        field: "active",
        header: this.cs.getLabel('organization.columns.status')
      },
      {
        attributeCode: "phoneNumber",
        attributeValue: 'Hours',
        attributeIndex: 3,
        field: "contactNumber",
        header: this.cs.getLabel('organization.columns.contactNumber')
      }
    ];
  }

  setHeadersAndLabels() {

    this.entityNameToDisplay = this.cs.getLabel('manage_roles.lbl_entity_name_display');
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.bulkUpdateHeader = this.cs.getLabel('shiftTemplate.update_shift_templates');
    this.btnUpdate = this.cs.getLabel('shiftTemplate.lbl_update_shift_template');
    this.deleteShiftTemplateMsg = this.cs.getLabel('shiftTemplate.message.confirm_delete_msg');
    this.deleteShiftTemplateHeaderMsg = this.cs.getLabel('shiftTemplate.message.confirm_delete_header');
    this.btnLabel = this.cs.getLabel('organization.add_organization');
    this.getData();
  }

  setModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.cs.getLabel('shiftTemplate.import_shift_templates'),
        icon: AppIcons.UPLOAD + " wh-16",
        command: () => {
          const el: HTMLElement = this.uploader.nativeElement;
          el.click();
        }
      },
      {
        label: this.cs.getLabel('shiftTemplate.download_import_template'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
        }
      },
      {
        label: this.cs.getLabel('shiftTemplate.download_import_guide'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
        }
      },
      {
        separator: true
      },
      {
        label: this.cs.getLabel('vehicle.export_xlsx_csv'),
        icon: AppIcons.EXPORT + " wh-16",
      },
    ];
  }

  setBulkSelectionOptions() {
    this.bulkSelectionsItems = [
      {
        label: this.bulkDeleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: (e) => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.BULK);
        },
      }
    ];
  }

  setRowSelectionOptions() {
    this.rowItems = [
      {
        label: this.cs.getLabel('shiftTemplate.lbl_edit'),
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        command: (event) => {
          this.router.navigate(['app/organization/edit/' + this.currentRow.tenantId]);
        }
      }
    ];
  }

  onRowSelect(event) {
    if (event.type === 'row') {
      this.router.navigate(['app/organization/details/' + event.data.tenantId,]);
    }
  }

  setCurrentRowData(event) {
    this.currentRow = event;
    this.setEditOptionDisabledForDeletedRecord(event);
  }

  setEditOptionDisabledForDeletedRecord(data) {
    this.rowItems[0].disabled = data.deleted;
    this.cd.detectChanges();
  }

  getData() {
    this.searchEntity();
  }

  onOrganizationStatusChangeEvent(event) {
    const data = {
      active: event.checked,
    }
    this.entityService.updateOrganizationStatus(event.tenantId, data).subscribe({
      next: (result) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('organization.lbl_organization_status_updated_successfully') });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }


  searchEntity() {

    this.entityService.getOrganizationList(this.entityData).subscribe((res: any) => {
      this.tableData = [];
      this.count = res.count;
      this.setUpRoutePath();
      this.listColumns = res?.data[0];
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      for (const data of res.data) {
        this.tableData.push({
          id: data.shiftTemplateId,
          ...data
        });
      }
    })
    this.isLoading = false;
  }

  onAddOrganization() {
    this.router.navigate(['app/organization/add']);
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  onBulkDataUpdateEvent(event) {
    this.searchEntity();
  }

  onChange(event) {

  }

}
