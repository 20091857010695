import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { SettingsService } from 'app/modules/settings/settings.service';
import { MessageService } from 'primeng/api';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordModule } from 'primeng/password';
import * as _ from 'lodash';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { AppIcons } from 'app/modules/shared/app.icons';
import { LoginOption, RoleLoginOption } from '../models/authModels';

@Component({
  selector: 'app-settings-passenger',
  standalone: true,
  imports: [TooltipModule, ButtonModule, InputTextModule, TabViewModule, TranslateModule, ReactiveFormsModule, PasswordModule, FormsModule, InputTextModule, CheckboxModule, DropdownModule],
  templateUrl: './settings-passenger.component.html',
  styleUrl: './settings-passenger.component.scss'
})
export class SettingsPassengerComponent {
  addBtnLabel: string;
  profileForm: FormGroup;
  userData: RoleLoginOption[] | null = null;
  passwordOptions;
  hasSso;
  hasOtp;
  miIcons = AppIcons;
  timeoutOptions = [
    { label: 'Never', value: 'never' },
    { label: '30 days', value: '30D' },
    { label: '60 days', value: '60D' },
    { label: '90 days', value: '90D' }
  ];
  isReady: boolean = false;

  defaultPasswordInfo = ""

  constructor(
    private cs: CommonBindingDataService,
    private formBuilder: FormBuilder,
    private settingService: SettingsService,
    private messageService: MessageService,
    private configService: ConfigService
  ) {
    this.addBtnLabel = this.cs.getLabel('label_save');
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.settingService.getLoginOption().subscribe({
      next: (response) => {
        this.passwordOptions = _.filter(response, (item) => !/OTP/.test(item.loginOptionType) && item.loginOptionCode !== 'SSO');
        this.hasSso = _.filter(response, (item) => item.loginOptionCode === 'SSO');
        this.hasOtp = _.filter(response, (item) => /OTP/.test(item.loginOptionType));
        this.setData();
      },
      error: (err) => {
        console.error('Error fetching login option', err);
      }
    });


  }

  setData() {
    this.settingService.getRoleLoginOption(AppSettings.ROLE_CODE.PASSENGER).subscribe({
      next: (response) => {
        this.userData = response;
        console.log(this.userData)
        this.initializeForm();
      },
      error: (err) => {
        console.error('Error fetching role login option', err);
      }
    });
  }

  private initializeForm(): void {
    const passengerData = this.userData[0];
    const metaData = JSON.parse(passengerData.metaData);
    const loginOptionIds: string[] = this.userData?.map(option => option.loginOptionId);

    this.profileForm = this.formBuilder.group({
      defaultPassword: [passengerData.defaultPassword ? passengerData.defaultPassword : ''],
      passwordOptions: this.formBuilder.group(this.createCheckboxes(this.passwordOptions, loginOptionIds)),
      hasSso: this.formBuilder.group(this.createCheckboxes(this.hasSso, loginOptionIds)),
      hasOtp: this.formBuilder.group(this.createCheckboxes(this.hasOtp, loginOptionIds)),
      timeout: [passengerData.appConfiguration?.timeout ? passengerData.appConfiguration?.timeout : ''],
      metaData: [metaData?.[0]?.metaUrl ? metaData?.[0]?.metaUrl : '']
    });

    this.updateDefaultPasswordValidation();
    this.updateMetaDataValidation();

    this.profileForm.get('passwordOptions')?.valueChanges.subscribe(() => {
      this.updateDefaultPasswordValidation();
      this.toggleFieldStates();
    });

    this.profileForm.get('hasSso')?.valueChanges.subscribe(() => {
      this.updateMetaDataValidation();
      this.toggleFieldStates();
    });
    this.isReady = true;
    console.log(this.profileForm);
  }

  private toggleFieldStates(): void {
    let passwordOptionsSelected = Object.keys(this.profileForm.value.passwordOptions).filter(key => this.profileForm.value.passwordOptions[key][0]).map(key => this.profileForm.value.passwordOptions[key]);
    passwordOptionsSelected = _.flattenDeep(passwordOptionsSelected);
    this.profileForm.get('defaultPassword')?.setValue(passwordOptionsSelected.length > 0 ? this.profileForm.value.defaultPassword : '');
    this.profileForm.get('defaultPassword')?.setValidators(passwordOptionsSelected.length > 0 ? [
      Validators.required,
      Validators.pattern(AppSettings.PASSWORD_PATTERN)
    ] : []);
    this.profileForm.get('defaultPassword')?.updateValueAndValidity();


    let hasSsoSelected = Object.keys(this.profileForm.value.hasSso).filter(key => this.profileForm.value.hasSso[key][0]).map(key => this.profileForm.value.hasSso[key]);
    hasSsoSelected = _.flattenDeep(hasSsoSelected);
    this.profileForm.get('metaData')?.setValue(hasSsoSelected.length > 0 ? this.profileForm.value.metaData : '');
    this.profileForm.get('metaData')?.setValidators(hasSsoSelected.length > 0 ? Validators.required : []);
    this.profileForm.get('metaData')?.updateValueAndValidity();
  }

  private updateMetaDataValidation(): void {
    const hasSsoSelected = Object.keys(this.profileForm.value.hasSso).filter(key => this.profileForm.value.hasSso[key]);
    console.log('hasSsoSelected:', hasSsoSelected);
    if (hasSsoSelected.length > 0) {
      this.profileForm.get('metaData')?.setValidators([Validators.required]);
    } else {
      this.profileForm.get('metaData')?.clearValidators();
    }
    this.profileForm.get('metaData')?.updateValueAndValidity();
  }

  private updateDefaultPasswordValidation(): void {
    const passwordOptionsSelected = Object.keys(this.profileForm.value.passwordOptions).filter(key => this.profileForm.value.passwordOptions[key]);
    console.log('passwordOptionsSelected:', passwordOptionsSelected);
    if (passwordOptionsSelected.length > 0) {
      this.profileForm.get('defaultPassword')?.setValidators([
        Validators.required,
        Validators.pattern(AppSettings.PASSWORD_PATTERN)
      ]);
    } else {
      this.profileForm.get('defaultPassword')?.clearValidators();
    }
    this.profileForm.get('defaultPassword')?.updateValueAndValidity();
  }

  createCheckboxes(items: LoginOption[], loginOptionIds: string[]): { [key: string]: boolean } {
    const checkboxes = {};
    items.forEach(item => {
      console.log(item.loginOptionCode);
      const value = loginOptionIds.includes(item?.loginOptionId) ? [item?.loginOptionId] : false;
      return checkboxes[item.loginOptionCode] = value;
    });
    return checkboxes;
  }

  onCancel() {
    this.profileForm.reset();
    this.updateDefaultPasswordValidation();
    this.updateMetaDataValidation();
    this.setData();
  }

  onAddUser() {
    const extractSelectedValues = (formGroup: any) => {
      return _.flattenDeep(Object.keys(formGroup)
        .filter(key => formGroup[key][0])
        .map(key => formGroup[key]));
    };

    const selectedLoginOptionIds = [
      ...extractSelectedValues(this.profileForm.value.passwordOptions),
      ...extractSelectedValues(this.profileForm.value.hasSso),
      ...extractSelectedValues(this.profileForm.value.hasOtp)
    ];

    const userData = {
      forTenantCode: this.configService.getLoggedInTenantCode(),
      roleCode: AppSettings.ROLE_CODE.PASSENGER,
      defaultPassword: this.profileForm.value.defaultPassword,
      metaData: JSON.stringify([{ metaUrl: this.profileForm.value.metaData }]),
      loginOptionId: selectedLoginOptionIds,
      appConfiguration: {
        appCode: "MOBILE APP",
        configurationCode: "MOBILE APP",
        timeout: this.profileForm.value.timeout
      }
    };

    this.settingService.setRoleLoginOption(userData).subscribe({
      next: (response) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.authentication.lbl_password_updated_successfully') });
        this.getData();
      },
      error: (err) => {
        console.error('Error fetching role login option', err);
      }
    });
  }
}
