import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';

import { CommonModule, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SettingsService } from 'app/modules/settings/settings.service';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Country } from 'app/modules/shared/models/country';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { EntityList } from 'app/modules/vehicles/models/entity.models';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { SettingsAddLabelsComponent } from './settings-add-labels/settings-add-labels.component';
import { Column } from 'app/modules/vehicles/models/attribute.models';
import { ListResponse } from 'app/modules/vehicles/models/listResponse.models';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-settings-labels',
  standalone: true,
  imports: [TabViewModule, TranslateModule, ButtonModule, InputTextModule, TableModule, MenuModule, FormsModule, NgClass, SettingsAddLabelsComponent, CommonModule],
  templateUrl: './settings-labels.component.html',
  styleUrl: './settings-labels.component.scss'
})
export class SettingsLabelsComponent implements OnInit {
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  @Output() onPageChange: EventEmitter<any> = new EventEmitter();
  @Output() itemPerPageChange: EventEmitter<any> = new EventEmitter();
  miIcons = AppIcons;
  addBtnLabel = this.cs.getLabel('settings.lbl_add_label');
  editLabel: string = this.cs.getLabel('settings.edit');
  deleteLabel: string = this.cs.getLabel('settings.delete');
  deleteHeaderMsg: string = this.cs.getLabel('settings.labels.label_delete_header');
  deleteMsg: string = this.cs.getLabel('settings.labels.delete_msg');
  isVisible: boolean = false;
  isModal: boolean = false;
  searchValue: string = '';
  totalRecords: number;
  columns;
  tableDataTemp: any = [];
  listColumns;
  pagination: any;
  tableData: any = [];
  tableDataForUsage: any = [];
  cols!: Column[];
  country: Country;
  selectedRows;
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  currentRow;
  rowData;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    forTenantCode: this.confService.getLoggedInTenantCode(),
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc'
  };

  constructor(private cs: CommonBindingDataService, private entityService: EntityService, private settingsService: SettingsService, private confService: ConfigService,
    private confirmationService: ConfirmationService, private messageService: MessageService,) { }

  ngOnInit() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.getColumns();
    this.setRowItems();
    this.searchEntityForTags();
    this.initializeBulkSelectionsItems();
  }

  rowMouseOver(rowData) {
    rowData.showRowMenu = true;
  }

  rowMouseOut(rowData) {
    rowData.showRowMenu = false;
  }

  getColumns() {
    this.cols = [
      { field: 'label', header: this.cs.getLabel('lbl_name') },
      { field: 'priority', header: this.cs.getLabel('lbl_usage') }
    ];
  }

  public setRowItems() {
    this.rowItems = [
      {
        label: this.editLabel,
        icon: AppIcons.EDIT_2 + " wh-16",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        },
      },
      {
        label: this.deleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-16",
        iconClass: "bulk-update-icon",
        command: (e) => {
          const clonedEvent = _.cloneDeep(e);
          this.delete(clonedEvent, this.selectedRows, AppSettings.DELETE_TYPE.ROW);
        },
      },
    ];
  }


  private initializeBulkSelectionsItems() {
    this.bulkSelectionsItems = [
      {
        label: this.deleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-16",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        style: { 'width': '170px' },
        command: (e) => {
          const clonedEvent = _.cloneDeep(e);
          this.delete(clonedEvent, this.selectedRows, AppSettings.DELETE_TYPE.BULK);
        },
      },
    ];
  }

  update() {
    this.rowData = this.currentRow;
    this.isVisible = true;
  }

  delete(event: Event, rowData: any, deleteType: string) {
    this.confirmationService.confirm({
      header: this.deleteHeaderMsg,
      message: this.deleteMsg,
      acceptIcon: '',
      rejectIcon: '',
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        if (deleteType === AppSettings.DELETE_TYPE.ROW) {
          this.onSettingsDelete(this.currentRow.tagId);
        } else {
          this.onBulkDataDeleteEvent(this.selectedRows.map(ele => {
            return ele.tagId
          }));
        }

      },
      reject: () => {
      }
    });
  }

  onSettingsDelete(event) {
    this.entityData.tagIds = [event];
    this.settingsService.deleteTags(AppSettings.ENTITY_CODE.TAGS, this.entityData).subscribe(response => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.labels.lbl_label_delete_successfully') });
      this.searchEntityForTags();
    })
  }

  onBulkDataDeleteEvent(event) {
    this.entityData.tagIds = [event];
    this.settingsService.deleteTags(AppSettings.ENTITY_CODE.TAGS, this.entityData).subscribe(response => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.labels.lbl_label_delete_successfully') });
      this.selectedRows = [];
      this.searchEntityForTags();
    })
  }

  onToggleManageColumn(event) {
    this.isModal = true;
  }

  setCurrentRow(rowData) {
    this.currentRow = rowData;
  }

  clearSelected(): void {
    this.selectedRows = [];
  }

  onAddDialogOpen() {
    this.isVisible = true;
    this.rowData = '';
  }

  handleOverlayToggle() {
    this.isVisible = false;
    this.searchEntityForTags();
  }

  searchEntityForTags() {
    this.entityService.searchEntityForTags(AppSettings.ENTITY_CODE.TAGS, this.entityData).subscribe((res: ListResponse) => {
      const tagsData = this.cs.getTableDataForTags(res);
      this.searchEntityForUsage(tagsData);
      this.totalRecords = res?.count;
      this.pagination = res?.pagination;
    });
  }

  searchEntityForUsage(tagsData: any[]) {
    this.entityService.searchEntityForUsage(this.entityData).subscribe((res: any) => {
      const usageData = res;
      const usageMap = new Map<string, any>();
      for (const usageItem of usageData) {
        usageMap.set(usageItem.tagListDto.tagId, usageItem.usage);
      }
      const combinedData = tagsData.map(tag => {
        const usage = usageMap.get(tag.tagId) || {};
        return {
          ...tag,
          usage
        };
      });
      this.tableData = combinedData;
    });
  }

  onSearch(event, settingsData) {
    const value = event.target.value.toLowerCase();
    if (settingsData) {
      this.tableData = this.tableDataTemp.filter(ele => ele?.labelValue.toLowerCase().includes(value));
    }
  }

  clearSearch() {
    this.searchValue = "";
    this.searchEntityForTags();
    this.onSearchValueChange.emit('');
  }

  onPageChange2(event: PageEvent) {
    this.onPageChange.emit(event);
  }

}
