import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NoResultFoundComponent } from 'app/modules/shared/components/no-result-found/no-result-found.component';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { ChartOptions } from 'chart.js';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { forkJoin } from 'rxjs';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import type { EntityCountsResponse } from '../../../vehicles/models/entity.models';
import type { Values } from '../../models/driverList.models';
import type { EntityList, Leave, LeaveCountModal, LeaveManagementModal } from '../../models/leaveManagement.models';
import { LeaveManagementService } from '../../services/leave-management.service';




@Component({
  selector: 'app-driver-leave-management',
  standalone: true,
  imports: [
    BreadcrumbModule,
    TabViewModule,
    TranslateModule,
    MITableComponent,
    ChartModule,
    CommonModule,
    DropdownModule,
    CalendarModule,
    FormsModule,
    ToastModule,
    ConfirmDialogModule,
    RouterModule,
    ToastModule,
    NoResultFoundComponent
  ],
  templateUrl: './driver-leave-management.component.html',
  styleUrl: './driver-leave-management.component.scss',
})
export class DriverLeaveManagementComponent implements OnInit {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  btnLabel: string;
  routePath: MenuItem[] = [];
  pieChartData: any;
  pieChartOptions: any;
  data: any;
  options: ChartOptions<any>;
  tomorrowsData: any;
  tomorrowsOptions: ChartOptions<any>;
  todaysDate: Date = new Date();
  tomorrowDate: Date;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  leaveListData: any = {};
  totalRecords: number = 0;
  entityName;
  currentRow;
  filterList;
  bulkUpdateHeader;
  reviewHeader;
  deleteLeaveMsg;
  deleteLeaveHeaderMsg;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];
  isEntity: boolean = true;
  attributeData: any;
  filterFieldsArray: any[];
  uniqueId: string = 'leave_management';
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  rejectLabel: string = this.cs.getLabel('leave_management.lbl_reject');
  editLabel: string = this.cs.getLabel('leave_management.lbl_edit');
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    fromDateStr: this.cs.getDate15DaysBefore(),
    toDateStr: this.cs.getDate15DaysAfter()
  };
  leaves: Leave[];
  selectedLeave: Leave;
  showCalendar: boolean = false;
  rangeDates: Date[] | undefined;
  rejectedLeaveId: string;
  rejectHeader = this.cs.getLabel('lbl_reject_header');
  rejectMessage = this.cs.getLabel('lbl_reject_message');
  todaysLeaveCount: number;
  todaysWorkingCount: number;
  tomorrowsLeaveCount: number;
  tomorrowsWorkingCount: number;
  bulkUpdateLabel: string = this.cs.getLabel("leave_management.lbl_update_leaves");
  bulkDeleteLabel: string = this.cs.getLabel("lbl_delete");
  isShowNoResult: boolean = false;

  constructor(private cs: CommonBindingDataService, private router: Router, private messageService: MessageService,
    private leaveManagementService: LeaveManagementService, private configService: ConfigService, private entityService: EntityService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.setRowItems();
    this.bulkUpdateItems();
    this.initializeOtherVariablesAndAPI();
    this.initializeColumn();
    this.getBreadcrumb();
    this.getListData();
    this.calculateTomorrowDate();
    this.initTodaysDoughnutChart();
    this.initTomorrowsDoughnutChart();
    this.leaveDropdownValues();
  }

  leaveDropdownValues() {
    this.leaves = AppSettings.LEAVES_GROUP.OVERVIEW_LIST.map(item => ({
      label: item.label,
      value: item.label
    }));
  }

  onChangeSelectedLeave(event) {
    const periodHandlers = {
      'This Week': this.getCurrentWeek.bind(this),
      'Last Week': this.getLastWeek.bind(this),
      'This Month': this.getThisMonth.bind(this),
      'Last Month': this.getLastMonth.bind(this),
      'This Year': this.getCurrentYear.bind(this),
      'Last Year': this.getLastYear.bind(this),
    };
    const selectedLabel = event.originalEvent.target.innerText;
    const handler = periodHandlers[selectedLabel];

    if (handler) {
      const dates = handler();
      const fromDate = dates.monday || dates.start;
      const toDate = dates.sunday || dates.end;
      const fromDateObj = fromDate instanceof Date ? fromDate : new Date(fromDate);
      const toDateObj = toDate instanceof Date ? toDate : new Date(toDate);
      const formattedFromDateStr = this.cs.formatDateToDDMMYYYY(fromDateObj);
      const formattedToDateStr = this.cs.formatDateToDDMMYYYY(toDateObj);
      if (formattedFromDateStr && formattedToDateStr) {
        const reqBody = {
          fromDateStr: formattedFromDateStr,
          toDateStr: formattedToDateStr,
          forTenantCode: this.configService.getForTenantCode(),
        };
        this.callOverviewLeaveCountAPI(reqBody);
      }
      this.showCalendar = false;
    } else if (selectedLabel === 'Custom') {
      this.showCalendar = true;
    } else {
      this.showCalendar = false;
    }
  }

  callOverviewLeaveCountAPI(reqBody) {
    this.leaveManagementService.getOverviewLeaveCount(reqBody).subscribe((res: any) => {
      if (res.length > 0) {
        this.isShowNoResult = true;
      } else {
        this.isShowNoResult = false;
      }
      this.initPieChart(res.map(ele => ele.leaveType), res.map(ele => ele.count))
    })
  }

  getCurrentWeek() {
    return this.cs.getStartAndEndOfWeek(0);
  }

  getLastWeek() {
    return this.cs.getStartAndEndOfWeek(-7);
  }

  getThisMonth() {
    return this.cs.getStartAndEndOfMonth(0);
  }

  getLastMonth() {
    return this.cs.getStartAndEndOfMonth(-1);
  }

  getCurrentYear() {
    return this.cs.getStartAndEndOfYear(0);
  }

  getLastYear() {
    return this.cs.getStartAndEndOfYear(-1);
  }

  onDateSelect(event) {
    if (this.rangeDates && this.rangeDates.length) {
      const fromDate = this.rangeDates[0];
      const toDate = this.rangeDates[1];
      if (fromDate && toDate) {
        const fromDateStr = this.cs.formatDateToDDMMYYYY(fromDate);
        const toDateStr = this.cs.formatDateToDDMMYYYY(toDate);
        const reqBody = {
          fromDateStr: fromDateStr,
          toDateStr: toDateStr,
          forTenantCode: this.configService.getForTenantCode(),
        }
        this.callOverviewLeaveCountAPI(reqBody);
      }
    }
  }

  public setRowItems() {
    this.rowItems = [
      {
        label: this.editLabel,
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        },
      },
      {
        label: this.rejectLabel,
        icon: AppIcons.WARNING_OUTLINE + " wh-16",
        iconClass: "bulk-update-icon",
        visible: false,
        command: () => {
          this.onRejectLeave();
        },
      },
    ];
  }

  private bulkUpdateItems() {
    this.bulkSelectionsItems = [
      {
        label: this.bulkUpdateLabel,
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: "bulk-update-icon",
        styleClass: "bulk-update-list",
        command: () => {
          this.tableRef.showBulkLeaveDialog();
        },
      },
      {
        label: this.bulkDeleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: "bulk-update-icon",
        styleClass: "bulk-update-list",
        command: (e) => {
          const event = _.cloneDeep(e);
          this.tableRef.deleteLeave();
        },
      },
    ];
  }

  initializeColumn() {
    this.allColumns = [
      {
        "attributeCode": "fullName",
        "attributeIndex": 0,
        "field": "fullName",
        "header": this.cs.getLabel('leave_management.columns.lbl_name')
      },
      {
        "attributeCode": "driverUniqueId",
        "attributeIndex": 1,
        "field": "driverUniqueId",
        "header": this.cs.getLabel('leave_management.columns.lbl_unique_id')
      },
      {
        "attributeCode": "driverCallSign",
        "attributeIndex": 2,
        "field": "driverCallSign",
        "header": this.cs.getLabel('leave_management.columns.lbl_call_sign')
      },
      {
        "attributeCode": "leaveType",
        "attributeIndex": 3,
        "field": "leaveType",
        "header": this.cs.getLabel('leave_management.columns.lbl_leave_type')
      },
      {
        "attributeCode": "fromDate",
        "attributeIndex": 4,
        "field": "fromDate",
        "header": this.cs.getLabel('leave_management.columns.lbl_from_date')
      },
      {
        "attributeCode": "toDate",
        "attributeIndex": 5,
        "field": "toDate",
        "header": this.cs.getLabel('leave_management.columns.lbl_to_date')
      },
      {
        "attributeCode": "numberOfDays",
        "attributeIndex": 6,
        "field": "numberOfDays",
        "header": this.cs.getLabel('leave_management.columns.lbl_no_of_days')
      },
      {
        "attributeCode": "approvalStatus",
        "attributeIndex": 7,
        "field": "approvalStatus",
        "header": this.cs.getLabel('leave_management.columns.lbl_status')
      },
    ];
  }

  initializeOtherVariablesAndAPI() {
    this.btnLabel = this.cs.getLabel('lbl_new_leave');
    this.bulkDeleteLabel = this.cs.getLabel('leave_management.message.delete_leave');
    this.bulkUpdateLabel = this.cs.getLabel('leave_management.lbl_update_leaves');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.bulkUpdateHeader = this.cs.getLabel('leave_management.lbl_bulk_update_header');
    this.reviewHeader = this.cs.getLabel('leave_management.lbl_review_leaves');
    this.deleteLeaveMsg = this.cs.getLabel('leave_management.message.confirm_delete_msg');
    this.deleteLeaveHeaderMsg = this.cs.getLabel('leave_management.message.delete_leave');
    this.getBulkUpdateAttributeData();
    this.getData();
  }

  private getBreadcrumb() {
    this.routePath = [{ label: this.cs.getLabel('sidebar_leave_management') }];
  }

  private getListData() {
    this.btnLabel = this.cs.getLabel("lbl_new_leave");
  }

  calculateTomorrowDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    this.tomorrowDate = tomorrow;
  }

  initPieChart(labels, data) {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");

    this.pieChartData = {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: ["#4169E1", "#FFA400", "#00A9D7"],
        },
      ],
    };
    this.pieChartOptions = {
      maintainAspectRatio: false,
      aspectRatio: 2,
      responsive: false,
      plugins: {
        legend: {
          position: "right",
          display: true,
          labels: {
            usePointStyle: true,
            color: textColor,
            generateLabels: this.generateLabels
          },
        },
      },
    };
  }

  initTodaysDoughnutChart() {
    const reqBody = {
      dateStr: this.cs.formatDate(this.todaysDate),
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
    }
    this.leaveManagementService.getAllLeavesCount(reqBody).subscribe((res: LeaveCountModal) => {
      this.todaysWorkingCount = res.counts.working;
      this.todaysLeaveCount = res.counts.leave;
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      this.data = {
        labels: [this.cs.getLabel('lbl_working'), this.cs.getLabel('lbl_on_leave')],
        datasets: [
          {
            data: [this.todaysWorkingCount, this.todaysLeaveCount],
            backgroundColor: ["#4169E1", "#FF0000"],
          }
        ]
      };

      this.options = {
        cutout: '60%',
        aspectRatio: 2,
        maintainAspectRatio: false,
        responsive: false,
        plugins: {
          legend: {
            position: "right",
            display: true,
            labels: {
              color: textColor,
              boxWidth: 15,
              generateLabels: this.generateLabels
            }
          }
        }
      };
    })
  }


  generateLabels(chart) {
    const data = chart.data;
    if (data.labels.length && data.datasets.length) {
      return data.labels.map((label, i) => {
        const value = data.datasets[0].data[i];
        return {
          text: `${label}: ${value}`,
          fillStyle: data.datasets[0].backgroundColor[i],
          hidden: isNaN(value),
          index: i
        };
      });
    }
    return [];
  }


  initTomorrowsDoughnutChart() {
    const reqBody = {
      dateStr: this.cs.formatDate(this.tomorrowDate),
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
    }
    this.leaveManagementService.getAllLeavesCount(reqBody).subscribe((res: LeaveCountModal) => {
      this.tomorrowsWorkingCount = res.counts.working;
      this.tomorrowsLeaveCount = res.counts.leave;
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      this.tomorrowsData = {
        labels: [this.cs.getLabel('lbl_working'), this.cs.getLabel('lbl_on_leave')],
        datasets: [
          {
            data: [this.tomorrowsWorkingCount, this.tomorrowsLeaveCount],
            backgroundColor: ["#4169E1", "#FF0000"],
          }
        ]
      };

      this.tomorrowsOptions = {
        cutout: '60%',
        aspectRatio: 2,
        maintainAspectRatio: false,
        responsive: false,
        plugins: {
          legend: {
            position: "right",
            display: true,
            labels: {
              color: textColor,
              boxWidth: 15,
              generateLabels: this.generateLabels
            }
          }
        }
      };
    })
  }

  getTableView() {
    this.isLoading = false;
    this.searchEntity();
    this.filterAvailableColumns = _.clone(this.allColumns);
    this.isEntity = false;
  }

  getFilterView() {
    this.filterList = [
      {
        "attributeCode": "Leave Type",
        "attributeIndex": 0,
        "presetValues": [
          {
            "labelKey": "Casual Leave",
            "labelValue": "Casual Leave",
          },
          {
            "labelKey": "Earned Leave",
            "labelValue": "Earned Leave",
          },
          {
            "labelKey": "Sick Leave",
            "labelValue": "Sick Leave",
          },
        ],
      },
      {
        "attributeCode": "Status",
        "attributeIndex": 1,
        "presetValues": [
          {
            "labelKey": "Pending",
            "labelValue": "Pending",
          },
          {
            "labelKey": "Approved",
            "labelValue": "Approved",
          },
          {
            "labelKey": "Rejected",
            "labelValue": "Rejected",
          }
        ],
      },
      {
        "attributeCode": "Date",
        "attributeIndex": 2,
        "inputCode": "dateRange"
      }
    ];
  }


  getBulkUpdateAttributeData() {
    this.attributeData = {
      tabs: [
        {
          groups: [
            {
              fields: [
                {
                  attributeCode: 'approvalStatus',
                  attributeValue: 'Status',
                  isDisable: false,
                  presetValues: [
                    { labelValue: AppSettings.LEAVES_GROUP.BULK_STATUS[0].label, labelKey: AppSettings.LEAVES_GROUP.BULK_STATUS[0].label },
                    { labelValue: AppSettings.LEAVES_GROUP.BULK_STATUS[1].label, labelKey: AppSettings.LEAVES_GROUP.BULK_STATUS[1].label },
                    { labelValue: AppSettings.LEAVES_GROUP.BULK_STATUS[2].label, labelKey: AppSettings.LEAVES_GROUP.BULK_STATUS[2].label },
                  ]
                }
              ]
            }
          ]
        }
      ]
    };
    this.updateFilterFieldsArray(this.attributeData.tabs[0]?.groups[0]?.fields);
  }

  private updateFilterFieldsArray(fields: any[]) {
    if (!fields) return;
    this.isLoading = false;
    this.filterFieldsArray = [];
    fields.forEach(field => {
      if (field.attributeCode === 'approvalStatus') {
        this.filterFieldsArray.push(field);
      }
    });
  }

  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
    })
  }

  searchEntity() {
    this.tableData = [];
    this.leaveManagementService.searchEntityForLeave(AppSettings.ENTITY_CODE.LEAVES, this.entityData).subscribe((res: LeaveManagementModal) => {
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = res?.data.map(data => ({
        ...data,
        fullName: `${data.driverFirstName} ${data.driverLastName}`
      })) || [];
      this.tableData.forEach(rowData => {
        if (rowData?.driverProfileImageId && (rowData?.driverProfileImageId[0])) {
          this.getFile(rowData)
        }
      });
      this.leaveListData = res;
      this.searchEntityForGetDriverData(this.tableData);
      const fromDate = Math.min(...this.tableData.map(ele => ele.fromDate));
      const toDate = Math.max(...this.tableData.map(ele => ele.toDate));
      if (fromDate !== Infinity && toDate !== -Infinity && fromDate && toDate) {
        const fromDateStr = this.cs.formatDateFromTimestamp(fromDate);
        const toDateStr = this.cs.formatDateFromTimestamp(toDate);
        const reqBody = {
          fromDateStr: fromDateStr,
          toDateStr: toDateStr,
          forTenantCode: this.configService.getForTenantCode(),
        }
        this.callOverviewLeaveCountAPI(reqBody);
      }
    })
  }

  getFile(rowData) {
    this.entityService.getFile(rowData.driverProfileImageId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'entity');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        rowData.profileImage = base64data;
      }
    })
  }

  searchEntityForGetDriverData(tableData) {
    const driverEntityId = tableData.map(ele => ele.driverEntityId);
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  onFilterValueChange(event: { [key: string]: any }) {
    for (const [key, value] of Object.entries(event)) {
      if (value) {
        const attributeValue = Array.isArray(value) ? value : [value];
        if (key === 'Leave Type') {
          this.entityData.leaveType = attributeValue;
        } else if (key === 'Status') {
          this.entityData.status = attributeValue;
        } else if (key === 'Date') {
          const fromDate = attributeValue[0];
          const toDate = attributeValue[1];
          if (fromDate) {
            const fromDateObj = fromDate instanceof Date ? fromDate : new Date(fromDate);
            if (!isNaN(fromDateObj.getTime())) {
              this.entityData.fromDateStr = this.cs.formatDateToDDMMYYYY(fromDateObj);
            }
          }

          if (toDate) {
            const toDateObj = toDate instanceof Date ? toDate : new Date(toDate);
            if (!isNaN(toDateObj.getTime())) {
              this.entityData.toDateStr = this.cs.formatDateToDDMMYYYY(toDateObj);
            }
          }
        }
      }
    }
    if (!this.entityData.leaveType || this.entityData.leaveType.length === 0) {
      delete this.entityData.leaveType;
    }
    if (!this.entityData.status || this.entityData.status.length === 0) {
      delete this.entityData.status;
    }
    if (!this.entityData.fromDateStr) {
      delete this.entityData.fromDateStr;
    }
    if (!this.entityData.toDateStr) {
      delete this.entityData.toDateStr;
    }
    this.entityData.offset = event.first;
    this.searchEntity();
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  setCurrentRowData(event) {
    this.currentRow = event;
    this.rejectedLeaveId = event.leaveId;
    this.rowItems.find(ele => ele.label === this.cs.getLabel('leave_management.lbl_edit')).disabled = this.currentRow.approvalStatus !== AppSettings.LEAVE_REQUEST_OPTIONS.PENDING;
  }

  onRejectLeave() {
    const reqBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      approvalStatus: this.cs.getLabel('lbl_rejected')
    }
    this.confirmationService.confirm({
      header: this.rejectHeader,
      message: this.rejectMessage,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        this.leaveManagementService.rejectLeave(this.rejectedLeaveId, reqBody).subscribe(res => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_leave_rejected_successfully') });
          this.searchEntity();
        });
      }
    });
  }

  update() {
    this.onUpdateLeave(this.currentRow.leaveId);
  }

  onUpdateLeave(leaveId) {
    this.router.navigate(['app/driver-leave-management/new-leave/' + leaveId]);
  }

  onBulkDataUpdateEvent(event) {
    this.searchEntity();
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      leaveIds: event
    };
    this.leaveManagementService.deleteBulkLeaveActionStatus(requestBody).subscribe((response: any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(response.message) });
      this.searchEntity();
    })
  }

  onRowSelect(event) {
    if (event.type === 'row') {
      this.router.navigate(['app/driver-leave-management/leave-details/' + event.data.leaveId,]);
    }
  }

  onAddNewLeave() {
    this.router.navigate(["app/driver-leave-management/new-leave"]);
  }

  todaysViewAll() {
    this.router.navigate(["app/driver-leave-management/todays-view-all"])
  }

  tomorrowsViewAll() {
    this.router.navigate(["app/driver-leave-management/tomorrows-view-all"])
  }
}
