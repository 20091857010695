import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigService } from 'app/modules/shared/services/config.service';
import * as _ from 'lodash';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { AppSettings } from '../../../shared/app.settings';
import { BasicTableComponent } from '../../../shared/components/basic-table/basic-table.component';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import type { Country } from '../../../shared/models/country';
import type { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { AttributeData, entityResponse } from '../../models/attribute.models';

@Component({
  selector: 'app-vehicle-document-entity',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, BasicTableComponent, ButtonModule, TranslateModule, ToastModule],
  templateUrl: './vehicle-document-entity.component.html',
  styleUrl: './vehicle-document-entity.component.scss'
})
export class VehicleDocumentEntityComponent {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  @Output() previousClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextClick: EventEmitter<void> = new EventEmitter<void>();
  @Input() tab;
  @Input() activeIndex;
  @Input() attributeLabels = {};
  @Input() moduleName: string;
  @Input() submitBtnText: string;
  @Input() previousBtnText: string;
  @Input() attributeValues = [];
  @Input() attributeData: any;
  @Input() vehicleId;
  @Input() saveBtnId: string;
  routePath: MenuItem[] = [];
  data: AttributeData;
  vehicleAttributeData: any;
  country: Country;
  language: Language;
  cols: any = [];
  vehicleAttributeLength: number;
  vehicleDocumentId: string;
  vehicleDocument: any[] = [];
  vehicleDocumentFields: number = 5;
  start: number = 0;
  end: number = 5;
  prefixForOneToManyRelatedFields: any = '_';
  vehicleDocumentEntityIdList: any[] = [];
  attributeIdForVehicleDocument;
  edit: boolean = false;
  vehicleData: any;
  vehicleDetails: any;
  isReady: boolean = false;
  vehicleDocumentPayload: any;
  entityId: string;
  imgFileId;
  vehicleDocumentToShow;
  documentTypeValue;
  uploadDocumentValue;
  relationshipDataForVehicleDocument: any[] = [];
  removedVehicleDocumentId;
  removedIndex;
  attributeIdForVehicleEntityId: any;

  constructor(private vehicleService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    public appSettings: AppSettings,
    private configService: ConfigService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.setLabels();
    this.setVehicleId();
    this.setCountryAndLanguage();
    this.loadVehicleData();
    this.setRoutePath();
  }

  setLabels() {
    this.submitBtnText = this.cs.getLabel('lbl_next');
    this.previousBtnText = this.cs.getLabel('lbl_previous');
  }

  setVehicleId() {
    this.vehicleId = this.route.snapshot.paramMap.get('id');
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  private loadVehicleData() {
    if (this.vehicleId) {
      this.vehicleService
        .getEntity(this.vehicleId, AppSettings.ENTITY_CODE.VEHICLE, AppSettings.VIEW_CODE.ADD_EDIT_VIEW)
        .subscribe((res) => {
          if (res) {
            const data = res;
            this.vehicleDetails = res;
            this.attributeValues = this.cs.getOrgAttributeValues(data);
            this.parseAttributeValues();
            this.getVehicleDocumentData(this.attributeValues);
          }
          this.getAttributes();
        });
    } else {
      this.getAttributes();
    }
  }

  getVehicleDocumentData(attributeValues) {
    const attributeValueArray = attributeValues[AppSettings.GROUP_CODE.DOCUMENT_SECTION];
    for (const key in attributeValueArray) {
      if (Object.prototype.hasOwnProperty.call(attributeValueArray, key)) {
        const value = attributeValueArray[key];
        this.vehicleDocumentEntityIdList.push(value)
      }
    }
    const uniqueVehicleDocumentEntityIdList = Array.from(new Set(this.vehicleDocumentEntityIdList));
    if (uniqueVehicleDocumentEntityIdList.length > 0) {
      this.edit = true;
      let index = 1000;
      for (let i = 0; i < uniqueVehicleDocumentEntityIdList.length; i++) {
        this.vehicleService.getEntity(uniqueVehicleDocumentEntityIdList[i], AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((res: any) => {
          this.setAttributeValuesForVehicleDocumentEditMode(res, index);
          index = index + 1;
        });
      }
    }
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.VEHICLE;
    this.vehicleService.getAttributeDefinition(entityType,  AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.vehicleAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.vehicleAttributeLength = this.vehicleAttributeData.tabs.length;
        this.attributeIdForVehicleDocument = this.getAttributeIdForGroupCode(AppSettings.GROUP_CODE.DOCUMENTS);
        this.isReady = true;
        this.attributeIdForVehicleEntityId = this.getAttributeId('vehicle_entity_id');
      }
    });
  }

  getAttributeId(attributeCode: string): number | undefined {
    const driverDocument = _.find(this.vehicleAttributeData.relatedAttributes, { entityCode: 'vehicle_document' });
    for (const tab of driverDocument.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  getAttributeIdForGroupCode(groupCode: string): string | undefined {
    const group = _.flatMap(this.vehicleAttributeData.tabs, 'groups').find(group => group.code === groupCode);
    return group ? group.relation.ownerAttributeId : undefined;
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('lbl_add_new_vehicles'),
        routerLink: '../list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: this.vehicleId ? this.cs.getLabel('vehicle.lbl_edit_vehicle') : this.cs.getLabel('vehicle.add'),
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      }
    ];
  }

  private parseAttributeValues() {
    const dateAttributes = [
      AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_ISSUED_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_EXPIRY_DATE
    ];
    dateAttributes.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
    });

    const vehicleDocumentIdArray = this.vehicleDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === AppSettings.GROUP_CODE.DOCUMENT_SECTION);
    this.vehicleDocumentEntityIdList = (vehicleDocumentIdArray && vehicleDocumentIdArray.attributeValue.length > 0) ? vehicleDocumentIdArray.attributeValue : [];
  }

  setAttributeValuesForVehicleDocumentEditMode(data, index) {
    this.documentTypeValue = this.getAttributeValueByCode(data.attributeCodeValueDtoList, 'document_type');
    this.uploadDocumentValue = this.getAttributeValueByCode(data.attributeCodeValueDtoList, 'upload_document');
    for (const labelsObj of data.attributeCodeValueDtoList) {
      this.imgFileId = this.attributeValues[AppSettings.GROUP_CODE.DOCUMENT_SECTION];
      this.attributeValues[index + '_' + labelsObj.attributeCode] =
        (labelsObj.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_ISSUED_DATE ||
          labelsObj.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_EXPIRY_DATE) ? new Date(labelsObj.attributeValue) : labelsObj.attributeValue;
    }
  }

  getAttributeValueByCode(attributes: any[], code: string): any {
    const attribute = attributes.find(attr => attr.attributeCode === code);
    return attribute ? attribute : null;
  }

  onRemoveVehicleDocumentEntity(event) {
    this.removedIndex = event[1];
    this.removedVehicleDocumentId = typeof event[0] === 'string' ? event[0] : String(event[0]);
    this.vehicleDocumentEntityIdList = this.vehicleDocumentEntityIdList.filter(id => id !== this.removedVehicleDocumentId);
    this.saveVehicleDocumentData();
  }

  saveData(event?) {
    const vehicleFormData = event;
    this.vehicleData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.VEHICLE,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };
    const removedVehicleFormData = this.cs.removeDocumentByIndex(vehicleFormData, this.removedIndex);
    for (const [key, value] of Object.entries(removedVehicleFormData)) {
      const attributeValue = value;
      if (key.includes(this.prefixForOneToManyRelatedFields)) {
        this.setAttributeValuesForVehicleDocument(key, attributeValue);
      } else {
        this.setAttributeValuesForOtherDetails(key, attributeValue);
      }
    }
    this.saveVehicleDocumentData();
  }

  private setAttributeValuesForVehicleDocument(key, attributeValue) {
    const attributeCode = key.substring(key.indexOf('_') + 1);
    if (attributeValue) {
      const isExpiryDate = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_EXPIRY_DATE;
      const isIssuedDate = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_ISSUED_DATE;
      const value = isExpiryDate || isIssuedDate ? new Date(attributeValue).getTime() : attributeValue;
      this.vehicleDocument.push({ attributeCode, attributeValue: value });
    }
  }

  private setAttributeValuesForOtherDetails(key, attributeValue) {
    if (attributeValue) {
      this.vehicleData?.data?.push({
        'attributeCode': key,
        attributeValue
      });
    }
  }


  saveVehicleDocumentData() {
    const { countryCode } = this.country[0] || {};
    const numberOfVehicleDocument = this.vehicleDocument.length / this.vehicleDocumentFields;
    const uniqueVehicleDocumentEntityIdList = Array.from(new Set(this.vehicleDocumentEntityIdList));
    this.start = 0;
    this.end = this.vehicleDocumentFields;
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT
    }

    for (let i = 0; i < numberOfVehicleDocument; i++) {
      const vehicleDocumentPayload = {
        forTenantCode: this.configService.getForTenantCode(),
        entityCode: AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT,
        countryCode: countryCode,
        languageCode: this.language[0].langCode,
        data: this.vehicleDocument.slice(this.start, this.end),
      };

      vehicleDocumentPayload.data.push({
        attributeId: this.attributeIdForVehicleEntityId,
        attributeValue: this.vehicleId
      });
      if (uniqueVehicleDocumentEntityIdList.length > 0) {
        this.saveAttributeDataForVehicleDocumentEditMode(vehicleDocumentPayload, uniqueVehicleDocumentEntityIdList[i], i, numberOfVehicleDocument, entitiesData);
      } else {
        this.createEntityForVehicleDocument(entitiesData, vehicleDocumentPayload, i, numberOfVehicleDocument);
      }
      this.start = this.end;
      this.end = this.end + this.vehicleDocumentFields;
    }
  }


  createEntityForVehicleDocument(entitiesData, vehicleDocument, index, numberOfVehicleDocument) {
    this.vehicleService.createEntities(entitiesData.entityCode, entitiesData).subscribe({
      next: (res: entityResponse) => {
        const entityId = res.entityId;
        this.entityId = res.entityId;
        this.vehicleDocumentEntityIdList.push(entityId);
        vehicleDocument.data = this.cs.mapAttributeIdsForRelatedData(vehicleDocument.data, this.attributeData.tabs);
        this.vehicleService.saveAttributeData(AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT, entityId, vehicleDocument).subscribe((res: any) => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
        });
        if (index === (numberOfVehicleDocument - 1)) {
          this.vehicleData.data.push({
            attributeId: this.attributeIdForVehicleDocument,
            attributeValue: this.vehicleDocumentEntityIdList
          })
          this.saveVehicleData(this.vehicleData);
        }
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel(error) });
      }
    });
  }

  saveAttributeDataForVehicleDocumentEditMode(vehicleDocument, entityId, index, numberVehicleDocumentData, entitiesData) {
    vehicleDocument.data = this.cs.mapAttributeIdsForRelatedData(vehicleDocument.data, this.attributeData.tabs);
    if (entityId) {
      this.vehicleService.saveAttributeData(vehicleDocument.entityCode, entityId, vehicleDocument).subscribe(() => {
        this.relationshipDataForVehicleDocument.push(
          {
            entityRelationshipConfigId: this.vehicleAttributeData.tabs[1].groups.find(ele => ele.code === AppSettings.GROUP_CODE.DOCUMENTS).relation.entityRelationshipConfigId,
            otherEntityId: entityId
          }
        );
        if (index === (numberVehicleDocumentData - 1)) {
          const uniqueVehicleDocumentEntityIdList = Array.from(new Set(this.vehicleDocumentEntityIdList));
          this.vehicleData.data.push({
            attributeId: this.attributeIdForVehicleDocument,
            attributeValue: uniqueVehicleDocumentEntityIdList
          });
          this.savePrivilegeDataToDb();
        }
      });
    } else {
      this.createEntityForVehicleDocument(entitiesData, vehicleDocument, index, numberVehicleDocumentData);
    }
  }

  savePrivilegeDataToDb() {
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: this.data.entityCode
    }
    this.vehicleData.relationshipData = this.relationshipDataForVehicleDocument;
    if (this.edit) {
      this.saveAttributeData(entitiesData.entityCode)
    } else {
      this.createEntityAndUpdateAttributeData(entitiesData);
    }
  }

  saveAttributeData(entityCode) {
    this.vehicleService.saveAttributeData(entityCode, this.vehicleId, this.vehicleData).subscribe(() => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('vehicle.vehicle_added') });
    });
  }

  createEntityAndUpdateAttributeData(entitiesData) {
    this.vehicleService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.vehicleId = res.entityId;
      this.vehicleService.saveAttributeData(entitiesData.entityCode, this.vehicleId, this.vehicleData).subscribe(() => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('vehicle.vehicle_added') });
      });
    });
  }

  saveVehicleData(vehicleData) {
    const { entityRelationshipConfigId } = this.vehicleAttributeData?.tabs[1]?.groups.find(ele => ele.code === AppSettings.GROUP_CODE.DOCUMENTS)?.relation || {};
    this.vehicleData.relationshipData = [];
    for (let index = 0; index < this.vehicleDocumentEntityIdList.length; index++) {
      this.vehicleData.relationshipData.push({ entityRelationshipConfigId, otherEntityId: this.vehicleDocumentEntityIdList[index] });
    }
    this.vehicleService.saveAttributeData(AppSettings.ENTITY_CODE.VEHICLE, this.vehicleId, vehicleData).subscribe(() => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('vehicle.vehicle_added'), });
    });
  }

  onPreviousClick() {
    this.previousClick.emit();
  }

  onNextClick() {
    this.nextClick.emit();
  }

  getLastIndex(): number {
    return this.vehicleAttributeLength - 1;
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(['app/vehicles/list']);
  }
}
