import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { AppSettings } from '../../../shared/app.settings';
import { BasicTableComponent } from '../../../shared/components/basic-table/basic-table.component';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { AttributeData, entityResponse } from '../../../vehicles/models/attribute.models';

import { CardModule } from 'primeng/card';
import { SelectedDriverComponent } from '../selected-driver/selected-driver.component';

@Component({
  selector: 'app-add-group',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, BasicTableComponent, ButtonModule, TranslateModule, SelectedDriverComponent, CardModule],
  templateUrl: './add-group.component.html',
  styleUrl: './add-group.component.scss'
})
export class AddGroupComponent implements OnInit {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  @ViewChild(SelectedDriverComponent) selectedDriverComponent: SelectedDriverComponent;
  activeIndex: number = 1;
  data: AttributeData;
  groupAttributeData: any = {};
  routePath: MenuItem[] = [];
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  groupAttributeLength: number;
  groupId: string;
  attributeValues: any = {};
  nextBtnLabel: string;
  saveAndAddBtnText: string;
  groupEntityData: any;
  groupDetails: {};
  selectedColumns = [];
  attributeIdForSelectedColumns: any;
  selectedDriver = [];

  constructor(private groupService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private configService: ConfigService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.setLabels();
    this.setAppSettings();
    this.setRoutePath();
    this.loadGroupData();
  }

  private setLabels() {
    this.groupId = this.route.snapshot.paramMap.get('id');
    this.nextBtnLabel = this.groupId ? this.cs.getLabel('manage_group.lbl_update') : this.cs.getLabel('manage_group.lbl_save');
    this.saveAndAddBtnText = this.cs.getLabel('manage_group.lbl_save_and_add_another');
  }

  private setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.moduleName = AppSettings.ENTITY_CODE.DRIVER_GROUP;
  }

  private loadGroupData() {
    if (this.groupId) {
      this.groupService
        .getEntity(this.groupId, AppSettings.ENTITY_CODE.DRIVER_GROUP, AppSettings.VIEW_CODE.ADD_EDIT_VIEW)
        .subscribe((res) => {
          if (res) {
            const data = res;
            this.attributeValues = this.cs.getOrgAttributeValues(data) as any;
            this.selectedDriver = this.attributeValues.assigned_drivers;
            this.getAttributes();
          }
        });
    } else {
      this.getAttributes();
    }
  }

  private getAttributes() {
    const entityType = AppSettings.ENTITY_CODE.DRIVER_GROUP;
    this.groupService.getAttributeDefinition(entityType,  AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.groupAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.groupAttributeLength = this.groupAttributeData.tabs.length;
        this.attributeIdForSelectedColumns = this.getAttributeId('added_drivers');
      }
    });
  }

  getAttributeId(attributeCode: string): number | undefined {
    for (const tab of this.groupAttributeData.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.groupId ? this.cs.getLabel('manage_group.lbl_edit_group') : this.cs.getLabel('manage_group.lbl_add_group'),
        routerLink: this.groupId ? '../../' : '../',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      }
    ];
  }

  onChangeSelectedDriver(event) {
    this.selectedColumns = event.map(ele => ele.id);
  }

  onSaveGroups(event) {
    let groupData;
    groupData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };

    const groupFormData = event;
    for (const [key, value] of Object.entries(groupFormData)) {
      const attributeValue = <any>value;
      if (attributeValue) {
        const obj = typeof attributeValue;
        if (obj == "object") {
          if (attributeValue?.length > 0) {
            groupData?.data?.push({
              attributeCode: key,
              attributeValue,
            });
          }
        } else {
          groupData?.data?.push({
            attributeCode: key,
            attributeValue,
          });
        }
      }
    }
    groupData?.data?.push({
      attributeId: this.attributeIdForSelectedColumns,
      attributeValue: this.selectedColumns
    })

    this.setGroupEntityData(this.data.entityCode);
    if (!this.groupId) {
      this.groupService.createEntities(this.groupEntityData.entityCode, this.groupEntityData).subscribe((res: entityResponse) => {
        this.groupId = res.entityId;
        this.saveGroupData(this.groupEntityData.entityCode, groupData, true);
      });
    } else {
      this.saveGroupData(this.groupEntityData.entityCode, groupData, false);
    }
  }

  setGroupEntityData(entityCode) {
    this.groupEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: entityCode
    }
  }

  saveGroupData(entityCode, groupData, isNew: boolean) {
    groupData.data = this.cs.mapAttributeIds(groupData.data, this.groupAttributeData.tabs);
    this.groupService.saveAttributeData(entityCode, this.groupId, groupData).subscribe((res: any) => {
      if (isNew) {
        this.cs.onDisplayMessage(this.data, 'driver_group', AppSettings.MSG_ACTION.ADD, 'success');
      } else {
        this.cs.onDisplayMessage(this.data, 'driver_group', AppSettings.MSG_ACTION.UPDATE, 'success');
      }
      this.router.navigate(['app/manage-groups']);
    });
  }


  onSaveAndAddGroups(event) {
    let groupData;
    groupData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };
    const groupFormData = event;
    for (const [key, value] of Object.entries(groupFormData)) {
      const attributeValue = <any>value;
      if (attributeValue) {
        const obj = typeof attributeValue;
        if (obj == "object") {
          if (attributeValue?.length > 0) {
            groupData?.data?.push({
              attributeCode: key,
              attributeValue,
            });
          }
        } else {
          groupData?.data?.push({
            attributeCode: key,
            attributeValue,
          });
        }
      }
    }

    groupData?.data?.push({
      attributeId: this.attributeIdForSelectedColumns,
      attributeValue: this.selectedColumns
    })

    this.setGroupEntityData(this.data.entityCode);
    if (!this.groupId) {
      this.groupService.createEntities(this.groupEntityData.entityCode, this.groupEntityData).subscribe((res: entityResponse) => {
        this.groupId = res.entityId;
        this.saveAndAddGroupData(this.groupEntityData.entityCode, groupData);
      });
    } else {
      this.saveAndAddGroupData(this.groupEntityData.entityCode, groupData);
    }
  }

  saveAndAddGroupData(entityCode, groupData) {
    groupData.data = this.cs.mapAttributeIds(groupData.data, this.groupAttributeData.tabs);
    this.groupService.saveAttributeData(entityCode, this.groupId, groupData).subscribe(res => {
      this.cs.onDisplayMessage(this.data, 'driver_group', AppSettings.MSG_ACTION.ADD, 'success');
      this.miFormComponent.resetForm();
      this.selectedDriverComponent.clearSelectedColumns();
    });
  }

  onPreviousClick() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
    if (this.isLastIndex() === false) {
      this.nextBtnLabel = this.cs.getLabel('lbl_next');
    }
  }

  onNextClick() {
    if (this.activeIndex < this.groupAttributeLength - 1) {
      this.activeIndex++;
    }
    if (this.isLastIndex() === true) {
      this.nextBtnLabel = this.cs.getLabel('label_save');
    }
  }

  getLastIndex(): number {
    return this.groupAttributeLength - 1;
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  onNextBtnClick() {
    const miFormGroup = this.miFormComponent.miFormGroup;

    if (miFormGroup.valid) {
      this.onSaveGroups(miFormGroup.value);
    } else {
      miFormGroup.markAllAsTouched();
      const firstElementWithError = document.querySelector('.ng-invalid');
      this.miFormComponent.scrollTo(firstElementWithError);
    }
  }

  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(['app/manage-groups']);
  }
}

