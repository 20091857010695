import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';

@Component({
  selector: 'app-select-button',
  standalone: true,
  imports: [TabMenuModule],
  templateUrl: './select-button.component.html',
  styleUrl: './select-button.component.scss'
})
export class SelectButtonComponent implements OnInit {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  items = [];
  activeItem: MenuItem | undefined;


  constructor(
    private formGroupDirective: FormGroupDirective) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {

    this.field.presetValues = [
      {
        "labelKey": "Car",
        "labelValue": "Car",
        "parentLabelKey": null
      },
      {
        "labelKey": "Shuttle",
        "labelValue": "Shuttle",
        "parentLabelKey": null
      },
      {
        "labelKey": "Bus",
        "labelValue": "Bus",
        "parentLabelKey": null
      },
      {
        "labelKey": "Truck",
        "labelValue": "Truck",
        "parentLabelKey": null
      }
    ];
    this.field.presetValues.map(res => {
      const className = res.labelKey.toLowerCase();
      this.items.push({ label: res.labelValue, icon: `pi pi-fw mi-${className} wh-24 mi-lg` })
    });
    this.activeItem = this.items[0];
  }

  onActiveItemChange(event: MenuItem) {
    this.activeItem = event;
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(this.activeItem.label);
  }


}
