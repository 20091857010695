import { NgClass } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import * as _ from 'lodash';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AppSettings } from "app/modules/shared/app.settings";
import { Country } from "app/modules/shared/models/country";
import { Language } from "app/modules/shared/models/language";
import { CommonBindingDataService } from "app/modules/shared/services/common-binding-data.service";
import { ConfigService } from "app/modules/shared/services/config.service";
import { EntityService } from "app/modules/shared/services/entity.service";
import { MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { OverlayPanel, OverlayPanelModule } from "primeng/overlaypanel";

@Component({
  selector: "app-driver-list-card",
  templateUrl: "./driver-list-card.component.html",
  styleUrls: ["./driver-list-card.component.scss"],
  standalone: true,
  imports: [ButtonModule, NgClass, OverlayPanelModule, TranslateModule],
})
export class DriverListCardComponent implements OnInit {

  isHovered: boolean = false;
  @ViewChild("op") op: OverlayPanel;
  @Input() driverDetail: any;
  @Input() forDetail: boolean = false;
  @Input() bookingId;
  @Input() isBookingScreen: boolean = true;
  @Output() closeBtnClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() driverCardClicked: EventEmitter<any> = new EventEmitter<any>();
  country: Country;
  language: Language;
  groupName: any;
  nameCode: any;
  manufacturer: any;
  variant: any;
  vehicleType: any;
  passengerCapacity: any;
  constructor(private enitityService: EntityService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private cs: CommonBindingDataService) { }



  ngOnInit(): void {
    const group = _.find(this.driverDetail.drivers, driver => driver.group_name);
    this.groupName = group ? group.group_name : null;

    const name = _.find(this.driverDetail.drivers, driver => driver.name_code);
    this.nameCode = name ? name.name_code : null;

    const manufacturer = _.find(this.driverDetail.drivers, driver => driver.manufacturer);
    this.manufacturer = manufacturer ? manufacturer.manufacturer : null;

    const variant = _.find(this.driverDetail.drivers, driver => driver.variant);
    this.variant = variant ? variant.variant : null;

    const vehicle_type = _.find(this.driverDetail.drivers, driver => driver.vehicle_type);
    this.vehicleType = vehicle_type ? vehicle_type.vehicle_type : null;

    const passenger_capacity = _.find(this.driverDetail.drivers, driver => driver.passenger_capacity);
    this.passengerCapacity = passenger_capacity ? passenger_capacity.passenger_capacity : null;

  }


  onCardClick(item) {
    const driverDetails = {
      driver: item,
      bookingId: this.bookingId
    }
    this.driverCardClicked.emit(driverDetails);
  }

  onCloseDetailView() {
    this.closeBtnClicked.emit(false);
  }

  onMouseEnter(event: MouseEvent) {
    this.isHovered = true;
    this.op.toggle(event);
  }

  onMouseLeave(event: MouseEvent) {
    this.isHovered = false;
    this.op.hide();
  }
  onAssignClick(driverDetail, event: Event) {
    event.stopPropagation();
    if (this.bookingId) {
      this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
      this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
      const data = {
        forTenantCode: this.configService.getForTenantCode(),
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        bookingEntityId: this.bookingId,
        driverEntityId: driverDetail.id
      }
      this.enitityService.assignDriver(data).subscribe(() => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('dispatch.driver_assign_msg') });
      })
    } else {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('dispatch.booking_assign_first') });
    }
  }
}
