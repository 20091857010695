import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { AutoFocusModule } from 'primeng/autofocus';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-select-button-with-add-new',
  standalone: true,
  imports: [
    SelectButtonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DialogModule,
    ButtonModule,
    InputTextModule,
    AutoFocusModule,
    MenuModule,
    ToastModule
  ],
  templateUrl: './select-button-with-add-new.component.html',
  styleUrl: './select-button-with-add-new.component.scss'
})
export class SelectButtonWithAddNewComponent {
  @Input() field: any;
  @Input() group: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() selectedButton: string = 'Home';
  @Input() locationTypeList: any = [];
  @Output() onNewAddressAdd: EventEmitter<boolean> = new EventEmitter();
  @Output() onSelectButtonChange: EventEmitter<string> = new EventEmitter();
  @Output() onAddressDelete: EventEmitter<string> = new EventEmitter();
  @Output() onAddressUpdate: EventEmitter<string> = new EventEmitter();
  formName: FormGroup;
  placeholder: any;
  items: MenuItem[] | undefined
  showSelectButton: boolean = true;
  showAddPopup: boolean = false;
  header: any = 'Add Location Type';
  locationNameGroup: FormGroup;
  isEditLabel: boolean = false;

  constructor(private formGroupDirective: FormGroupDirective,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
    this.locationNameGroup = this.fb.group({
      name: ['', [Validators.required]]
    });
    this.items = [
      {
        label: 'Edit Location Label', icon: 'mi-lg basic_edit_2',
        command: () => {
          this.updateLabel()
        }
      },
      {
        separator: true
      },
      {
        label: 'Delete Location', icon: 'mi-lg basic-delete',
        command: () => {
          this.deleteAddress()
        }
      }
    ];
  }

  updateLabel() {
    this.showAddPopup = true;
    this.isEditLabel = true;
    this.locationNameGroup.controls.name.setValue(this.selectedButton);
  }

  deleteAddress() {
    this.confirmationService.confirm({
      header: this.cs.getLabel('passengers.lbl_delete_location'),
      message: this.cs.getLabel('passengers.lbl_are_you_sure_you_want_to_delete_this_location'),
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'bg-white text-color cancel',
      acceptButtonStyleClass: 'bg-red-500 delete',
      acceptLabel: this.cs.getLabel('lbl_delete'),
      rejectLabel: this.cs.getLabel('lbl_cancel'),
      accept: () => {
        this.onAddressDelete.emit(this.selectedButton);
      },
      reject: () => {
      }
    });
  }

  addMore() {
    this.showAddPopup = true;
    this.locationNameGroup.reset();
  }

  locationNameBlur() {
    this.locationNameGroup.controls['name'].setValue(this.locationNameGroup.controls['name'].value?.trim());
  }

  AddUpdateLocation() {
    if (!this.isEditLabel) {
      this.addLocationLabel();
    } else {
      this.updateLocationLabel();
    }
  }

  addLocationLabel() {
    this.locationTypeList.push({ labelKey: this.locationNameGroup.controls.name.value, labelValue: this.locationNameGroup.controls.name.value, entityId: null, index: this.locationTypeList.length + 1 });
    this.cd.detectChanges();
    this.onNewAddressAdd.emit(this.locationNameGroup.controls.name.value);
    this.showSelectButton = false;
    this.cd.detectChanges();
    this.showSelectButton = true;
    this.showAddPopup = false;
  }

  updateLocationLabel() {
    const index = this.locationTypeList.findIndex(element => element.labelKey === this.selectedButton);
    if (index) {
      this.locationTypeList[index].labelKey = this.locationTypeList[index].labelValue = this.locationNameGroup.controls.name.value;
      this.setLocationTypeForUpdatedLocation();
      this.selectedButton = this.locationNameGroup.controls.name.value;
      this.onSelectButtonChange.emit(this.selectedButton);
      this.isEditLabel = false;
      this.showAddPopup = false;
      this.cd.detectChanges();
    }
  }

  setLocationTypeForUpdatedLocation() {
    this.group.fields.forEach(element => {
      if (element.locationType === this.selectedButton) {
        element.locationType = this.locationNameGroup.controls.name.value
      }
    });
  }

  onChange(event) {
    this.selectedButton = event.value;
    this.onSelectButtonChange.emit(this.selectedButton);
  }
}
