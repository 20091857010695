<p-sidebar [(visible)]="passengerAddressVisible" position="right">
    <header>
        <h5 translate>lbl_save_new_address</h5>
    </header>

    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="{{miIcons.MANAGE_ZONES}} wh-14 inline-block mr-2"></i>
                <span translate>lbl_locations</span>
            </ng-template>
            <ng-template pTemplate="body">
                <app-booking-passenger-location (closeAddressDialog)="closeAddressDialog()" [passengerId]="passengerId"></app-booking-passenger-location>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="{{miIcons.BOOKMARK}} wh-14 inline-block mr-2"></i>
                <span translate>lbl_saved_addresses</span>
            </ng-template>
            <ng-template pTemplate="body">
                <app-booking-passenger-saved-address [passengerId]="passengerId"></app-booking-passenger-saved-address>
                <button pButton class="float-r mr-2 mt-100px bg-white default-text-black-color cursor-pointer border-round border-black-alpha-40 width-100px" [label]="'cancel' | translate" (click)="closeAddressDialog()"></button>
            </ng-template>
        </p-tabPanel>
    </p-tabView>

</p-sidebar>
