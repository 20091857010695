@if(isShowBookingMap) {
    <form [formGroup]="formName">
        <div class="flex">
            <span id="googleSearch" class="p-input-icon-left">
                <i class="pi search-icon relative left-30 cursor-pointer pi-search"></i>
                <input [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" class="search-field" type="text"
                    pInputText id="autocompleteSearch" [placeholder]="'lbl_search' | translate" appSearchLocation (setAddress)="getAndStoreAddress($event)"/>
                <!-- <input id="autocompleteSearch" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" type="text" 
                    pInputText class="search-field"  [placeholder]="'lbl_search' | translate"/> -->
            </span>
        </div>
    </form>
    <div class="map-div mb-3 mt-3" id="map"></div>
} @else {
    <form [formGroup]="formName">
        <div class="flex">
            @if(moduleName === 'booking') {
                <p-dropdown [options]="displayedAddresses" [placeholder]="placeholder"  [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [class]="field.attributeCode" (onChange)="changedValue()">
                    <ng-template pTemplate="header">
                        <div class="dropdown-header">
                            <div class="flex justify-content-between align-items-center p-2">
                                <div>
                                     <span class="saved-address px-2 py-1 mr-2" (click)="showLocationOption('saved')" [ngClass]="{'active-class': addressOption === 'saved'}">Saved Addresses</span>
                                     <span class="predefined-address px-2 py-1" (click)="showLocationOption('predefined')" [ngClass]="{'active-class': addressOption === 'predefined'}">Predefined Addresses</span>
                                </div>
                                <div>
                                    <p-button [label]="'lbl_add_address' | translate" class=" default-text-blue-color cursor-pointer font-semibold add-new" [outlined]="true"
                                        icon="pi pi-plus-circle" (click)="passengerSidebarOpen()"></p-button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template let-address pTemplate="item">
                        <div class="dropdown-content p-0">
                            <div class="address-item border-none">
                                <div class="address-header">
                                    @if (address['select_location_type'] === 'Home') {
                                        <i class="{{miIcons.HOME_DARK}} wh-16 inline-block mr-2 relative top-2"></i>
                                    }
                                    @else if (address['select_location_type'] === 'Hotel') {
                                        <i class="{{miIcons.HOTEL_DARK}} wh-16 inline-block mr-2 relative top-2"></i>
                                    }
                                    @else {
                                        <i class="{{miIcons.DRIVER_RATING}} wh-16 inline-block mr-2 relative top-2"></i>
                                    }
                                    
                                    <span class="font-semibold font-16">
                                        {{ address['select_location_type'] }}
                                    </span>
                                </div>
                                <div class="ml-5 mt-2">
                                    @if(address['select_location_address']) {
                                        {{ address['select_location_address'] }},
                                    }
                                    @if(address['address_line_1']) {
                                        {{ address['address_line_1'] }},
                                    }
                                    @if(address['address_line_2']) {
                                        {{ address['address_line_2'] }},
                                    }
                                    @if(address['nearby_landmark']) {
                                        {{ address['nearby_landmark'] }},
                                    }
                                    @if(address['state']) {
                                        {{ address['state'] }},
                                    }
                                    @if(address['country']) {
                                       {{ address['country'] }} - {{ address['postal_code'] }}
                                    }
                                    <div class="address-action float-r cursor-pointer">
                                        <i class="{{miIcons.BASIC_DELETE}} wh-21 inline-block mr-2" (click)="removeAddress(address)"></i>
                                    </div>
                                </div>
                            </div>
                            <hr class="hr-line">
                        </div>
                    </ng-template>
                </p-dropdown>
            } @else {
                <p-dropdown #miSelect [options]="field.presetValues" optionLabel="labelValue.address" [placeholder]="placeholder"
                    optionValue="labelValue" (onChange)="changedValue()"
                    [filter]="true"
                    filterBy="labelValue.address" 
                    [showClear]="true"
                    [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [class]="field.attributeCode"></p-dropdown>
            }
            <div [ngStyle]="{ width: moduleName === 'booking' ? '0' : '100%' }">
                <p-button [class]="moduleName === ENTITY_CODE.PASSENGER_ADDRESS ? 'geo-location' : 'location'" class="inline-flex ml-3" icon="mi-lg wh-24" [icon]="moduleName === ENTITY_CODE.PASSENGER_ADDRESS ? 'basic-location' : 'mi-basic-location'" (click)="showDialog()"></p-button>
            </div>
        </div>
    </form>

    <p-dialog header="Geo Location" (onHide)="close()" focusOnShow="false" [(visible)]="visible" [style]="{width: '50vw'}">
        <div class="map-div mb-3 mt-3" id="map">
        </div>

        <div class="col-12 save-div mb-3 pr-0">
            <button pButton class="cancel mr-20 text-color height-44 border-button" (click)="close()" [label]="cancelBtnText"></button>
            <p-button class="inline-flex height-44" (click)="saveGeoLocation()" [label]="saveBtnText"></p-button>
        </div>
    </p-dialog>
}

<!-- <input style="display: none;" type="text" pInputText [formControlName]="field.attributeCode" class="w-full" /> -->
@if (showGoogleSearchBox) {
    <span id="googleSearch" class="p-input-icon-left ">
        <i class="pi search-icon relative left-30 cursor-pointer pi-search"></i>
        <input id="autocompleteSearch" [(ngModel)]="searchText" type="text" 
        pInputText class="search-field"  [placeholder]="'lbl_search' | translate"
        />
    </span>
}
