import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';

import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { AppSettings } from '../../../shared/app.settings';
import type { Country } from '../../../shared/models/country';
import type { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { AttributeData, entityResponse } from '../../../vehicles/models/attribute.models';

@Component({
  selector: 'app-add-broadcast',
  standalone: true,
  imports: [TranslateModule, MiFormComponent, DialogModule],
  templateUrl: './add-broadcast.component.html',
  styleUrl: './add-broadcast.component.scss'
})
export class AddBroadcastComponent implements OnInit {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Input() isAddBroadcastVisible;
  @Input() broadcastIdForUpdate;
  activeIndex: number = 1;
  data: AttributeData;
  broadcastAttributeData: any = {};
  routePath: MenuItem[] = [];
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  broadcastAttributeLength: number;
  attributeValues = [];
  nextBtnLabel: string;
  previousBtnText: string;
  broadcastEntityData: any;
  header: string;
  isShowDialog: boolean = false;

  constructor(private broadcastService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private configService: ConfigService) { }

  ngOnInit() {
    this.setLabels();
    this.setAppSettings();
    this.setRoutePath();
    this.getDataForEdit();
  }

  private setLabels() {
    this.header = this.cs.getLabel('broadcasting.lbl_send_notification')
    this.nextBtnLabel = this.cs.getLabel('broadcasting.lbl_send');
    this.previousBtnText = this.cs.getLabel('broadcasting.message.cancel');
    this.miFormComponent?.resetForm();
  }

  private setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.moduleName = AppSettings.ENTITY_CODE.BROADCAST_MESSAGE;
  }

  private setRoutePath() {
    this.routePath = [
      {
        label: this.broadcastIdForUpdate ? this.cs.getLabel('broadcasting.edit') : this.cs.getLabel('broadcasting.lbl_send'),
        routerLink: '../../../app/communication/broadcast',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      }
    ];
  }

  private getDataForEdit() {
    if (this.broadcastIdForUpdate) {
      this.loadBroadcastData(this.broadcastIdForUpdate);
    } else {
      this.loadBroadcastData(null);
    }
  }

  private loadBroadcastData(broadcastIdForUpdate) {
    if (broadcastIdForUpdate) {
      this.broadcastService
        .getEntity(broadcastIdForUpdate, AppSettings.ENTITY_CODE.BROADCAST_MESSAGE, AppSettings.VIEW_CODE.ADD_EDIT_VIEW)
        .subscribe((res) => {
          if (res) {
            const data = res;
            this.attributeValues = this.cs.getOrgAttributeValues(data);
            this.getAttributes();
          }
        });
    } else {
      this.getAttributes();
    }
  }

  private getAttributes() {
    const entityType = AppSettings.ENTITY_CODE.BROADCAST_MESSAGE;
    this.broadcastService.getAttributeDefinition(entityType,  AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.broadcastAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.isShowDialog = true;
        this.broadcastAttributeLength = this.broadcastAttributeData.tabs.length;
      }
    });
  }


  onSaveBroadcast(event) {
    let broadcastData;
    broadcastData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0]?.countryCode,
      languageCode: this.language[0]?.langCode,
      data: [],
    };

    const broadcastFormData = event;
    const dateIds = [
      AppSettings.DATE_ATTRIBUTE_IDS.BROADCAST_SELECT_TIME,
    ];
    for (const [key, value] of Object.entries(broadcastFormData)) {
      let attributeValue = <any>value;
      if (dateIds.includes(key)) {
        attributeValue = new Date(<string>value).getTime();
      }
      if (attributeValue) {
        const obj = typeof attributeValue;
        if (obj == "object") {
          if (attributeValue?.length > 0) {
            broadcastData?.data?.push({
              attributeCode: key,
              attributeValue,
            });
          }
        } else {
          broadcastData?.data?.push({
            attributeCode: key,
            attributeValue,
          });
        }
      }
    }

    this.setbroadcastEntityData(this.data.entityCode);
    if (!this.broadcastIdForUpdate) {
      this.broadcastService.createEntities(this.broadcastEntityData.entityCode, this.broadcastEntityData).subscribe((res: entityResponse) => {
        this.broadcastIdForUpdate = res.entityId;
        this.saveBroadcastData(this.broadcastEntityData.entityCode, broadcastData);
      });
    } else {
      this.saveBroadcastData(this.broadcastEntityData.entityCode, broadcastData);
    }
  }

  setbroadcastEntityData(entityCode) {
    this.broadcastEntityData = {
      countryCode: this.country[0]?.countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: entityCode
    }
  }

  saveBroadcastData(entityCode, broadcastData) {
    broadcastData.data = this.cs.mapAttributeIds(broadcastData.data, this.broadcastAttributeData.tabs);
    this.broadcastService.saveAttributeData(entityCode, this.broadcastIdForUpdate, broadcastData).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('broadcasting.broadcast_added_successfully'), });
      this.isAddBroadcastVisible = false;
      this.router.navigate(['../../../app/communication/broadcast']);
    });
  }

  onPreviousClick() {
    this.isAddBroadcastVisible = false;
    this.onToggleOverLay.emit(this.isAddBroadcastVisible);
  }

  onHide() {
    this.isAddBroadcastVisible = false;
    this.onToggleOverLay.emit(this.isAddBroadcastVisible);
  }

}
