import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { ManageZonesService } from "../../services/manage-zones.service";
import { TranslateModule } from "@ngx-translate/core";
import { ZoneItem } from "../../models/zone.models";
import { ZoneMapComponent } from "../zone-map/zone-map.component";
import data from "../../../../../../src/assets/json/dashboard/data.json";

@Component({
  selector: "app-zone-detail",
  templateUrl: "./zone-detail.component.html",
  styleUrls: ["./zone-detail.component.scss"],
  standalone: true,
  imports: [BreadcrumbModule, ButtonModule, TranslateModule, ZoneMapComponent],
})
export class ZoneDetailComponent implements OnInit {
  routePath: MenuItem[];
  zoneId: string;
  editBtnLabel: string;
  zoneItem: ZoneItem;
  zoneDetailsItem: any;
  zoneType: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cs: CommonBindingDataService,
    private manageZonesService: ManageZonesService
  ) { }

  ngOnInit() {
    this.zoneId = this.route.snapshot.paramMap.get("id");
    this.editBtnLabel = this.cs.getLabel("lbl_edit");
    this.getZoneDetails();
    this.setRoutePath();
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel("manage_zones.manage_zone"),
        routerLink: "../",
        icon: "pi pi-arrow-left",
        iconStyle: { "font-weight": "bold", "margin-right": "10px" },
      },
      {
        label: this.cs.getLabel("driver.lbl_overview"),
        routerLink: "../" + this.zoneId,
        styleClass: "breadcrumb-child forward-slash breadcrumb-text",
        style: { display: "flex", top: "2px", position: "relative" },
      },
    ];
  }

  getZoneDetails() {
    this.manageZonesService.getZoneDetails(this.zoneId).subscribe((zoneData: ZoneItem) => {
      this.zoneItem = zoneData;

      if (zoneData.zoneType === "GEO") {
        this.zoneDetailsItem = {
          coords: zoneData.geoFence.split(",").map(coord => {
            const [lng, lat] = coord.split(" ").map(parseFloat);
            return { lat, lng };
          }),
          strokeColor: zoneData.zoneColor,
          label: zoneData.zoneName,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: zoneData.zoneColor,
          fillOpacity: 0.35
        };
        this.zoneType = "GEO";
      } else if (zoneData.zoneType === "RADIUS") {
        this.zoneDetailsItem = {
          center: { lat: zoneData.latitude, lng: zoneData.longitude },
          radius: zoneData.radius,
          strokeColor: zoneData.zoneColor,
          label: zoneData.zoneName,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: zoneData.zoneColor,
          fillOpacity: 0.35
        };
        this.zoneType = "RADIUS";
      }
    });
  }

  editZone(zoneId: string) {
    this.router.navigate(["app/manage-zones/edit/" + zoneId]);
  }
}
