import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { AppSettings } from '../../../shared/app.settings';
import { BasicTableComponent } from '../../../shared/components/basic-table/basic-table.component';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { AttributeData, entityResponse } from '../../../vehicles/models/attribute.models';
import { EntityService } from '../../../shared/services/entity.service';
import { forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DriverDrivingRecordEntityComponent } from '../driver-driving-record-entity/driver-driving-record-entity.component';
import { DriverDocumentEntityComponent } from '../driver-document-entity/driver-document-entity.component';
import { ConfigService } from 'app/modules/shared/services/config.service';

@Component({
  selector: 'app-add-driver',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, BasicTableComponent, ButtonModule, TranslateModule, DriverDrivingRecordEntityComponent, DriverDocumentEntityComponent],
  templateUrl: './add-driver.component.html',
  styleUrl: './add-driver.component.scss'
})
export class AddDriverComponent {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  routePath: MenuItem[] = [];
  data: AttributeData;
  driverAttributeData: any;
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  cols: any = [];
  driverAttributeLength: number;
  activeIndex: number = 0;
  nextBtnLabel: string;
  previousBtnLabel: string;
  saveDriverBtnLabel: string;
  driverId: string;
  driverIdForDrivingRecord: string;
  driverDocumentId: string;
  attributeValues = [];
  driverData: any;
  driverDetails: any;
  edit: boolean = false;
  driverIdForDocument: string;

  constructor(private driverService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    public appSettings: AppSettings,
    private confService: ConfigService) { }

  ngOnInit() {
    this.setDriverId();
    this.setLabels();
    this.moduleName = AppSettings.ENTITY_TYPE.DRIVER;
    this.setCountryAndLanguage();
    this.loadDriverData();
    this.setRoutePath();
  }

  setLabels() {
    this.nextBtnLabel = this.cs.getLabel('lbl_next');
    this.previousBtnLabel = this.cs.getLabel('lbl_previous');
    this.saveDriverBtnLabel = this.driverId ? this.cs.getLabel('lbl_update_driver') : this.cs.getLabel('lbl_save_driver');
  }

  setDriverId() {
    this.driverId = this.route.snapshot.paramMap.get('id');
    if (this.driverId) {
      this.driverIdForDrivingRecord = this.driverId;
      this.driverIdForDocument = this.driverId;
    }
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  private loadDriverData() {
    if (this.driverId) {
      this.driverService
        .getEntity(this.driverId, AppSettings.ENTITY_CODE.DRIVER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW)
        .subscribe((res) => {
          if (res) {
            const data = res;
            this.attributeValues = this.cs.getOrgAttributeValues(data);
            this.parseAttributeValues();
          }
          this.getAttributes();
        });
    } else {
      this.getAttributes();
    }
  }

  private parseAttributeValues() {
    const dateAttributes = [
      AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DATE_OF_BIRTH,
    ];
    dateAttributes.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
    });
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.DRIVER;
    const addEditView = this.driverService.getCachedEntityView(entityType, AppSettings.VIEW_CODE.ADD_EDIT_VIEW);
    if (addEditView) {
      this.setDriverView(addEditView);
      return;
    }
    
    this.driverService.getAttributeDefinition(entityType,  AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.setDriverView(res);
        this.driverService.setEntityView(entityType, AppSettings.VIEW_CODE.ADD_EDIT_VIEW, res);       
      }
    });
  }

  setDriverView(res) {
    this.data = res;
    this.attributeLabels = this.cs.getAttributeLabels(this.data);
    this.driverAttributeData = this.cs.getOrganizedAttributeForDriver(this.data);
    this.driverAttributeLength = this.driverAttributeData.tabs.length;
    const documentTab = this.driverAttributeData.tabs.find(ele => ele.tabCode === 'documents');
    const groups = documentTab.groups.find(ele => ele.code === 'driver_document');
    const issuedDateField = groups.fields.find(ele => ele.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_ISSUED_DATE);
    const expiryDateField = groups.fields.find(ele => ele.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_EXPIRY_DATE);
    if (issuedDateField) {
      issuedDateField.validation.max = new Date();
    }
    if (expiryDateField) {
      expiryDateField.validation.min = new Date();
    }
    const drivingRecordTab = this.driverAttributeData.tabs.find(ele => ele.tabCode === 'other_details');
    const drivingRecordGroups = drivingRecordTab.groups.find(ele => ele.code === 'driver_driving_record');
    const drivingRecordDateField = drivingRecordGroups.fields.find(ele => ele.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DATE);
    if (drivingRecordDateField) {
      drivingRecordDateField.validation.max = new Date();
    }

  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('settings.driver.lbl_driver'),
        routerLink: this.driverId ? '../../list' : '../list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: this.driverId ? this.cs.getLabel('lbl_edit_driver') : this.cs.getLabel('driver.lbl_add_driver'),
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      }
    ];
  }

  onSaveDetails(event) {
    const driverFormData = event;
    this.driverData = {
      forTenantCode: this.confService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };

    const dateIds = [AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DATE_OF_BIRTH];
    for (const [key, value] of Object.entries(driverFormData)) {
      let attributeValue = <any>value;
      if (dateIds.includes(key)) {
        attributeValue = new Date(<string>value).getTime();
      }
      if (attributeValue) {
        const obj = typeof attributeValue;
        if (obj == "object") {
          if (attributeValue?.length > 0) {
            this.driverData?.data?.push({
              attributeCode: key,
              attributeValue,
            });
          }
        } else {
          this.driverData?.data?.push({
            attributeCode: key,
            attributeValue,
          });
        }
      }
    }
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.confService.getForTenantCode(),
      entityCode: this.data.entityCode
    }
    if (this.driverId) {
      this.saveDriverData(this.driverData)
    } else {
      this.createEntityAndUpdateAttributeData(entitiesData);
    }
  }

  createEntityAndUpdateAttributeData(entitiesData) {
    this.driverService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.driverId = res.entityId;
      if (this.driverId) {
        this.driverIdForDrivingRecord = this.driverId;
        this.driverIdForDocument = this.driverId;
      }
      this.cd.detectChanges();
      this.saveDriverData(this.driverData);
    });
  }

  saveDriverData(driverData?) {
    driverData.data = this.cs.mapAttributeIds(driverData.data, this.driverAttributeData.tabs);
    this.driverService.saveAttributeData(driverData.entityCode, this.driverId, driverData).subscribe({
      next: (result) => {
        this.cs.onDisplayMessage(this.data, 'driver', AppSettings.MSG_ACTION.UPDATE,'success');
      },
      error: (error) => {
        this.activeIndex--;
        this.cs.onDisplayMessage(this.data, 'driver', AppSettings.MSG_ACTION.UPDATE,'error');
      }
    });
  }

  saveDriverApiCall() {
    this.driverService.saveAttributeData(this.driverData.entityCode, this.driverId, this.driverData).subscribe((res: any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
      setTimeout(() => {
        this.onNextClick();
      }, 500);
    },
    (error) => {
      this.activeIndex--;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      this.cs.onDisplayMessage(this.driverId, 'driver', AppSettings.MSG_ACTION.UPDATE,'error')
    })
  }

  createDriverEntity() {
    this.driverService.createEntities(this.driverData.entityCode, this.driverData).subscribe({
      next: (res: entityResponse) => {
        this.driverId = res.entityId;
        this.saveDriverApiCall();
        this.cs.onDisplayMessage(this.data, 'driver', AppSettings.MSG_ACTION.ADD, 'success');
      },
      error: (error) => {
        this.cs.onDisplayMessage(this.data, 'driver', AppSettings.MSG_ACTION.ADD, 'error');
      }
    });
  }

  onNextBtnClick() {
    const buttonMapping = {
      0: 'details',
      1: 'otherDetails',
      2: 'driverDocument'
    };
    const buttonId = buttonMapping[this.activeIndex];
    if (buttonId) {
      const btn = document.getElementById(buttonId);
      if (btn) {
        btn.click();
      } else {
        console.error(`Button with ID ${buttonId} not found.`);
      }
    } else {
      console.error(`No button mapping found for activeIndex ${this.activeIndex}.`);
    }
  }

  onPreviousClick() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
    if (this.isLastIndex() === false) {
      this.nextBtnLabel = this.cs.getLabel('lbl_next');
    }
  }

  onNextClick() {
    if (this.activeIndex < this.driverAttributeLength - 1) {
      this.activeIndex++;
    }
    if (this.isLastIndex() === true) {
      this.nextBtnLabel = this.saveDriverBtnLabel;
    }
  }

  getLastIndex(): number {
    return this.driverAttributeLength - 1;
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(['app/drivers/list']);
  }
}
