import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { AppIcons } from '../../shared/app.icons';
import { AppSettings } from '../../shared/app.settings';
import { LoaderService } from '../../shared/components/loader/loader.service';
import { StatusComponent } from '../../shared/components/status/status.component';
import { Country } from '../../shared/models/country';
import { Language } from '../../shared/models/language';
import { Status } from '../../shared/models/status';
import { CommonBindingDataService } from '../../shared/services/common-binding-data.service';
import { ConfigService } from '../../shared/services/config.service';
import { EntityService } from '../../shared/services/entity.service';
import type { AttributeData } from '../../vehicles/models/attribute.models';

@Component({
  selector: 'app-event-details',
  standalone: true,
  imports: [TranslateModule, DatePipe, BreadcrumbModule,
    StatusComponent, ButtonModule, MenuModule],
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss', '../../shared/components/mi-fields/mobile-input/flags.scss'],
  providers: [DatePipe]
})
export class EventDetailsComponent implements OnInit {
  routePath: MenuItem[] = [];
  eventId: string;
  dataForStatus: Array<Status> = [];
  country: Country;
  language: Language;
  activeIndex: any = 0;
  data: AttributeData;
  eventAttributeData: any;
  attributeLabels = {};
  vehicleAttributeLength;
  attributeValues = [];
  imgFileId: any;
  showStatus: boolean = false;
  vehicleEntityId;
  currentRow;
  vehicleDetails: any;
  eventDetails: any;
  entityForStatus: any;
  tab: any;
  details: any;
  eventRelatedSettings: any;
  moduleName: any;
  eventImage: any;
  deleteBtnLabel: any;
  editBtnLabel: any;
  countryCodeWithName = AppSettings.COUNTRY_CODE_WITH_COUNTRY_NAME;
  countryCodeForTransportSupervisorNumber: any;
  emergencyNumber: any;
  userId: any;
  message: string;
  header: string;
  miIcons = AppIcons;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  isDeletedRecord: boolean = false;
  languageCode: string;
  eventState: string;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  center: google.maps.LatLngLiteral;
  map: google.maps.Map;
  address: any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private entityService: EntityService,
    private configService: ConfigService,
    private loaderService: LoaderService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) {

  }

  ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get('id');
    this.getAttributeData();
    this.setCountryAndLanguage();
    this.editBtnLabel = this.cs.getLabel('lbl_edit');
    this.header = this.cs.getLabel('events.message.confirm_delete_header');
    this.message = this.cs.getLabel('events.message.confirm_delete_msg');
    this.routePath = [
      {
        label: "Events",
        routerLink: '/app/events/list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: "Details",
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
    this.entityService.vehicleCurrentRow.subscribe(res => {
      this.currentRow = res;
    });
    this.initMap();
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  async setMarker(markerlocation): Promise<void> {
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: markerlocation,
    });
    this.map.setCenter(markerlocation);
    this.map.setZoom(17);
  }

  async initMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 18.50932924799519, lng: 74.32022387996037 },
      zoom: 8,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapId: AppSettings.MAP_ID,
    });
  }

  getUnit(field) {
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const tempUnit = JSON.parse(field?.imperialUnit);
    this.languageCode = this.language[0].langCode;
    return tempUnit[this.languageCode];
  }

  setStateForEvent(res) {
    const currentTime = new Date().getTime();
    const { eventStartDate, eventEndDate } = this.extractEventDates(res);

    if (eventStartDate && eventEndDate) {
      this.eventState = this.determineEventState(eventStartDate, eventEndDate, currentTime);
    }
  }

  private extractEventDates(res) {
    let eventStartDate = null;
    let eventEndDate = null;

    res?.attributeCodeValueDtoList.forEach((attr) => {
      if (attr.attributeCode === 'event_start_date') {
        eventStartDate = attr.attributeValue;
      }
      if (attr.attributeCode === 'event_end_date') {
        eventEndDate = attr.attributeValue;
      }
    });

    return { eventStartDate, eventEndDate };
  }

  private determineEventState(eventStartDate, eventEndDate, currentTime) {
    if (eventStartDate < currentTime && eventEndDate > currentTime) {
      return AppSettings.EVENT_STATE.ONGOING;
    } else if (eventStartDate > currentTime) {
      return AppSettings.EVENT_STATE.UPCOMING;
    } else {
      return AppSettings.EVENT_STATE.COMPLETED;

    }
  }


  setModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.attributeValues[AppSettings.EVENT_ATTRIBUTES.EVENT_STATUS] === AppSettings.EVENT_STATUS.ACTIVE ? this.cs.getLabel('events.lbl_make_event_inactive') : this.cs.getLabel('events.lbl_make_event_active'),
        icon: AppIcons.OVERDUE + ` wh-16 ${this.attributeValues[AppSettings.EVENT_ATTRIBUTES.EVENT_STATUS] === AppSettings.EVENT_STATUS.ACTIVE ? 'bg-red-500' : 'bg-teal-300'}`,
        command: () => {
          this.showConsentPopup();
        }
      }
    ];
  }

  showConsentPopup() {
    const statusChangeConfirmMsg = this.attributeValues[AppSettings.EVENT_ATTRIBUTES.EVENT_STATUS] === AppSettings.EVENT_STATUS.ACTIVE ? this.cs.getLabel('events.lbl_make_event_inactive_msg') : this.cs.getLabel('events.lbl_make_event_active_msg')
    this.confirmationService.confirm({
      header: this.attributeValues[AppSettings.EVENT_ATTRIBUTES.EVENT_STATUS] === AppSettings.EVENT_STATUS.ACTIVE ? this.cs.getLabel('events.lbl_make_event_inactive') : this.cs.getLabel('events.lbl_make_event_active'),
      message: statusChangeConfirmMsg,
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: this.cs.getLabel('events.lbl_save'),
      rejectLabel: this.cs.getLabel('events.lbl_cancel'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        this.updateEventStatus();
      },
      reject: () => {
      }
    });
  }

  updateEventStatus() {
    const data = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      eventEntityId: this.eventId,
      eventStatus: this.attributeValues[AppSettings.EVENT_ATTRIBUTES.EVENT_STATUS] === AppSettings.EVENT_STATUS.ACTIVE ? AppSettings.EVENT_STATUS.INACTIVE : AppSettings.EVENT_STATUS.ACTIVE
    }
    this.entityService.updateEventStatus(AppSettings.ENTITY_CODE.EVENTS, data).subscribe({
      next: (result) => {
        this.getEventDetails();
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('events.lbl_event_status_updated_successfully') });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  getEventImage() {
    this.entityService.getFile(this.attributeValues[AppSettings.EVENT_ATTRIBUTES.IMAGE_ATTRIBUTE_CODE], AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'event');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        that.eventImage = base64data;
      }
    })
  }

  getAttributeData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.EVENTS,  AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe(res => {
      this.data = res;
      this.attributeLabels = this.cs.getAttributeLabels(this.data);
      this.eventAttributeData = this.cs.getOrganizedAttribute(this.data);
      this.vehicleAttributeLength = this.eventAttributeData.tabs.length;
      this.moduleName = this.data.entityCode;
      this.getEventDetails();
    })
  }

  getEventDetails() {
    this.entityService.getEntity(this.eventId, AppSettings.ENTITY_CODE.EVENTS, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe((result: any) => {
      this.eventDetails = result;
      this.setStateForEvent(result);
      this.attributeValues = this.cs.getOrgAttributeValues(result);
      this.isDeletedRecord = this.eventDetails.actionStatus === AppSettings.ACTION_STATUS.DELETED;
      this.setModuleHeaderSecondaryBtnMenuItems();
      this.address = this.attributeValues[AppSettings.EVENT_ATTRIBUTES.EVENT_ADDRESS];
      const geoPoint = this.attributeValues[AppSettings.EVENT_ATTRIBUTES.EVENT_GEOLOCATION];
      const [lat, lng] = geoPoint?.split(',').map(parseFloat);
      const markerlocation = { lat, lng };
      this.setMarker(markerlocation);
      this.attributeValues['transportation_mode'] = this.attributeValues['transportation_mode'].join(' | ');
      this.imgFileId = this.attributeValues[AppSettings.EVENT_ATTRIBUTES.IMAGE_ATTRIBUTE_CODE] ? this.attributeValues[AppSettings.EVENT_ATTRIBUTES.IMAGE_ATTRIBUTE_CODE][0] : null;
      this.showStatus = true;
      this.tab = this.eventAttributeData.tabs.find(ele => ele.tabCode === 'overview');
      this.details = this.tab.groups.find(ele => ele.code === 'details');
      this.eventRelatedSettings = this.tab.groups.find(ele => ele.code === 'event_related_settings');
      this.parseDates();
      this.parseYesNoValues();
      this.setStatusData();
      this.getEventImage();
      this.getFlagsForMobileNumber();
      this.setMobileNumber();
    })
  }

  setMobileNumber() {
    const tranasportSupervisorNumber = this.attributeValues['transport_supervisor_number'].split('-');
    if (tranasportSupervisorNumber[0] === null || tranasportSupervisorNumber[0] === '') {
      this.attributeValues['transport_supervisor_number'] = tranasportSupervisorNumber[1];
    }

    const emergencyNumber = this.attributeValues['emergency_number'].split('-');
    if (emergencyNumber[0] === null || emergencyNumber[0] === '') {
      this.attributeValues['emergency_number'] = emergencyNumber[1];
    }
  }

  getFlagsForMobileNumber() {
    const findAttributeValue = (attributeCode: string) =>
      this.eventDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === attributeCode)?.attributeValue;

    const findFlag = (flagCode: string) =>
      this.countryCodeWithName.find(ele => ele.countryCode === flagCode)?.code;
    const transportSuperVisorNumber = findAttributeValue('transport_supervisor_number');
    const flag1 = findFlag(transportSuperVisorNumber?.split('-')[0])
    this.countryCodeForTransportSupervisorNumber = flag1 ? flag1 : null;

    const emergencyNumber = findAttributeValue('emergency_number');
    const flag2 = findFlag(emergencyNumber?.split('-')[0])
    this.emergencyNumber = flag2 ? flag2 : null;

  }

  parseDates() {
    const dateAttributes = [
      AppSettings.EVENT_ATTRIBUTES.START_DATE_ATTRIBUTE_CODE,
      AppSettings.EVENT_ATTRIBUTES.END_DATE_ATTRIBUTE_CODE,
      AppSettings.EVENT_ATTRIBUTES.STOP_BOOKING_DATE_PASSENGER_APP_ATTRIBUTE_CODE,
      AppSettings.EVENT_ATTRIBUTES.STOP_BOOKING_DATE_ADMIN_APP_ATTRIBUTE_CODE,
    ];

    dateAttributes.forEach(attributeCode => {
      this.attributeValues[attributeCode] = this.attributeValues[attributeCode]
        ? this.datePipe.transform(this.attributeValues[attributeCode], AppSettings.DATE_FORMAT_DD_MMM_YYYY_FOR_TS_FILE)
        : this.cs.getLabel('lbl_empty');
    });
  }

  parseYesNoValues() {
    this.attributeValues[AppSettings.EVENT_ATTRIBUTES.CAR_SERVICE_AUTO_DISPATCH_ATTRIBUTE_CODE] = this.attributeValues[AppSettings.EVENT_ATTRIBUTES.CAR_SERVICE_AUTO_DISPATCH_ATTRIBUTE_CODE] ? this.cs.getLabel('lbl_yes') : this.cs.getLabel('lbl_no');
    this.attributeValues[AppSettings.EVENT_ATTRIBUTES.SHUTTLE_BOOKING_ATTRIBUTE_CODE] = this.attributeValues[AppSettings.EVENT_ATTRIBUTES.SHUTTLE_BOOKING_ATTRIBUTE_CODE] ? this.cs.getLabel('lbl_yes') : this.cs.getLabel('lbl_no');
  }

  deleteEvent() {
    this.confirmationService.confirm({
      header: this.header,
      message: this.message,
      acceptIcon: null,
      rejectIcon: null,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      acceptLabel: this.cs.getLabel('lbl_delete'),
      rejectLabel: this.cs.getLabel('cancel'),
      accept: ($event) => {
        this.deleteEventApiCall();
      },
      reject: () => {
      }
    });
  }

  deleteEventApiCall() {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.EVENTS,
      entityIds: [this.eventId],
      countryCode: this.country[0].countryCode
    };
    this.entityService.deleteEntityActionStatus(requestBody).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('events.lbl_event_deleted_successfully') });
        this.router.navigate(['/app/events']);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  setStatusData() {
    const findAttributeValue = (attributeCode: string) =>
      this.eventDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === attributeCode)?.attributeValue;

    const getAttributeValueOrEmpty = (attributeCode: string) =>
      findAttributeValue(attributeCode) || this.cs.getLabel('lbl_empty');

    this.entityForStatus = {
      entityName: `${findAttributeValue('event_name')}`
    };

    this.dataForStatus = [
      { label: 'Short Code', text: getAttributeValueOrEmpty('event_short_code') },
    ];
  }

  edit() {
    this.router.navigate(['app/events/edit/' + this.eventId,]);
  }

}
