import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { forkJoin } from 'rxjs';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { Column } from '../../../shared/components/mi-table/models/table';
import { ModuleHeaderComponent } from '../../../shared/components/module-header/module-header.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { EntityCount, EntityCountsResponse, EntityList } from '../../../vehicles/models/entity.models';
import type { ListResponse, Values } from '../../../vehicles/models/listResponse.models';

@Component({
  selector: 'app-incidents-complaints-list',
  templateUrl: './incidents-complaints-list.component.html',
  styleUrl: './incidents-complaints-list.component.scss',
  standalone: true,
  imports: [BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, MITableComponent]
})
export class IncidentsComplaintsListComponent implements OnInit {
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLElement>;
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];
  @Input() moduleName: string;
  filterList;
  value: number = 50;
  btnLabel: string;
  items: MenuItem[] | undefined;
  isShowMenu: boolean = true;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  totalRecords: number = 0;
  tableData: any = [];
  buttons: any[] = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "",
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  home: MenuItem = {};
  isModal: boolean = false;
  selectedColumns!: Column[];
  deleteVehicleMsg: string;
  deleteVehicleHeaderMsg: string;
  showMenu: boolean = false;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  bulkDeleteLabel: string = this.cs.getLabel('incidents_complaints.delete');
  bulkUpdateLabel: string = this.cs.getLabel('incidents_complaints.lbl_update');
  filterFieldsArray: any[];
  isReady: boolean = false;
  attributeData: any;
  data;
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  currentRow;
  visiblePrintLabel: boolean = false;
  bulkPrintLabel = 'Print';
  showBulkUpdateDialog: boolean = false;
  visible: boolean = false;
  attributeIdOfIncidentsComplaintStatus: any;
  bulkUpdateHeader: any;
  deleteIncidentsHeaderMsg: string;
  reviewHeader: any;
  btnUpdate: any;
  entityNameToDisplay: any;
  miIcons = AppIcons;

  constructor(private incidentsComplaintService: EntityService,
    private cs: CommonBindingDataService, private confirmationService: ConfirmationService,
    private router: Router, private messageService: MessageService, private actRoute: ActivatedRoute,
    private configService: ConfigService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.setUpRoutePath();
    this.initializeOtherVariablesAndAPI();
    this.setUpRowItems();
    this.setupBulkSelectionsItems();
    this.setupModuleHeaderSecondaryBtnMenuItems();
  }

  initializeOtherVariablesAndAPI() {
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.btnLabel = this.cs.getLabel('incidents_complaints.add_new_lbl');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.bulkUpdateHeader = this.cs.getLabel('incidents_complaints.lbl_bulk_update_header');
    this.entityNameToDisplay = this.cs.getLabel('incidents_complaints.lbl_entity_name_display');
    this.deleteVehicleMsg = this.cs.getLabel('incidents_complaints.message.confirm_delete_msg');
    this.deleteIncidentsHeaderMsg = this.cs.getLabel('incidents_complaints.message.confirm_delete_header');
    this.reviewHeader = this.cs.getLabel('incidents_complaints.lbl_review_incident');
    this.btnUpdate = this.cs.getLabel('incidents_complaints.btn_bulk_update');
    this.entityData.forTenantCode = this.configService.getForTenantCode()
    this.getAttributeData();

    this.getData();
  }

  private setUpRoutePath() {
    this.routePath = [
      { label: this.cs.getLabel('incidents_complaints.lbl_route_path'), routerLink: 'incidents-complaints/list' },
    ];
  }

  private setUpRowItems() {
    this.rowItems = [
      {
        label: this.cs.getLabel('incidents_complaints.lbl_update'),
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        }
      }, {
        label: this.cs.getLabel('incidents_complaints.delete'),
        icon: AppIcons.BASIC_TRAY + " wh-16",
        iconClass: 'bulk-update-icon',
        command: (e) => {
          const event = _.cloneDeep(e);
          this.delete(event, this.currentRow, 'row');
        }
      }
    ];
  }

  private setupBulkSelectionsItems() {
    this.bulkSelectionsItems = [
      {
        label: this.bulkUpdateLabel,
        icon: AppIcons.EDIT_2 + " wh-18",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: () => {
          this.visible = true;
          this.tableRef.showBulkRowDialog();
        },
      },
    ];
  }

  private setupModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.cs.getLabel('incidents_complaints.import_incidents_complaint'),
        icon: AppIcons.UPLOAD + " wh-16",
        command: () => {
          const el: HTMLElement = this.uploader.nativeElement;
          el.click();
        }
      },
      {
        label: this.cs.getLabel('vehicle.download_important_template'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.downloadImportTemplate();
        }
      },
      {
        label: this.cs.getLabel('vehicle.download_important_guide'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.downloadImportGuide();
        }
      },
      {
        separator: true
      },
      {
        label: this.cs.getLabel('incidents_complaints.export_xlsx_csv'),
        icon: AppIcons.EXPORT + " wh-16",
      },
    ];
  }

  private downloadImportTemplate() {
    this.incidentsComplaintService.downloadImportTemplate(AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT).subscribe(
      (result: any) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(result);
        a.download = 'Import Template.xlsx';
        a.click();
      },
      err => {
        console.log(err);
      });
  }

  private downloadImportGuide() {
    this.incidentsComplaintService.downloadImportGuide(AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT).subscribe(result => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(result);
      a.download = 'Import Guide.xlsx';
      a.click();
    },
      err => {
        console.log(err);
      });
  }

  public onChange(event) {
    for (const file of event.target.files) {
      if (file) {
        if (file.size > AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('err_file_size_exceed_msg') });
        } else {
          const formData = new FormData();
          formData.append("file", file);
          formData.append('forTenantCode', this.configService.getForTenantCode());
          formData.append('entityCode', AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT);
          formData.append('viewCode', AppSettings.VIEW_CODE.EXCEL_IMPORT_VIEW);
          this.cs.importEntity(formData, AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT).subscribe(res => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_file_uploaded_successfully') });
          })
        }
      }
    }
  }

  getAttributeData() {
    this.incidentsComplaintService.getAttributeDefinition(AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT,  AppSettings.VIEW_CODE.BULK_UPDATE_VIEW).subscribe(res => {
      if (res) {
        this.attributeData = res;
        this.isLoading = false;
        this.updateFilterFieldsArray(res.tabs[0]?.groups[0]?.fields);
      }
    });
  }

  private updateFilterFieldsArray(fields: any[]) {
    if (!fields) return;

    this.filterFieldsArray = fields.map(field => {
      switch (field.attributeCode) {
        case 'raised_by_type':
          return { ...field, attributeValue: 'Type', isDisable: false };
        case 'incidents_and_complaint_status':
          return { ...field, attributeValue: 'Status', isDisable: false };
        default:
          return field;
      }
    });
  }


  onStatusChange(currentRow) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      incidentAndComplaintEntityId: currentRow.id,
      incidentAndComplaintStatus: currentRow.incidents_and_complaint_status,
    };
    const entityCode = AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT;
    this.incidentsComplaintService.updateIncidentStatus(entityCode, requestBody).subscribe(result => {
      setTimeout(() => {
        this.searchEntity();
      }, 500)
    });
  }

  setCurrentRowData(event) {
    this.currentRow = event;
    this.rowItems[0].disabled = this.currentRow.deleted;
    this.rowItems[1].disabled = this.currentRow.deleted;
    this.cd.detectChanges();
  }

  update() {
    this.onUpdateIncidentComplaint(this.currentRow.id);
  }

  delete(event: Event, rowData: any, deleteType: string) {
    this.confirmationService.confirm({
      header: this.deleteIncidentsHeaderMsg,
      message: this.deleteVehicleMsg,
      acceptIcon: '',
      rejectIcon: '',
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        if (deleteType === 'row') {
          this.onIncidentComplaintDelete(this.currentRow.id);
        } else {
          this.onBulkDataDeleteEvent(this.currentRow.map(ele => {
            return ele.id
          }));
        }

      },
      reject: () => {
      }
    });
  }

  getTableView() {
    this.incidentsComplaintService.getAttributeDefinition(AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT,  AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW).subscribe(res => {
      if (res) {
        this.isLoading = false;
        this.data = res;
        this.attributeIdOfIncidentsComplaintStatus = res.tabs[0].groups[0].fields.find(element => element.attributeCode === 'incidents_and_complaint_status')?.attributeId;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);

        this.allColumns = this.cs.getColumns(this.data);
        this.searchEntity();

        this.allColumns?.forEach((key, index) => {
          this.allColumns[index] = key;
          this.allColumns[index].field = key.attributeCode;
          this.allColumns[index].header = this.cs.getLabelValue(
            "incidents_and_complaint" + ".fields." + key.attributeCode + ".label",
            this.attributeLabels,
            key.attributeCode
          );
        });

        this.filterAvailableColumns = _.clone(this.allColumns);
      }
    });
  }

  getFilterView() {
    this.incidentsComplaintService.getAttributeDefinition(AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT,  AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
        this.filterAttributeLabels = this.cs.getAttributeLabels(filterResponse);
        this.filterList = this.cs.getColumns(filterResponse);
        this.filterList = _.sortBy(this.filterList, 'attributeIndex');
      }
    })
  }

  getData() {

    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
      getEntityCount: this.getEntityCount()
    })
  }


  searchEntity() {
    this.tableData = [];
    this.entityData.countryCode = this.country[0].countryCode;
    this.incidentsComplaintService.searchEntity(AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = this.cs.getTableData(res);
    })
  }

  onAddIncidentComplaint() {
    this.router.navigate(['app/incidents-complaints/add',]);
  }

  getLabel(params) {
    return params + 'hi';
  }

  onRowClicked(e: any) {
    alert('Row click : ' + JSON.stringify(e));
  }

  onActionBtnClicked(e: any) {
    alert('Action btn click : ' + JSON.stringify(e));
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  onConfirmDelete(event) {

  }


  onIncidentComplaintDelete(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT,
      entityIds: [
        event
      ]
    };
    this.incidentsComplaintService.deleteEntityActionStatus(requestBody).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: res.message });
        setTimeout(() => {
          this.searchEntity();
          this.getEntityCount();
        }, 300);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].messageCode) });
      }
    })
  }

  onUpdateIncidentComplaint(incidentsComplaintId) {
    this.router.navigate(['app/incidents-complaints/add/' + incidentsComplaintId,]);
  }

  onRowSelect(event) {
    if (event.type === 'row') {
      this.router.navigate(['app/incidents-complaints/' + event.data.id,]);
    }
  }

  onFilterValueChange(event) {
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(event)) {
      const attributeValue = value;
      if (key === AppSettings.DATE_ATTRIBUTE_IDS.INCIDENTS_DATE_TIME) {
        const date = new Date(value.toString());
        date.setSeconds(0);
        const timestamp = date.getTime();
        if (attributeValue) {
          this.entityData.filters.push({
            attributeCode: key,
            attributeValue: timestamp
          });
        }

      } else {
        if (attributeValue) {
          this.entityData.filters.push({
            attributeCode: key,
            attributeValue
          });
        }
      }

    }
    this.entityData.offset = event.first;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
    this.searchEntity();
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  getEntityCount() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityCountPayload = <EntityCount>{
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT,
      countryCode: country[0].countryCode,
    };

    this.incidentsComplaintService.getEntityCount(AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT, entityCountPayload).subscribe((res: EntityCountsResponse) => {
      this.entityCount = res;
    })
  }

  tabViewChange(event) {
    switch (event.index) {
      case 0:
        this.entityData.actionStatus = '';
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.entityData.filters = [];
        this.searchEntity();
        break;

      default:
        break;
    }
  }

  onBulkDataUpdateEvent(event) {
    setTimeout(() => {
      this.searchEntity();
    }, 500);
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT,
      entityIds: event
    };
    this.entityData.actionStatus = AppSettings.VEHICLE_TAB_LIST.DELETE
    this.incidentsComplaintService.deleteEntityActionStatus(requestBody).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: res.message });
        setTimeout(() => {
          this.searchEntity();
          this.getEntityCount();
        }, 300);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].messageCode) });
      }
    })
  }

}
