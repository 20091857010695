import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { AppIcons } from '../../../../shared/app.icons';
import { AppSettings } from '../../../../shared/app.settings';
import { ConfigService } from '../../../../shared/services/config.service';

@Component({
  selector: 'app-added-passenger-input',
  standalone: true,
  imports: [DropdownModule, FormsModule, TranslateModule, NgClass],
  templateUrl: './added-passenger-input.component.html',
  styleUrl: './added-passenger-input.component.scss'
})
export class AddedPassengerInputComponent {
  @Input() addedPassengerList;
  @Input() selectAddedPassenger;
  @Output() onAddedPassenger: EventEmitter<any> = new EventEmitter();
  @Output() onCloseRemove: EventEmitter<any> = new EventEmitter();

  @Input() isPassengerDetails = false;


  miIcons = AppIcons;

  constructor(private configService: ConfigService) {

  }

  onSelectAddedPassenger() {
    this.onAddedPassenger.emit(this.selectAddedPassenger);
  }

  onRemove(event) {
    this.onCloseRemove.emit(event);
  }




}
