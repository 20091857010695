<div class="w-full formgrid grid pt-2 " cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">

    @for (stop of stops; track stop;let s = $index) {


    <div class="grid  pt-0">
        <div class="col-12 p-0 md:col-12 pt-0 pb-2 " cdkDrag>
            <div class="flex align-items-center justify-content-between">
                <div class="col-11 p-0 flex align-items-center justify-content-left ellipsis stops-text relative">
                    <div class="flex align-items-center relative">
                        <!-- <i class="route inline-block relative mr-1 text-xs"></i> -->
                        <i class="{{miIcons.BOOKING_STOP_POINT}} inline-block mr-2 text-xs">{{ getLetter(s) }}</i>
                        <!-- <i class="{{miIcons.GRID_VERTICAL}} wh-24 inline-block mr-10 "></i> -->
                    </div>
                    <div class="flex-grow-1 align-items-center relative">
                         <label translate class="lbl-geo-pickup stop-locations"> bookings.add_stop</label>
                            <app-mi-fields [field]="stop" [attributeLabels]="attributeLabels"
                                [moduleName]="moduleName"></app-mi-fields>
                    </div>
    
                    <!-- @if (stops.length > 1) {
                    <div class="flex align-items-center remove cursor-pointer ml-1" (click)="removeStop(s, stop)">
                        <i class="{{miIcons.OFF_OUTLINE_CLOSE}} wh-16 inline-block surface-500 cursor-pointer"></i>
                    </div>
                    } -->
                </div>
                <div class="col-1 p-0 flex justify-content-end align-items-center">
                    <div class="add-stop-icon flex align-items-center border-round p-2 border-1 cursor-pointer" (click)="removeStop(s, stop)">
                      <i class="{{miIcons.CLOSE_BIG}} wh-17 inline-block bg-primary"></i>
                    </div>
                  </div>
            </div>
            <div class="col-6 py-1">
                <app-mi-error [field]="stop" [attributeLabels]="attributeLabels"
                    [moduleName]="moduleName"></app-mi-error>
            </div>
        </div>
    </div>
    }
    @if (stops.length > 0) {
        <div class=" col-12 align-content-end md:col-12 add-stops flex align-items-center ml-4"
            (click)="addMoreStops($event)" [class.disabled]="stops.length >= 2">
            <i class="{{miIcons.BOOKING_EDIT_PLUS_CIRCLE}} wh-16 inline-block mr-1 bg-primary cursor-pointer"></i>
            <label class="slot cursor-pointer" translate>bookings.add_stops</label>
        </div>
    }

</div>