<p-breadcrumb [model]="routePath" (click)="onCancel()"></p-breadcrumb>
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white border-0 default-text-light-black-color cursor-pointer border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button class="flex justify-content-center cursor-pointer height-44" [label]="btnLabel" (click)="onSaveBtnClick()"></p-button>
</div>
<p-tabView [(activeIndex)]="activeIndex">
    @for(tab of locationAttributeData?.tabs; track tab?.tabCode) {
        <p-tabPanel >
            <ng-template pTemplate="header">
                <div class="tab-icons">
                <i class="mi-lg {{miIcons.DETAILS}}" ></i>
                <span>{{ cs.getLabelValue('location.tabs.'+tab.tabCode+'.label',attributeLabels,tab.tabCode) }}</span>
                </div>
            </ng-template>
            <div class="" >
                <div>
                    <app-mi-form [attributeLabels]="attributeLabels" [attributeValues]="attributeValues" [moduleName]="moduleName" [groups]="tab.groups"
                        (saveData)="onSaveLocation($event)" (previousClick)="onPreviousClick()" 
                        [submitBtnText]="btnLabel" [previousBtnText]="previousBtnLabel" [activeIndex]="activeIndex" [saveBtnId]="'details'" (onClickCancel)="onCancel()"></app-mi-form>
                </div>
            </div>
        </p-tabPanel>
    }
</p-tabView>
