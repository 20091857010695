import { Injectable } from '@angular/core';
import { AppSettings } from '../../shared/app.settings';
import { ConfigService } from '../../shared/services/config.service';
import { RestApiService } from '../../shared/services/rest-api.service';
import type { AssignVehicleToBookingPayload } from '../models/booking.models';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private restApiService: RestApiService, private confService: ConfigService) { }

  getBookingStatus() {
    return this.restApiService.get(' booking status', `${this.confService.getLoggedInTenantCode()}/api/v1/booking-statuses?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');

  }

  updateStatus(data) {
    return this.restApiService.post('Update Booking Status', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/custom/update-booking-status`, data, 'page-center');

  }

  assignVehicleToBooking(data: AssignVehicleToBookingPayload) {
    return this.restApiService.post('Assign Vehicle to booking', `${this.confService.getForTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/custom/booking-assign-vehicle`, data, 'page-center');
  }

  releaseDriverFromBooking(data) {
    return this.restApiService.post('RELEASE DRIVER FROM BOOKING', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/custom/booking-release-driver`, data, 'page-center');
  }

}
