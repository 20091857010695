import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AutoFocusDirective } from '../../../../../../shared/directives/auto-focus.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { CommonBindingDataService } from '../../../../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../../../../shared/services/config.service';
import { MessageService } from 'primeng/api';
import { AppSettings } from '../../../../../../shared/app.settings';
import { CheckboxModule } from 'primeng/checkbox';
import { SettingsService } from 'app/modules/settings/settings.service';

@Component({
  selector: 'app-settings-add-booking-transportation-mode',
  standalone: true,
  imports: [TranslateModule, ButtonModule, DialogModule, ToastModule, InputTextModule, ReactiveFormsModule, FormsModule, CheckboxModule],
  templateUrl: './settings-add-booking-transportation-mode.component.html',
  styleUrl: './settings-add-booking-transportation-mode.component.scss',
  providers: [AutoFocusDirective]
})
export class SettingsAddBookingTransportationModeComponent implements OnInit {
  @ViewChild('nameInput', { static: false }) nameInput!: ElementRef;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  settingsForm: FormGroup;
  @Input() isVisible;
  @Input() rowData;
  dialogHeader: string;

  constructor(private cs: CommonBindingDataService, private formBuilder: FormBuilder,
    private configService: ConfigService, private settingsService: SettingsService,
    private messageService: MessageService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.formValidation();
    this.updateDialogHeader();
    if (this.rowData) {
      this.isEditModeForm();
    }
  }

  formValidation() {
    this.settingsForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      markAsDefault: ['']
    });
  }

  updateDialogHeader() {
    this.dialogHeader = this.rowData ? this.cs.getLabel('settings.booking.lbl_edit_transportation_mode') : this.cs.getLabel('settings.booking.lbl_add_transportation_mode');
  }

  isEditModeForm() {
    this.patchFormValues(this.rowData);
  }

  patchFormValues(formData) {
    this.settingsForm.patchValue({
      name: formData.labelValue,
      markAsDefault: formData?.defaultPreset
    });

    if (!this.rowData.customValue) {
      this.settingsForm.get('name')?.disable();
    } else {
      this.settingsForm.get('name')?.enable();
    }
  }

  onHide() {
    this.isVisible = false;
    this.settingsForm.reset();
    this.cd.detectChanges();
    this.onToggleOverLay.emit(this.isVisible);
  }

  onDialogShow() {
    setTimeout(() => {
      if (this.nameInput) {
        this.nameInput?.nativeElement?.focus();
      } else {
        console.error('nameInput is undefined');
      }
    }, 0);
  }

  onAddSettings() {
    if (this.settingsForm.valid) {
      let reqBody;
      reqBody = {
        forTenantCode: this.configService.getForTenantCode(),
        attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.TRANSPORTATION_MODE,
        presetValue: this.settingsForm.value.name,
        presetKey: this.settingsForm.value.name,
        defaultPreset: this.settingsForm.value.markAsDefault,
        parentPresetKey: '',
      }

      if (!this.rowData) {
        reqBody.presetValue = this.settingsForm.value.name;
        reqBody.presetKey = this.settingsForm.value.name;
      } else {
        reqBody.presetValue = this.rowData.customValue ? this.settingsForm.value.name : this.rowData.labelValue;
        reqBody.presetKey = this.rowData.customValue ? this.settingsForm.value.name : this.rowData.labelKey;
      }

      const addEditAPI = this.rowData ? this.settingsService.editAttributeSettings(AppSettings.ENTITY_CODE.BOOKING, this.rowData.attributePresetLabelId, reqBody) : this.settingsService.addAttributeSettings(AppSettings.ENTITY_CODE.BOOKING, reqBody)
      addEditAPI.subscribe((res: any) => {
        const message = this.rowData
          ? this.cs.getLabel('settings.booking.lbl_transportation_mode_updated_successfully')
          : this.cs.getLabel('settings.booking.lbl_transportation_mode_added_successfully');

        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: message });
        this.onHide();
      },
        error => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_invalid_data') });
        });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('settings.lbl_please_fill_the_form') });
    }
  }

}
