<p-breadcrumb [model]="routePath"></p-breadcrumb>
<app-module-header [label]="btnLabel" (onBtnClick)="onAddDriver()" [moduleHeaderSecondaryBtnMenuItems]="moduleHeaderSecondaryBtnMenuItems"></app-module-header>
  <p-tabView [(activeIndex)]="activeIndex" (onChange)="tabViewChange($event)">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2 relative"></i>
        <span translate>lbl_all</span>&nbsp;<span class="iconNumber text-color-secondary">({{entityCount?.counts?.ALL}})</span>
      </ng-template>
      <ng-template pTemplate="body">
          @if (data) {
            <app-mi-table
            [collectionOfColumns]="allColumns"
            [attributeLabels]="filterAttributeLabels"
            [data]="tableData"
            entityName="driver"
            [isLoading]="isLoading"
            (onPageChange)="onPageChange($event)"
            (itemPerPageChange)="itemPerPageChange($event)"
            [totalRecords]="totalRecords"
            [itemsPerPage]="itemsPerPage"
            [message]="deleteDriverMsg"
            [header]="deleteDriverHeaderMsg"
            (onDelete)="onDriverDelete($event)"
            (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
            (onRowSelection)="onRowSelect($event)"
            [filterList]="filterList"
            (onValueChange)="onFilterValueChange($event)"
            [filterFields]="filterFieldsArray"
            [label]="btnLabel"
            (onBtnClick)="onAddDriver()"
            [bulkUpdateLabel]="bulkUpdateLabel"
            [bulkDeleteLabel]="bulkDeleteLabel"
            [attributeData]="attributeData"
            [bulkUpdateHeader]="bulkUpdateHeader"
            [entityNameToDisplay]="entityNameToDisplay"
            (onBulkDataUpdateEvent)="onBulkDataUpdateEvent($event)"
            (onSearchValueChange)="onSearchValueChanges($event)"
            (onUpdate)="onUpdateDriver($event)"
            [rowItems]="activeRowItems"
            (setCurrentRowData)="setCurrentRowData($event)"
            [bulkSelectionsItems] = "bulkSelectionsItems"
            [(showBulkUpdateDialog)]="showBulkUpdateDialog"
            [(visible)]="visible"
            (onToggleOverLay)="visible = false"
            (onChangeStatus)="onStatusChange($event)"
            (onChangeAssignDriverGroup)="openDriverStatusDialog($event)"
            (onChangeDefaultVehicle)="onChangeDefaultVehicle($event)"
            (onChangeSelectCheckInLocation)="onChangeSelectCheckInLocation($event)"
            (onChangeOffDutyReason)="onChangeOffDutyReason($event)"
            (onChangeOnDutyReason)="onChangeOnDutyReason($event)"
            [entityNameForBulkUpdate]="entityNameForBulkUpdate"
            [reviewHeader]="reviewHeader"
            [btnUpdate]="updateBtnLbl"
            >
          </app-mi-table>
          }
      </ng-template>
    </p-tabPanel>
  
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="{{miIcons.CIRCLE_CHECKED}} inline-block wh-16 mr-2 relative"></i>
        <span translate>lbl_active</span>&nbsp;<span class="iconNumber text-color-secondary">({{entityCount?.counts?.ACTIVE}})</span>
      </ng-template>
      <ng-template pTemplate="body">
        @if (listColumns) {
          <app-mi-table
          [collectionOfColumns]="allColumns"
          [attributeLabels]="filterAttributeLabels"
          [data]="tableData"
          entityName="driver"
          [isLoading]="isLoading"
          (onPageChange)="onPageChange($event)"
          (itemPerPageChange)="itemPerPageChange($event)"
          [totalRecords]="totalRecords"
          [itemsPerPage]="itemsPerPage"
          [message]="deleteDriverMsg"
          [header]="deleteDriverHeaderMsg"
          (onDelete)="onDriverDelete($event)"
          (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
          (onRowSelection)="onRowSelect($event)"
          [filterList]="filterList"
          (onValueChange)="onFilterValueChange($event)"
          [filterFields]="filterFieldsArray"
          [label]="btnLabel"
          (onBtnClick)="onAddDriver()"
          [bulkUpdateLabel]="bulkUpdateLabel"
          [bulkDeleteLabel]="bulkDeleteLabel"
          [isBulkPrint]="isBulkPrint"
          [attributeData]="attributeData"
          [bulkUpdateHeader]="bulkUpdateHeader"
          [entityNameToDisplay]="entityNameToDisplay"
          (onBulkDataUpdateEvent)="onBulkDataUpdateEvent($event)"
          (onSearchValueChange)="onSearchValueChanges($event)"
          (onUpdate)="onUpdateDriver($event)"
          [rowItems]="activeRowItems"
          (setCurrentRowData)="setCurrentRowData($event)"
          [bulkSelectionsItems] = "bulkSelectionsItems"
          [(showBulkUpdateDialog)]="showBulkUpdateDialog"
          [(visible)]="visible"
          (onToggleOverLay)="visible = false"
          (onChangeStatus)="onStatusChange($event)"
          (onChangeAssignDriverGroup)="openDriverStatusDialog($event)"
          (onChangeDefaultVehicle)="onChangeDefaultVehicle($event)"
          (onChangeSelectCheckInLocation)="onChangeSelectCheckInLocation($event)"
          (onChangeOffDutyReason)="onChangeOffDutyReason($event)"
          (onChangeOnDutyReason)="onChangeOnDutyReason($event)"
            >
          </app-mi-table>
        }
      </ng-template>
    </p-tabPanel>
  
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="pi pi-times-circle mr-2"></i>
        <span translate>lbl_inactive</span>&nbsp;<span class="iconNumber text-color-secondary">({{entityCount?.counts?.INACTIVE}})</span>
      </ng-template>
      <ng-template pTemplate="body">
        <!-- @if (listColumns) { -->
          <app-mi-table
          [collectionOfColumns]="allColumns"
          [attributeLabels]="filterAttributeLabels"
          [data]="tableData"
          entityName="driver"
          [isLoading]="isLoading"
          (onPageChange)="onPageChange($event)"
          (itemPerPageChange)="itemPerPageChange($event)"
          [totalRecords]="totalRecords"
          [itemsPerPage]="itemsPerPage"
          [message]="deleteDriverMsg"
          [header]="deleteDriverHeaderMsg"
          (onDelete)="onDriverDelete($event)"
          (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
          (onRowSelection)="onRowSelect($event)"
          [filterList]="filterList"
          (onValueChange)="onFilterValueChange($event)"
          [filterFields]="filterFieldsArray"
          [label]="btnLabel"
          (onBtnClick)="onAddDriver()"
          [bulkUpdateLabel]="bulkUpdateLabel"
          [bulkDeleteLabel]="bulkDeleteLabel"
          [isBulkPrint]="isBulkPrint"
          [attributeData]="attributeData"
          [bulkUpdateHeader]="bulkUpdateHeader"
          [entityNameToDisplay]="entityNameToDisplay"
          (onBulkDataUpdateEvent)="onBulkDataUpdateEvent($event)"
          (onSearchValueChange)="onSearchValueChanges($event)"
          (onUpdate)="onUpdateDriver($event)"
          [rowItems]="activeRowItems"
          (setCurrentRowData)="setCurrentRowData($event)"
          [bulkSelectionsItems] = "bulkSelectionsItems"
          [(showBulkUpdateDialog)]="showBulkUpdateDialog"
          [(visible)]="visible"
          (onToggleOverLay)="visible = false"
          (onChangeStatus)="onStatusChange($event)"
          (onChangeAssignDriverGroup)="openDriverStatusDialog($event)"
          (onChangeDefaultVehicle)="onChangeDefaultVehicle($event)"
          (onChangeSelectCheckInLocation)="onChangeSelectCheckInLocation($event)"
          (onChangeOffDutyReason)="onChangeOffDutyReason($event)"
          (onChangeOnDutyReason)="onChangeOnDutyReason($event)"
            >
          </app-mi-table>
        <!-- } -->
      </ng-template>
    </p-tabPanel>
  
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="{{miIcons.BASIC_TRAY}} inline-block wh-14 mr-2 relative"></i>
        <span translate>lbl_deleted</span>&nbsp;<span class="iconNumber text-color-secondary">({{entityCount?.counts?.DELETED}})</span>
      </ng-template>
      <ng-template pTemplate="body">
        <!-- @if (listColumns) { -->
          <app-mi-table
            [collectionOfColumns]="allColumns"
            [attributeLabels]="attributeLabels"
            [data]="tableData"
            entityName="driver"
            [isLoading]="isLoading"
            (onPageChange)="onPageChange($event)"
            (itemPerPageChange)="itemPerPageChange($event)"
            [itemsPerPage]="itemsPerPage"
            (onDeleteRowConfirmation)="onConfirmDelete($event)"
            [message]="deleteDriverMsg"
            [header]="deleteDriverHeaderMsg"
            [totalRecords]="totalRecords"
            (onDelete)="onDriverDelete($event)"
            (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
            (onRowSelection)="onRowSelect($event)"
            [filterList]="filterList"
            [filterFields]="filterFieldsArray"
            [label]="btnLabel"
            (onBtnClick)="onAddDriver()"
            [bulkUpdateLabel]="bulkUpdateLabel"
            [bulkUpdateHeader]="bulkUpdateHeader"
            [entityNameToDisplay]="entityNameToDisplay"
            (onValueChange)="onFilterValueChange($event)"
            [attributeData]="attributeData"
            (onBulkDataUpdateEvent)="onBulkDataUpdateEvent($event)"
            (onSearchValueChange)="onSearchValueChanges($event)"
            >
          </app-mi-table>
        <!-- } -->
      </ng-template>
    </p-tabPanel>
  </p-tabView>

  <app-modify-default-entity (onToggleOverLay)="loadSearchEntity()" [visibleModify]="isVisibleModify" [rowData]="rowData"></app-modify-default-entity>
  <app-assign-vehicle id="assign-vehicle" (onToggleOverLay)="loadSearchEntity()" [visibleAssignVehicle]="isVisibleAssignVehicle" [rowData]="rowData" [vehicleBodyType]="vehicleBodyType"></app-assign-vehicle>
  <app-driver-all-status-dialog id="assign-group" (onToggleOverLay)="loadSearchEntity()" [visibleDriverStatus]="isVisibleDriverStatus" [rowData]="rowData" [header]="driverStatusHeader" [tableDriverGroupData]="driverGroupDataTemp"></app-driver-all-status-dialog>
  <app-driver-check-in-location id="check-in-location" (onToggleOverLay)="loadSearchEntity()" [visibleDriverStatus]="isVisibleDriverCheckInLocation" [rowData]="rowData" [header]="driverStatusHeader" [tableCheckInData]="checkInLocationDataTemp"></app-driver-check-in-location>
  <input hidden type="file" id="upload" (change)="onChangeImport($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" #uploader>
<p-toast></p-toast>