import { JsonPipe, NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import type { SearchEntityPayload } from 'app/modules/shared/models/entity.models';
import { DateFormatPipe } from 'app/modules/shared/pipes/dateformat.pipe';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppIcons } from '../../../../shared/app.icons';
import { AppSettings } from '../../../../shared/app.settings';
import { MiFieldsComponent } from '../../../../shared/components/mi-fields/mi-fields.component';
import { Country } from '../../../../shared/models/country';
import { Language } from '../../../../shared/models/language';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../../shared/services/config.service';
import { EntityService } from '../../../../shared/services/entity.service';
import { MiValidationsService } from '../../../../shared/services/mi-validations.service';
import type { entityResponse } from '../../../../vehicles/models/attribute.models';
import type { ListResponse } from '../../../../vehicles/models/listResponse.models';
import type { AttributeLabel, GetAttributes, Passenger } from '../../../models/booking.models';
import { AddedPassengerInputComponent } from '../added-passenger-input/added-passenger-input.component';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-select-passengers',
  standalone: true,
  imports: [AutoCompleteModule, DateFormatPipe, ButtonModule, NgClass, TranslateModule, DropdownModule, MiFieldsComponent, ToastModule, JsonPipe, FormsModule, ReactiveFormsModule, AddedPassengerInputComponent,ImageModule],
  templateUrl: './select-passengers.component.html',
  styleUrl: './select-passengers.component.scss'
})
export class SelectPassengersComponent implements OnInit {
  attributeValues: Passenger;
  @Input() bookingPass: GetAttributes;
  @Input() bookingBookingPass: GetAttributes;
  @Input() passengerView: any;
  @Input() addedPassengerList = [];
  @Input() filteredLabels: any;
  @Output() passengerList: EventEmitter<any> = new EventEmitter();
  defaultImage = '../../../../../assets/images/mi-theme/light/basic/default_user.svg';
  groups: any;
  attributeLabels: AttributeLabel[];
  moduleName = 'booking_pass';
  DETAILS_GROUP_CODE = 'details';
  country: Country;
  language: Language;
  passengerAttributeList;
  miIcons = AppIcons;
  items: any[] | undefined;
  selectedPassenger = [];
  currentSelectedCode: string;
  bookingPassengerEntityIds = [];
  formGroup: FormGroup;
  miFormGroup: FormGroup;
  formGroupFields = [];
  public fields = [];
  excludeGroupLabel = ['details'];
  excludeAttributeInLoop = ['date_of_birth', 'pass_full_name', 'pass_type', 'note', 'emergency_contact_name', 'emergency_contact_number', 'passenger_profile_image']
  excludeEditAction = ['knockout_date', 'passenger_user_id', 'mobile_number']
  selectAddedPassenger: any;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  tempPassenger: any;
  dateFormat: any = AppSettings.DATE_FORMAT_DD_MMM_YYYY;

  suggestions: any[] | undefined;
  entityData: SearchEntityPayload = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "",
    actionStatus: ''
  };
  tableData = [];
  passenger;
  passengerAttributeData;
  nextBtnLabel = "Save"
  passengerIcon = {
    1: AppIcons.BOOKING_PASSENGER,
    3: AppIcons.BOOKING_WATCHLIST,
    4: AppIcons.ID_CARD,
    5: AppIcons.PHONE,
    6: AppIcons.MAIL,
    7: AppIcons.BOOKING_CHART_BARCODE,
    8: AppIcons.BOOKING_GENDER,
    9: AppIcons.NOTIFICATION_OUTLINE
  };
  informationPhoto = {
    passenger_type: AppIcons.BOOKING_PASSENGER_TYPE_PHOTO,
    watchlist: AppIcons.BOOKING_WATCHLIST,
    unique_id: AppIcons.BOOKING_UNIQUE_ID,
    mobile_number: AppIcons.BOOKING_MOBILE_NUMBER,
    email: AppIcons.MAIL,
    barcode: AppIcons.BOOKING_CHART_BARCODE,
    gender: AppIcons.BOOKING_GENDER,
    trip_notification: AppIcons.BOOKING_TRIP_NOTIFICATION,
  };
  passengerData: any;

  TRANSPORTATION_TYPE = AppSettings.TRANSPORTATION_TYPE;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;
  passDataToBookingPass: any;
  selectedPassengerData: any;

  constructor(private entityService: EntityService, public cs: CommonBindingDataService,
    private configService: ConfigService,
    private router: Router,
    private validationsService: MiValidationsService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    this.initializeAttributes();
    this.setCountryAndLanguage();
    this.sortGroups();
    this.initializePassengerData();
    this.setDefaultPassenger();
  }


  private initializeAttributes(): void {
    this.attributeLabels = this.cs.getAttributeLabels(this.bookingPass);
  }

  private setCountryAndLanguage(): void {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  private setDefaultPassenger(): void {
    if (this.addedPassengerList.length > 0) {
      this.selectAddedPassenger = this.addedPassengerList[0];
      this.setGroupFields();

    }
  }


  private setGroupFields() {
    this.groups.forEach(groupItem => {
      groupItem.fields.forEach((field: any) => {
        field.attributeValue = this.selectAddedPassenger[field.attributeCode];
      });
    });
  }
  private sortGroups(): void {
    this.groups = _.sortBy(this.bookingBookingPass.tabs[0].groups, 'index');
  }

  private initializePassengerData(): void {
    const { entityRelationshipConfigId, otherEntityId } = this.passengerView.relations;
    this.passengerData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.BOOKING_PASS,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
      relationshipData: [{
        entityRelationshipConfigId,
        otherEntityId
      }]
    };
  }

  search(event: AutoCompleteCompleteEvent) {
    this.entityData.searchStr = event.query;
    this.searchEntity();
  }

  searchEntity() {
    this.tableData = [];
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    const isLoader = false;
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.PASSENGERS, this.entityData, isLoader).subscribe((response: ListResponse) => {
      const passenger = this.cs.getTableData(response);
      this.setPassengerSuggestions(passenger);
    });
  }

  setPassengerSuggestions(res) {
    const passenger = res;
    passenger.map(item => {
      item.name = item.first_name + ' ' + item.last_name
    });
    passenger.forEach(rowData => {
      if (rowData?.passenger_profile_image && (rowData?.passenger_profile_image[0])) {
        this.getFile(rowData)
      }
    });
    this.suggestions = passenger;
  }


  onAddNew() {
    this.router.navigate(['app/passengers/add']);
  }

  extractAttributes(group) {
    const attributesArray = {};
    group.forEach(section => {
      section.fields.forEach(field => {
        attributesArray[field.attributeCode] = field.attributeValue;
      });
    });
    return attributesArray;
  }

  createAttributeIdListForSave(data, data2) {
    const data3 = data2.flatMap(item => {
      return item.fields.map(field => {
        return {
          attributeId: field.attributeId,
          attributeValue: data[field.attributeCode] || null
        };
      });
    });

    return _.filter(data3, item => item.attributeValue !== null);

  }

  onSelectPassenger(selected) {
    this.tempPassenger = selected.value;
    const passengerId = selected.value.id;
    this.getPassengerSetData(passengerId, AppSettings.ENTITY_CODE.PASSENGERS);
  }

  getPassengerSetData(passengerId, ENTITY_CODE, API_CALL?) {
    const desiredOrder = [
      'passenger_type',
      'watchlist',
      'unique_id',
      'mobile_number',
      'email',
      'barcode',
      'gender',
      'trip_notification'
    ];
    this.entityService.getEntity(passengerId, ENTITY_CODE, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe((result: any) => {
      this.passDataToBookingPass = result.attributeCodeValueDtoList;
      this.groups.forEach(groupItem => {
        groupItem.fields.forEach((field: any) => {
          const matchingValue = _.find(this.passDataToBookingPass, { attributeCode: field.attributeCode });
          if (field.attributeCode === 'pass_full_name') {
            const firstName = _.find(this.passDataToBookingPass, { attributeCode: 'first_name' });
            const lastName = _.find(this.passDataToBookingPass, { attributeCode: 'last_name' });
            if (firstName && lastName) {
              field.attributeValue = `${firstName.attributeValue} ${lastName.attributeValue}`;
              this.passDataToBookingPass['pass_full_name'] = field.attributeValue;
            }


          } else if (matchingValue) {
            field.attributeValue = matchingValue.attributeValue;
          }
        });

        groupItem.fields.sort((a, b) => {
          return desiredOrder.indexOf(a.attributeCode) - desiredOrder.indexOf(b.attributeCode);
        });
      });

      const selectedData = this.extractAttributes(this.groups);
      this.buildForm();
      const apiCalls = [];

      apiCalls.push(this.createBookingEntity());
      forkJoin(apiCalls).subscribe((data: any) => {

        this.groups = this.groups.map(g => {
          if (g.code === 'details') {
            g['id'] = data[0]?.entityId;
          }
          return g;
        });
        selectedData['booking_pass_entityId'] = data[0]?.entityId;
        this.passengerData.data = this.createAttributeIdListForSave(selectedData, this.groups);
        this.passengerData.relationshipData[0].otherEntityId = passengerId;

        selectedData['profile_image'] = this.tempPassenger['passenger_profile_image'];
        this.addedPassengerList = [...this.addedPassengerList, selectedData];
        selectedData['entityRelationshipConfigId'] = this.getEntityRelationConfigId();

        this.passengerData.relationshipData[0].entityRelationshipConfigId = this.getEntityRelationConfigId();
        this.selectedPassenger = [];
        if (!this.selectAddedPassenger) {
          this.selectAddedPassenger = this.addedPassengerList[0];
        }

        if (API_CALL !== 'skip') {
          this.saveBookingPassengerData(data[0]?.entityId).subscribe((saveRes) => {
            this.passengerList.emit(this.addedPassengerList);
          });
        }
      });
    });
  }


  getEntityRelationConfigId() {
    return this.bookingPass.relations.entityRelationshipConfigId;
  }

  onRemove(passenger) {
    this.addedPassengerList = _.reject(this.addedPassengerList, { booking_pass_entityId: passenger.booking_pass_entityId });
    if (this.addedPassengerList.length === 0) {
      this.selectAddedPassenger = '';
    } else {
      this.selectAddedPassenger = this.addedPassengerList[0];
    }
    event.stopPropagation();
    return;
  }

  getIcon(id) {
    return this.passengerIcon[id];
  }

  onSelectAddedPassenger(event) {
    this.selectAddedPassenger = event;
    this.setGroupFields();
  }


  onEdit(field) {
    this.groups = _.map(this.groups, (item) => ({
      ...item,
      fields: _.map(item.fields, (fd) => {
        if (fd.attributeCode === field.attributeCode) {
          return { ...fd, isFormVisible: true };
        } else {
          if (fd.attributeCode == 'trip_notification') {
            return { ...fd, isFormVisible: true };
          }
          return { ...fd, isFormVisible: false };
        }

      }),
    }));

    field['isFormVisible'] = true;
    this.cd.detectChanges();

  }


  get value() {
    return this.miFormGroup.value;
  }

  private buildForm() {
    this.buildGroups();
  }

  private buildGroups() {
    const miFormControl = this.getFormControl();
    this.miFormGroup = new FormGroup(miFormControl);

  }

  resetForm() {
    this.miFormGroup.reset();
  }

  private getFormControl() {

    for (const group of this.groups) {
      for (const field of group.fields) {
        if (field.attributeCode !== 'trip_notification') {
          field.isFormVisible = false;
        } else {
          field.isFormVisible = true;
        }
        this.currentSelectedCode = field.inputCode;
        const validators = this.validationsService.addValidator(field.validation, this.currentSelectedCode);
        field.fieldName = field.attributeCode;
        const value = field.attributeValue ? field.attributeValue : '';
        this.formGroupFields[field.attributeCode] = new FormControl(value, validators);
      }
    }
    return this.formGroupFields;
  }

  submitData(event) {
    this.groups = _.map(this.groups, (item) => ({
      ...item,
      fields: _.map(item.fields, (field) => ({ ...field, isFormVisible: false }))
    }));
    const { entityRelationshipConfigId } = this.bookingPass?.relations;

    this.passengerData.relationshipData = [{
      entityRelationshipConfigId: this.selectAddedPassenger.entityRelationshipConfigId,
      otherEntityId: this.selectAddedPassenger.booking_pass_entityId
    }];

    const passengerFormData = this.miFormGroup.value;
    this.passengerData.data = [];
    for (const [key, value] of Object.entries(passengerFormData)) {
      const attributeValue = <any>value;
      if (attributeValue) {
        const obj = typeof attributeValue;
        if (obj == "object") {
          if (attributeValue?.length > 0) {
            this.passengerData.data.push({
              attributeCode: key,
              attributeValue,
            });
          }
        } else {
          this.passengerData.data.push({
            attributeCode: key,
            attributeValue,
          });
        }
      }
    }

    this.passengerData.data = this.cs.mapAttributeIds(this.passengerData.data, this.bookingBookingPass.tabs);
    this.saveBookingPassengerData(this.selectAddedPassenger.booking_pass_entityId).subscribe((saveRes) => {


      this.groups = _.map(this.groups, (item) => ({
        ...item,
        fields: _.map(item.fields, (fd) => {
          return { ...fd, isFormVisible: false };
        }),
      }));


      this.passengerList.emit(this.addedPassengerList);
      this.getPassengerSetData(this.selectAddedPassenger.booking_pass_entityId, AppSettings.ENTITY_CODE.BOOKING_PASS, 'skip');
    });

  }

  createBookingEntity() {
    const entityData = {
      tenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode
    };
    return this.entityService.createEntities(AppSettings.ENTITY_CODE.BOOKING_PASS, entityData).pipe(
      tap((res: entityResponse) => {
        this.bookingPassengerEntityIds.push(res?.entityId);
      }));
  }


  saveBookingPassengerData(bookingPassId) {
    return this.entityService.saveAttributeData(AppSettings.ENTITY_CODE.BOOKING_PASS, bookingPassId, this.passengerData).pipe(
      tap((res: any) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
      }));
  }


  getImage(passenger_profile_image) {

    if (passenger_profile_image) {
      return this.entityService.getFile(passenger_profile_image, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
        const file = new File([result], 'entity');
        const reader = new FileReader();
        reader.readAsDataURL(result);
        const that = this;
        reader.onloadend = function() {
          return reader.result;
        }
      })
    } else {
      return AppSettings.DEFAULT_LOADER_IMAGE;
    }
  }

  getFile(rowData) {

    if (rowData?.passenger_profile_image) {
      this.entityService.getFile(rowData.passenger_profile_image[0], AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
        const file = new File([result], 'entity');
        const reader = new FileReader();
        reader.readAsDataURL(result);
        const that = this;
        reader.onloadend = function() {
          const base64data = reader.result;
          rowData.passenger_profile_image = base64data;
        }
      })
    } else {
      return AppSettings.DEFAULT_LOADER_IMAGE;
    }
  }



}
