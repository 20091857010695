import { Router, RouterModule } from '@angular/router';
import { ConfirmationService, MenuItem, Message, MessageService } from 'primeng/api';
import { forkJoin, Observable, of, Subscription, timer } from 'rxjs';
import { UiService } from '../../services/ui.service';
import { AppSettings } from './../../app.settings';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EntityService } from 'app/modules/shared/services/entity.service';
import type { EntityList } from 'app/modules/vehicles/models/entity.models';
import * as dayjs from 'dayjs';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { AppIcons } from '../../app.icons';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { ConfigService } from '../../services/config.service';
import { StorageService } from './../../services/storage.service';
import { UserCommonService } from './../../services/user-common.service';
import { CommonModule } from '@angular/common';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [ConfirmDialogModule, DropdownModule, FormsModule, TranslateModule, RouterModule, CommonModule]
})
export class HeaderComponent implements OnInit {
  miIcon = AppIcons;
  notificationInterval;
  userFullName: string;
  items: MenuItem[];
  message: Message[] = [];
  profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  changePasswordPopup = false;
  editProfilePopup = false;
  subscription: Subscription;
  myDate = new Date(1496314866000);
  myDateLong = 1496314866000;
  minuts = 240;
  notifications: any = [];
  unReadNotificatonsCount = 0;
  limit = 5;
  offset = 0;
  showMoreOff = true;
  secondsCounter: any;
  logoutSession: any;
  profileData: any;
  selectedEvent: any;
  listColumns: any[] = [];
  eventImage: any[] = [];
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: 'IN',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };
  defaultIconUrl: string = '/assets/images/mi-theme/light/booking/outstation.svg';
  imageBase64: any;

  constructor(
    private uiService: UiService,
    private userCommandService: UserCommonService,
    private router: Router,
    private commonService: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private messageService: MessageService,
    private entityService: EntityService,
    private configService: ConfigService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.loadEvents();
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.setSessionInterval();
    this.storageService.logout.subscribe(res => {
      this.logout();
    });

    const data = localStorage.getItem('profileData');
    this.profileData = JSON.parse(data);

    this.items = [
      {
        label: 'Logout',
        icon: 'fa fa-sign-out',
        command: event => {
          this.doSignOut();
        }
      }
    ];

    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.userFullName = userObj.firstName + ' ' + userObj.lastName;
      if (
        userObj.userDetails !== undefined &&
        userObj.userDetails !== null &&
        userObj.userDetails.profileImage !== null
      ) {
        this.profileUrl =
          AppSettings.GET_FILE_THUMB_URL + userObj.userDetails.profileImage;
      }
    }

  }

  loadEvents() {
    this.entityService.searchEntityHeader(AppSettings.ENTITY_CODE.EVENTS, this.entityData).subscribe(
      (res: any) => {
        this.listColumns = [];
        const observables = res.data.map(element => {
          return this.getEventImage(element?.values?.event_image ? element.values.event_image[0] : null)
            .pipe(map(base64data => ({
              label: element.values.event_name,
              value: element.values.event_name,
              icon: base64data
            })));
        });
        forkJoin(observables).subscribe(
          listColumns => {
            this.listColumns = listColumns;
          },
          (error: any) => {
            console.error('Error fetching event images:', error);
          }
        );
      },
      (error: any) => {
        console.error('Error fetching events:', error);
      }
    );
  }

  getEventImage(eventImage): Observable<string> {
    if (eventImage) {
      return this.getImage(eventImage);
    } else {
      return of(this.defaultIconUrl);
    }
  }

  getImage(eventImage): Observable<string> {
    return new Observable(observer => {
      this.entityService.getFile(eventImage, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
        const file = new File([result], 'image');
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          observer.next(reader.result as string);
          observer.complete();
        };
      });
    });
  }

  onProfileClick(event: any) {
    event.preventDefault();
    const toggleClass = 'toggle-menu';
    event.currentTarget.classList.toggle(toggleClass);
  }

  onSideMenuClick(event: any) {
    event.preventDefault();
    document.getElementsByTagName('body')[0].classList.toggle('closed');
    this.uiService.sidebarToggled();
  }

  doSignOut() {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.commonService.getLabel('msg_logout'),
      header: this.commonService.getLabel('header_logout_confirmation'),
      icon: '',
      acceptButtonStyleClass: "p-button-danger",
      rejectButtonStyleClass: "cursor-pointer  p-button default-text-light-black-color  bg-white",
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: 'Logout',
      rejectLabel: this.commonService.getLabel('content.lbl_cancel'),
      accept: () => {
        this.logout();
      }
    });
  }

  logout() {
    this.userCommandService.signOut().subscribe(results => {
      this.storageService.removeAll();
      location.reload();
    }, (error) => {
      this.errorMessage(error);
    });
  }

  onCancelEditProfile(event: any) {
    this.editProfilePopup = !this.editProfilePopup;
  }

  onCancelChangePassword(event: any) {
    this.changePasswordPopup = !this.changePasswordPopup;
  }

  errorMessage(error) {
    error = JSON.parse(error);
    this.message = [];
    this.message.push({
      severity: 'error',
      summary: 'error',
      detail: error.general[0].message
    });
  }

  setSessionInterval() {
    const sessionInterval = timer(10000, 1000);
    this.secondsCounter = sessionInterval.subscribe(n => {
      const idleSessionTime = <number>this.storageService.getItem(AppSettings.INTERVAL);
      if (idleSessionTime) {
        if (idleSessionTime < new Date().getTime()) {
          this.confirmYouThere();
          this.secondsCounter.unsubscribe();
        } else {
          clearTimeout(this.logoutSession);
        }
      }
    });
  }

  confirmYouThere() {
    this.sessionTimeout();
    const message = this.commonService.getLabel('lbl_confirm_idle_session');
    this.confirmationService.confirm({
      message,
      rejectVisible: false,
      accept: () => {
        this.refreshSession();
      },
      reject: () => {
        this.refreshSession();
      }
    });
  }

  sessionTimeout() {
    this.logoutSession = setTimeout(() => {
      const idleSessionTime = <number>this.storageService.getItem(AppSettings.INTERVAL);
      if (idleSessionTime < new Date().getTime()) {
        this.logout();
      } else {
        clearTimeout(this.logoutSession);
      }
    }, AppSettings.IDLE_TIME_ON_CONFIRM_MSG);
  }

  refreshSession() {
    const idleTime = dayjs()
      .add(AppSettings.IDLE_TIME, 'm')
      .valueOf();
    this.storageService.setItem('interval', idleTime);
    clearTimeout(this.logoutSession);
    this.setSessionInterval();
  }

  createAcronym(name) {
    if (!name) {
      return '';
    }
    const words = name.split(' ');
    const acronym = words.map(word => word[0]).join('');
    return acronym.toUpperCase();
  }

  goToSettings() {
    this.router.navigate(['app/settings/user-profile']);
  }
  goToChangePassword() {
    this.router.navigate(['app/settings/login-and-password']);
  }

}
