import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { Router } from '@angular/router';
import type { Language } from 'app/modules/shared/models/language';
import { AppSettings } from 'app/modules/shared/app.settings';

@Component({
  selector: 'vehicle-specification',
  standalone: true,
  imports: [ButtonModule, TranslateModule],
  templateUrl: './specification.component.html',
  styleUrl: './specification.component.scss'
})
export class SpecificationComponent implements OnInit {
  iconPos: any = 'right';
  @Input() attributeValues: any;
  @Input() attributeLabels: any;
  @Input() vehicleAttributeData: any;
  @Input() vehicleEntityId;
  dimensions: any;
  weight: any;
  performance: any;
  fuelEconomy: any;
  engine: any;
  transmission: any;
  wheelsAndTyres: any;
  fuel: any;
  oil: any;
  tab: any;
  language: Language;
  languageCode: string;

  constructor(
    public cs: CommonBindingDataService, private router: Router
  ) { }

  ngOnInit() {
    this.tab = this.vehicleAttributeData.tabs.find(ele => ele.tabCode === 'specs');
    this.dimensions = this.tab.groups.find(ele => ele.code === 'dimensions');
    this.weight = this.tab.groups.find(ele => ele.code === 'weight');
    this.performance = this.tab.groups.find(ele => ele.code === 'performance');
    this.fuelEconomy = this.tab.groups.find(ele => ele.code === 'fuel_economy');
    this.engine = this.tab.groups.find(ele => ele.code === 'engine');
    this.transmission = this.tab.groups.find(ele => ele.code === 'transmission');
    this.wheelsAndTyres = this.tab.groups.find(ele => ele.code === 'wheels_and_tyres');
    this.oil = this.tab.groups.find(ele => ele.code === 'oil');
    this.fuel = this.tab.groups.find(ele => ele.code === 'fuel');
  }

  redirectToEdit() {
    this.router.navigate(['/app/vehicles/add/' + this.vehicleEntityId]);
  }

  getUnit(field) {
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const tempUnit = JSON.parse(field?.imperialUnit);
    this.languageCode = this.language[0].langCode;
    return tempUnit[this.languageCode];
  }

}
