import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigService } from 'app/modules/shared/services/config.service';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { forkJoin } from 'rxjs';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import { AttributeData, entityResponse } from '../../../vehicles/models/attribute.models';
import { EntityCount, EntityCountsResponse, EntityList } from '../../../vehicles/models/entity.models';
import { ListResponse, Values } from '../../../vehicles/models/listResponse.models';

@Component({
  selector: 'app-add-queue',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, TranslateModule, MITableComponent, AsyncPipe, ToastModule],
  templateUrl: './add-queue.component.html',
  styleUrl: './add-queue.component.scss'
})
export class AddQueueComponent implements OnInit {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  @Output() setCountAll: EventEmitter<any> = new EventEmitter<any>();
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  @Input() moduleName: string;
  filterList: {};
  btnLabel: string;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  assignQueueLocation: string = this.cs.getLabel('queue.assign_queue_location');
  filterFieldsArray: any[];
  attributeData: any;
  data: AttributeData;
  rowItems: MenuItem[] | undefined;
  currentRow: any;
  visible: boolean = false;
  totalRecords: number = 0;
  attributeIdOfGroupStatus: string;
  bulkSelectionsItems: MenuItem[] | undefined;
  isEntity: boolean = true;
  uniqueId: string = 'add_queue';
  queueData: any;
  queueId: string;
  queueLocationValue: string;
  queueAttributeData: any = {};
  queueTabFields: any = {};
  queueLocationAttributeId: string = 'queue_location';
  addedOnAttributeId: string = 'added_on';
  addedOnCurrentTime: number;
  queueAttributes: any[];
  relationshipData: any[];

  constructor(private queueManagementService: EntityService,
    private cs: CommonBindingDataService, private confirmationService: ConfirmationService, private cd: ChangeDetectorRef,
    private router: Router, private messageService: MessageService, private actRoute: ActivatedRoute,
    private configService: ConfigService) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.initializeColumn();
    this.bulkUpdateItems();
    this.setLabels();
    this.getAttributeData();
    this.getData();
    this.getAttributes();
    this.createAttributes();
  }

  private setBreadcrumb() {
    this.routePath = [{
      label: this.cs.getLabel('queue.lbl_add_queue'),
      routerLink: '../list',
      icon: 'pi pi-arrow-left',
      iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
    }];
  }


  private initializeColumn() {
    this.allColumns = [
      {
        "attributeCode": "first_name",
        "attributeIndex": 0,
        "field": "first_name",
        "header": this.cs.getLabel('queue.columns.driver_name')
      },
      {
        "attributeCode": "duty_status",
        "attributeIndex": 1,
        "field": "duty_status",
        "header": this.cs.getLabel('queue.columns.duty_status')
      },
      {
        "attributeCode": "active_vehicle_id",
        "attributeIndex": 2,
        "field": "active_vehicle_id",
        "header": this.cs.getLabel('queue.columns.vehicle_assigned')
      },
      {
        "attributeCode": "driver_group_id",
        "attributeIndex": 3,
        "field": "driver_group_id",
        "header": this.cs.getLabel('queue.columns.driver_group')
      },
      {
        "attributeCode": "unique_id",
        "attributeIndex": 4,
        "field": "unique_id",
        "header": this.cs.getLabel('queue.columns.unique_id')
      },
      {
        "attributeCode": "parking",
        "attributeIndex": 5,
        "field": "parking",
        "header": this.cs.getLabel('queue.columns.parking')
      },
      {
        "attributeCode": "add_queue_location",
        "attributeIndex": 6,
        "field": "add_queue_location",
        "header": this.cs.getLabel('queue.columns.queue_location')
      },
      {
        "attributeCode": "queue_action",
        "attributeIndex": 7,
        "field": "queue_action",
        "header": this.cs.getLabel('queue.columns.action')
      }
    ];
  }

  private bulkUpdateItems() {
    this.bulkSelectionsItems = [
      {
        label: this.assignQueueLocation,
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: "bulk-update-icon",
        styleClass: "bulk-update-list",
        command: () => {
          this.tableRef.showBulkRowDialog();
        },
      },
    ];
  }

  public setLabels() {
    const now = new Date();
    this.addedOnCurrentTime = now.getTime();
    this.btnLabel = this.cs.getLabel('queue.lbl_add_queue');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  getAttributeData() {
    this.queueManagementService.getAttributeDefinition(AppSettings.ENTITY_CODE.QUEUE,  AppSettings.VIEW_CODE.BULK_UPDATE_VIEW).subscribe(res => {
      if (res) {
        this.attributeData = res;
        this.isLoading = false;
        this.filterFieldsArray = res?.tabs[0]?.groups[0]?.fields;
      }
    });
  }

  getTableView() {
    this.isLoading = false;
    this.searchEntity();
    this.filterAvailableColumns = _.clone(this.allColumns);
    this.isEntity = false;
  }

  getFilterView() {
    this.filterList = [
      {
        "attributeCode": "Parking Location",
        "attributeIndex": 3,
        "presetValues": [
          {
            "labelKey": "Parking D2",
            "labelValue": "Parking D2",
          },
          {
            "labelKey": "Parking Hub 3",
            "labelValue": "Parking Hub 3",
          },
          {
            "labelKey": "Parking- Chelsea Harbour",
            "labelValue": "Parking- Chelsea Harbour",
          },
        ],
      },
      {
        "attributeCode": "Include Non Check-in Drivers ",
        "attributeIndex": 3,
        "presetValues": [
          {
            "labelKey": "Yes",
            "labelValue": "Yes",
          },
          {
            "labelKey": "No",
            "labelValue": "No",
          }
        ],
      }
    ];
  }

  getEntityCount() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityCountPayload = <EntityCount>{
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.DRIVER,
    };

    this.queueManagementService.getEntityCount(AppSettings.ENTITY_CODE.DRIVER, entityCountPayload)
      .subscribe((res: EntityCountsResponse) => {
        this.entityCount = res;
        this.setCountOfAll(this.entityCount.counts)
      });
  }

  setCountOfAll(count) {
    this.setCountAll.emit(count)
  }

  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
      getEntityCount: this.getEntityCount(),
    })
  }

  searchEntity() {
    this.entityData.countryCode = this.country[0].countryCode;
    this.queueManagementService.searchEntity(AppSettings.ENTITY_CODE.DRIVER, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = [];
      this.tableData = res.data?.map(data => {
        const relatedData = data?.relatedData || [];
        const groupedData = _.groupBy(relatedData, 'entityCode');

        const relatedVehicle = (groupedData['vehicle'] || []).map(related => ({
          id: related.id,
          ...related.values
        }));

        const relatedDriverGroup = (groupedData['driver_group'] || []).map(related => ({
          id: related.id,
          ...related.values
        }));

        const relatedLocation = (groupedData['location'] || []).map(related => ({
          id: related.id,
          ...related.values
        }));

        return {
          id: data.id,
          ...data.values,
          vehicle: relatedVehicle,
          driverGroup: relatedDriverGroup,
          location: relatedLocation
        };
      }) || [];
    })
  }

  getQueueLocationFromMiTable(event) {
    this.queueLocationValue = event;
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.QUEUE;
    this.queueManagementService.getAttributeDefinition(entityType,  AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.queueAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.queueTabFields = this.queueAttributeData.tabs[0].groups[0].fields;
        this.relationshipData = this.queueAttributeData.relations;
      }
    });
  }

  createAttributes() {
    this.queueAttributes = [
      {
        attributeCode: 'driver_entity_id',
        rowAttributeProperty: 'id'
      },
      {
        attributeCode: 'driver_first_name',
        rowAttributeProperty: 'first_name'
      },
      {
        attributeCode: 'driver_last_name',
        rowAttributeProperty: 'last_name'
      },
      {
        attributeCode: 'driver_duty_status',
        rowAttributeProperty: 'duty_status'
      },
      {
        attributeCode: 'vehicle_entity_id',
        rowAttributeProperty: 'active_vehicle_id'
      },
      {
        attributeCode: 'vehicle_name_code',
        rowAttributeProperty: 'name_code'
      },
      {
        attributeCode: 'vehicle_body_type',
        rowAttributeProperty: 'body_type'
      },
      {
        attributeCode: 'driver_group_entity_id',
        rowAttributeProperty: 'driver_group_id'
      },
      {
        attributeCode: 'driver_unique_id',
        rowAttributeProperty: 'unique_id'
      },
      {
        attributeCode: 'location_entity_id',
        rowAttributeProperty: 'location_entity_id'
      },
      {
        attributeCode: 'queue_location',
        rowAttributeProperty: 'queue_location'
      }
    ];
  }

  onFilterValueChange(event) {
    const queueData = event;
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(queueData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.entityData.filters.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.entityData.filters = _.filter(_.uniq(this.entityData.filters, function(item, key, a) {
      if (item.attributeValue.length > 0) {
        return item.attributeCode;

      }
    }), function(element) {
      if (element.attributeValue.length > 0) {
        return true;
      }
      return false;
    });
    this.entityData.offset = event.first;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
    this.searchEntity();
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  setCurrentRowData(event) {
    this.relationshipData = this.relationshipData.map(ele => ({ otherEntityId: ele.otherEntityId, entityRelationshipConfigId: ele.entityRelationshipConfigId }))
    const queueFormData = event;
    this.queueData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data?.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };

    for (const [key, value] of Object.entries(queueFormData)) {
      const attributeValue = <any>value;
      if (attributeValue) {
        const obj = typeof attributeValue;
        if (obj == "object") {
          if (attributeValue?.length > 0) {
            this.queueData?.data?.push({
              attributeCode: this.queueAttributes?.find(ele => ele?.rowAttributeProperty === key)?.attributeCode,
              attributeValue: typeof attributeValue === 'string' ? attributeValue : attributeValue[0]?.id,
            });
          }
        } else {
          this.queueData?.data?.push({
            attributeCode: this.queueAttributes?.find(ele => ele?.rowAttributeProperty === key)?.attributeCode,
            attributeValue: typeof attributeValue === 'string' ? attributeValue : attributeValue[0]?.id,
          });
        }
      }
    }

    this.queueData.relationshipData = this.relationshipData;
    this.queueData?.data?.push({
      attributeCode: this.queueAttributes[5].attributeCode,
      attributeValue: event?.vehicle[0]?.name_code,
    });

    this.queueData?.data?.push({
      attributeCode: this.queueAttributes[6].attributeCode,
      attributeValue: event?.vehicle[0]?.body_type,
    });

    this.queueData?.data?.push({
      attributeCode: this.queueAttributes[9].attributeCode,
      attributeValue: event?.location[0]?.id,
    });

    this.queueData?.data?.push({
      attributeCode: this.queueLocationAttributeId,
      attributeValue: this.queueLocationValue,
    });

    this.queueData?.data?.push({
      attributeCode: this.addedOnAttributeId,
      attributeValue: this.addedOnCurrentTime,
    });

    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode
    }

    this.queueData.data = this.queueData.data.filter(ele => ele.attributeCode);
    if (this.queueId) {
      this.saveQueueData(this.queueData)
    } else {
      this.createEntityAndUpdateAttributeData(entitiesData);
    }
  }

  createEntityAndUpdateAttributeData(entitiesData) {
    this.queueManagementService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.queueId = res.entityId;
      this.saveQueueData(this.queueData);
    });
  }

  saveQueueData(queueData?) {
    queueData.data = this.cs.mapAttributeIds(queueData.data, this.queueAttributeData.tabs);
    this.queueManagementService.saveAttributeData(queueData.entityCode, this.queueId, queueData).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('queue.queue_added_successfully'), });
      this.router.navigate(['app/queue/list']);
    });
  }

  tabViewChange(event) {
    switch (event.index) {
      case 0:
        this.entityData.actionStatus = '';
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.entityData.filters = [];
        this.searchEntity();
        break;
      default:
        break;
    }
  }

}
