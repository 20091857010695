import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DriverListCardComponent } from 'app/modules/dispatch/components/assign-driver/driver-list-card/driver-list-card.component';
import { DriverSidebarComponent } from 'app/modules/dispatch/components/driver-sidebar/driver-sidebar.component';
import { AppIcons } from 'app/modules/shared/app.icons';
import { DateTimeComponent } from 'app/modules/shared/components/mi-fields/date-time/date-time.component';
import { MiFieldsComponent } from 'app/modules/shared/components/mi-fields/mi-fields.component';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ManualDispatchDetailsComponent } from '../manual-dispatch-details/manual-dispatch-details.component';
import { ManualPassengerVehicleDetailsComponent } from '../manual-passenger-vehicle-details/manual-passenger-vehicle-details.component';

@Component({
  selector: 'app-manual-dispatch',
  standalone: true,
  imports: [TranslateModule, ButtonModule,DriverSidebarComponent,InputSwitchModule,FormsModule, ReactiveFormsModule,NgClass, MiFieldsComponent,DateTimeComponent,DriverListCardComponent,ManualDispatchDetailsComponent,ManualPassengerVehicleDetailsComponent],  
  templateUrl: './manual-dispatch.component.html',
  styleUrl: './manual-dispatch.component.scss'
})
export class ManualDispatchComponent {

  @Input() assignConfiguration  = {passengerVehicleCount: 2, luggageVehicleCount: 3, isBookNow: false};
  rightDialogPosition: string = "center";
  miIcons = AppIcons;
  position: string;
  rightDriversSectionVisible: boolean = false;
  bookingId:string;
  autoToManual: boolean = false; 
  driverDataVisible: boolean = false; 
  // driverData = {
  //   actionStatus: 'ACTIVE',
  //   deleted: false,
  //   entityCode: 'driver',
  //   id: '01J9NY0W2DEH7NXXFPCKDPY5SV',
  //   relatedData: null,
  //   values: {
  //     unique_id: 'D6715980',
  //     last_name: 'Stamm',
  //     driver_status: 'Active',
  //     first_name: 'Delbert',
  //     duty_status: 'Off',
  //   },
  // };

  driverData = {
    actionStatus: "ACTIVE",
    deleted: false,
    entityCode: "driver",
    id: "01J9WXDVMEES8HHFZPCNCJ398X",
    relatedData: [
      {
        actionStatus: null,
        deleted: false,
        entityCode: "driver_group",
        id: "01J9XD920XT9D1Y7REBSKSJDQW",
        relatedData: null,
        values: {
          group_status: "Active",
          assigned_drivers: ["01J9WXDVMEES8HHFZPCNCJ398X", "01J9V2GTXHR1Z3FTT07X992SN5"],
          created_by: "Manoj Gunale",
          created_on: 1728637602615,
          group_color: "Bronze",
          group_name: "Crick",
        },
      },
      {
        actionStatus: "ASSIGNED",
        deleted: false,
        entityCode: "vehicle",
        id: "01J9V236501949KYFHKZY70S3J",
        relatedData: null,
        values: {
          reg_number: "MH16DH2424",
          name_code: "CAR123",
          region_geofence: "HADAPSAR (HAD)",
          body_type: "SUV",
          fuel_type: "CNG",
          manufacturer: "Hyundai",
          model: "Creta",
          passenger_capacity: "5",
          vehicle_status: "Active",
          vehicle_type: "Car",
        },
      },
  ],
  values: {
    active_vehicle_id: "01J9V236501949KYFHKZY70S3J",
    unique_id: "MG2131",
    call_sign_code: "Gold3121",
    default_vehicle_id: "",
    driver_group_id: "01J9XD920XT9D1Y7REBSKSJDQW",
    driver_profile_image: ["01J9WXB38B724XK5YPNGD20J7C"],
    driver_status: "Active",
    duty_status: "Off",
    first_name: "Manoj",
    last_name: "Gunale",
    region_geofence: "HADAPSAR (HAD)",

  },
};


  showRightDriversDialog(position: string) {
    this.rightDialogPosition = position;
    this.position = position;
    this.rightDriversSectionVisible = true;
  }

  showDriverData() {
    this.driverDataVisible = true;
  }

  hideDriverData() {
    this.driverDataVisible = false;
  }

}
