import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../../shared/services/config.service";
import { RestApiService } from "../../shared/services/rest-api.service";
import { ZoneItem, ZoneItemPagination } from "../models/zone.models";

@Injectable({
  providedIn: "root",
})
export class ManageZonesService {
  tenantCode: string;
  constructor(
    private restApiService: RestApiService,
    private configService: ConfigService
  ) {
    this.tenantCode = configService.getLoggedInTenantCode();
  }

  getZonesList(data: ZoneItemPagination): Observable<any> {
    return this.restApiService.post(
      "",
      `${this.tenantCode}/api/v1/zones/search?forTenantCode=${this.configService.getForTenantCode()}`,
      data,
      "page-center"
    );
  }
  getZoneDetails(zoneId: string): Observable<any> {
    return this.restApiService.get(
      "",
      `${this.tenantCode}/api/v1/zones/${zoneId}?forTenantCode=${this.configService.getForTenantCode()}`,
      "page-center"
    );
  }
  addZone(zoneItem: ZoneItem): Observable<any> {
    return this.restApiService.post(
      "",
      `${this.tenantCode}/api/v1/zones`,
      zoneItem,
      "page-center"
    );
  }
  editZone(zoneId: string, zoneItem: ZoneItem): Observable<any> {
    return this.restApiService.put(
      "",
      `${this.tenantCode}/api/v1/zones/${zoneId}`,
      zoneItem,
      "page-center"
    );
  }
}
