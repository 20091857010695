import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { tap } from 'rxjs/operators';
import { AppSettings } from '../../shared/app.settings';
import { BasicTableComponent } from '../../shared/components/basic-table/basic-table.component';
import { MiFormComponent } from '../../shared/components/mi-form/mi-form.component';
import { Country } from '../../shared/models/country';
import { Language } from '../../shared/models/language';
import { CommonBindingDataService } from '../../shared/services/common-binding-data.service';
import { ConfigService } from '../../shared/services/config.service';
import { EntityService } from '../../shared/services/entity.service';
import type { AttributeData, entityResponse } from '../../vehicles/models/attribute.models';

@Component({
  selector: 'app-add-event',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, BasicTableComponent, ButtonModule, TranslateModule],
  templateUrl: './add-event.component.html',
  styleUrl: './add-event.component.scss',
  providers: [
    CommonBindingDataService,
  ]
})
export class AddEventComponent {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  activeIndex: number = 1;
  data: AttributeData;
  eventAttributeData: any;
  routePath: MenuItem[] = [];
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  eventAttributeLength;
  eventId: string;
  attributeValues = [];
  nextBtnLabel;
  eventEntityData: any;
  eventDetails: any;
  multiSelectDisplay: string = 'chip';
  dateFormat: any = AppSettings.DATE_FORMAT_DD_MMM_YYYY;

  constructor(private eventService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private configService: ConfigService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get('id');
    this.setLabels();
    this.setAppSettings();
    this.setRoutePath();
    this.getEntityDetails();
    this.getAttributes();
  }

  getEntityDetails() {
    if (!this.eventId) {
      return;
    }
    this.eventService.getEntity(this.eventId, AppSettings.ENTITY_CODE.EVENTS, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).pipe(
      tap(entityRes => {
        if (entityRes) {
          this.eventDetails = entityRes;
          this.attributeValues = this.cs.getOrgAttributeValues(entityRes);
          console.log(this.attributeValues);
          const addressAndLatLng = this.attributeValues[AppSettings.EVENT_ATTRIBUTES.EVENT_GEOLOCATION];
          const latLngArray = addressAndLatLng ? addressAndLatLng?.split(',') : 0;
          const geoLocationValue = {
            address: this.attributeValues[AppSettings.EVENT_ATTRIBUTES.EVENT_ADDRESS],
            lat: parseFloat(latLngArray[0]),
            lng: parseFloat(latLngArray[1])
          };
          this.attributeValues[AppSettings.EVENT_ATTRIBUTES.EVENT_ADDRESS] = geoLocationValue;
          this.attributeValues[AppSettings.EVENT_ATTRIBUTES.EVENT_GEOLOCATION] = geoLocationValue.address;
          this.parseDates();
          this.getAttributes();
        }
      })).subscribe();
  }

  parseDates() {
    AppSettings.EVENT_ATTRIBUTES.DATE_ATTRIBUTE_CODES.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
    });
  }

  setLabels() {
    this.nextBtnLabel = this.eventId ? this.cs.getLabel('label_update') : this.cs.getLabel('lbl_save');
  }

  setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.moduleName = AppSettings.ENTITY_TYPE.EVENTS;
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.EVENTS;
    this.eventService.getAttributeDefinition(entityType,  AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.eventAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.setMinDateForEventEndDate();
        this.eventAttributeLength = this.eventAttributeData.tabs.length;
        if (this.eventId) {
          this.setAddressInPresetValuesIfNotPresent();
        }
      }
    });
  }

  setMinDateForEventEndDate() {
    this.eventAttributeData.tabs.forEach(tabs => {
      tabs.groups.forEach(group => {
        if (group.code === 'related_settings') {
          group.fields.forEach((field) => {
            if (field.attributeCode === 'event_start_date') {
              field.dateChange = (arg) => {
                group.fields.find(ele => ele.attributeCode === 'event_end_date').validation.min = arg;

              }
            }
          });
        }
      });
    });
  }

  setAddressInPresetValuesIfNotPresent() {
    const addressField = this.eventAttributeData.tabs[0].groups[1].fields.find(element => element.attributeCode === 'address');
    if (!addressField) {
      return;
    }
    const addressPresent = addressField.presetValues.find(element => element.labelKey === this.eventDetails.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'address')?.attributeValue);
    this.eventAttributeData.tabs[0].groups[1].fields.forEach(element => {
      if (element.attributeCode === 'address') {
        element.presetValues.push({
          labelKey: this.eventDetails.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'address')?.attributeValue,
          labelValue: this.eventDetails.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'address')?.attributeValue,
        });
      }
    });
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.eventId ? this.cs.getLabel('events.edit_event') : this.cs.getLabel('events.add_event'),
        routerLink: '/app/events/list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      }
    ];
  }

  onSaveEvents(event) {
    let eventData;
    eventData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };

    const eventFormData = event;
    const dateIds = AppSettings.EVENT_ATTRIBUTES.DATE_ATTRIBUTE_CODES;
    for (const [key, value] of Object.entries(eventFormData)) {
      let attributeValue = <any>value;
      if (dateIds.includes(key)) {
        attributeValue = this.getFormattedTime(key, value);
      }
      if (attributeValue !== null) {
        if (key === AppSettings.EVENT_ATTRIBUTES.EVENT_ADDRESS) {
          const geolocation = attributeValue

          eventData.data.push({
            attributeCode: key,
            attributeValue: geolocation.address
          })
          eventData.data.push({
            attributeCode: AppSettings.EVENT_ATTRIBUTES.EVENT_GEOLOCATION,
            attributeValue: `${geolocation.lat},${geolocation.lng}`
          })
        } else if (key === AppSettings.EVENT_ATTRIBUTES.EVENT_GEOLOCATION) {

        } else {
          eventData.data.push({
            attributeCode: key,
            attributeValue
          });
        }
      }
    }

    eventData.data = this.cs.mapAttributeIds(eventData.data, this.eventAttributeData.tabs);
    this.setEventEntityData(this.data.entityCode);

    if (!this.eventId) {
      this.eventService.createEntities(this.eventEntityData.entityCode, this.eventEntityData).subscribe((res: entityResponse) => {
        this.eventId = res.entityId;
        this.saveEventData(this.eventEntityData.entityCode, eventData, 'create');
      });
    } else {
      this.saveEventData(this.eventEntityData.entityCode, eventData, 'update');
    }
  }

  getFormattedTime(key, value) {
    if (key !== AppSettings.EVENT_ATTRIBUTES.END_DATE_ATTRIBUTE_CODE) {
      const date = new Date(<string>value);
      date.setHours(0, 0, 0, 0);
      return date.getTime();
    } else {
      const date = new Date(<string>value);
      date.setHours(23, 59, 59, 0);
      return date.getTime();
    }
  }

  setEventEntityData(entityCode) {
    this.eventEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: entityCode
    }
  }

  saveEventData(entityCode, eventData, action) {
    this.eventService.saveAttributeData(entityCode, this.eventId, eventData).subscribe({
      next: (res: any) => {
        if (action === 'update') {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('events.event_updated_successfully'), });
        } else if (action === 'create') {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('events.event_added_successfully'), });
        }
        this.router.navigate(['app/events']);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  onNextBtnClick() {
    const btn = document.getElementById('details');
    btn.click();
  }

  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(['app/events/list']);
  }
}
