
<div class="flex flex-row relative left-0 z-1">
  <div class="flex flex-row light-blue-container align-items-center">
    <div class="color-bar green mr-2"></div>
    <div class="font-16 font-bold default-text-black-color mr-2">{{bookingDetails.values.pickup_date | date: 'hh:mm, dd MMM yyyy'}}</div>
    <div class="font-13 font-semibold default-text-black-color mr-2">
      {{(bookingDetails?.values.number_of_passenger ? bookingDetails?.values.number_of_passenger : null) }}
      <span translate>bookings.lbl_passenger</span>
    </div>
    <div class="font-13 font-semibold default-text-black-color type-of-pass mr-2 player-profile-manual">{{bookingDetails?.values?.owner_pass_type}}</div>
    <div><i class="mi-lg mi-watchlist inline-block vertical-align-text-bottom cursor-pointer wh-20"></i></div>
  </div>
  <div class="flex flex-row align-items-center ml-2">
    <div class="mr-2 flight-container">
      @if (bookingDetails?.values.transportation_type === TRANSPORTATION_TYPE.AIRPORT) {
        <i class="mi-lg mi-airport inline-block vertical-align-text-bottom cursor-pointer wh-20"></i>
      } @else {
        @switch (bookingDetails?.transportation_mode) {
          @case (TRANSPORTATION_MODE.CAR) {
          <i class="{{miIcons.BOOKING_CAR}} inline-block vertical-align-text-bottom cursor-pointer wh-20 "></i>
          }

          @case (TRANSPORTATION_MODE.SHUTTLE) {
          <i class="{{miIcons.BOOKING_SHUTTLE_OLD}} inline-block vertical-align-text-bottom cursor-pointer wh-20 "></i>
          }

          @case (TRANSPORTATION_MODE.BUS) {
          <i class="{{miIcons.BOOKING_BUS}} inline-block vertical-align-text-bottom cursor-pointer wh-20 "></i>
          }
        }
      }
    </div>
    <div class="mr-2">
        @if (bookingDetails?.values.number_of_passenger > 2) {
          <i class="{{miIcons.BOOKING_SHARED_RIDE}} inline-block vertical-align-text-bottom cursor-pointer wh-20"></i>
        } @else {
          <i class="{{miIcons.BOOKING_PRIVATE_RIDE}} inline-block vertical-align-text-bottom cursor-pointer wh-20"></i>
        }
        <span class="default-separator-color font-15">|</span>
    </div>
    @if (getTotalChildSeats() > 0) {
      <div class="mr-2">
        <span class="font-16 font-bold text-dark-green">{{ getTotalChildSeats() }}</span>
        <span class="font-14 font-semibold text-dark-green ml-1" translate>bookings.child</span>
        <span class="default-separator-color ml-2 font-15">|</span>
      </div>
    }
    @if (bookingDetails.values.wheelchair > 0) {
      <div class="mr-2">
        <span class="font-16 font-bold text-dark-green">{{bookingDetails.values.wheelchair}}</span>
        <span class="font-14 font-semibold text-dark-green ml-1" translate>bookings.wheelChair</span>
        <span class="default-separator-color ml-2 font-15">|</span>
      </div>
    }
    @if (bookingDetails.values.luggage > 0) {
      <div class="mr-2">
        <span class="font-16 font-bold text-dark-green">{{bookingDetails.values.luggage}}</span>
        <span class="font-14 font-semibold text-dark-green ml-1" translate>bookings.lbl_bags</span>
        <span class="default-separator-color ml-2 font-15">|</span>
      </div>
    }
    @if (bookingDetails.values.additional_vehicle_requirement) {
      <div>
        <i class="mi-lg mi-stepper_color mi-additional_vehicle inline-block vertical-align-text-bottom cursor-pointer wh-20"></i>
        <span class="font-18 font-semibold default-text-black-color ml-1">{{bookingDetails.values.additional_vehicle_requirement}}</span
        ><span class="default-separator-color ml-2 font-15">|</span>
      </div>
    }
    @if (bookingDetails.values.booking_note) {
      <div>
        <span #mydiv class="relative white-space-nowrap pl-1">
            <i class="mi-lg bg-orange mi-instructions mt-2 inline-block cursor-pointer wh-20"
                [pTooltip]="bookingDetails.values.booking_note" 
                tooltipPosition="right" 
                tooltipStyleClass="tooltip-container">
            </i>
        </span>
      </div>
    }
  </div>
</div>