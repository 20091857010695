import { Component, OnInit } from "@angular/core";
import { DashboardNavbarComponent } from "../dashboard-navbar/dashboard-navbar.component";
import { TranslateModule } from "@ngx-translate/core";
import { FilterSectionComponent } from "./filter-section/filter-section.component";
import { AccordionModule } from "primeng/accordion";
import { NgClass } from "@angular/common";
import data from "../../../../../../src/assets/json/dashboard/data.json";
import { AppSettings } from "app/modules/shared/app.settings";
import { ListResponse, Values } from "app/modules/vehicles/models/listResponse.models";
import { EntityService } from "app/modules/shared/services/entity.service";
import { EntityList } from "app/modules/vehicles/models/entity.models";
import { Country } from "app/modules/shared/models/country";
import { ConfigService } from "app/modules/shared/services/config.service";

@Component({
  selector: "app-activity-feed",
  templateUrl: "./activity-feed.component.html",
  styleUrls: ["./activity-feed.component.scss"],
  standalone: true,
  imports: [
    DashboardNavbarComponent,
    TranslateModule,
    FilterSectionComponent,
    AccordionModule,
    NgClass,
  ],
})
export class ActivityFeedComponent implements OnInit {
  nodes!: any[];
  listColumns: Values;
  pagination: any;
  totalRecords: number = 0;
  tableData: any = [];
  country: Country;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.BOTH,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };

  constructor(private dashboardService: EntityService, private configService: ConfigService) { }

  ngOnInit() {
    this.nodes = data.nodes;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.searchEntity()
  }

  callFull(e) {
    e.stopPropagation();
  }

  searchEntity() {
    this.tableData = [];
    this.entityData.countryCode = this.country[0].countryCode;
    this.dashboardService.searchEntity(AppSettings.ENTITY_CODE.BOOKING_ACTIVITY, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      console.log("BOOKING_ACTIVITY", res);
      // this.tableData = res.data?.map(data => {
      //   return {
      //     ...data.values,
      //   };
      // }) || [];
    })
  }
}
