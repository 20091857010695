import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { DispatchMapComponent } from "../dispatch-map/dispatch-map.component";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { TooltipModule } from "primeng/tooltip";
import { AssignDriverComponent } from "../assign-driver/assign-driver.component";
import { DialogHeaderComponent } from "../dialog-header/dialog-header.component";
import { DriverDetailsComponent } from "../assign-driver/driver-details/driver-details.component";
import { DriverListCardComponent } from "../assign-driver/driver-list-card/driver-list-card.component";
import { TranslateModule } from "@ngx-translate/core";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { DatePipe } from "@angular/common";
import { AppIcons } from "../../../shared/app.icons";
import { AppSettings } from "../../../shared/app.settings";
import * as _ from 'lodash';
import { ActivatedRoute, Router } from "@angular/router";
import { EntityService } from "app/modules/shared/services/entity.service";
import { ConfigService } from "app/modules/shared/services/config.service";
import { DriverSidebarComponent } from "../driver-sidebar/driver-sidebar.component";
import { BookingSidebarComponent } from "../booking-sidebar/booking-sidebar.component";
@Component({
  selector: "app-dispatch",
  templateUrl: "./dispatch.component.html",
  styleUrls: ["./dispatch.component.scss"],
  standalone: true,
  imports: [
    BreadcrumbModule,
    DispatchMapComponent,
    DialogModule,
    ButtonModule,
    TooltipModule,
    AssignDriverComponent,
    DialogHeaderComponent,
    DriverDetailsComponent,
    DriverListCardComponent,
    TranslateModule,
    DatePipe,
    DriverSidebarComponent,
    BookingSidebarComponent
  ],
})
export class DispatchComponent implements OnInit {
  routePath: MenuItem[];
  leftBookingsSectionVisible: boolean = false;
  rightDriversSectionVisible: boolean = false;
  leftDialogPosition: string = "center";
  rightDialogPosition: string = "center";
  dummyNote: string = "Hello There";
  showBookingDetails: boolean = false;
  bookingDetailItem: any = {};
  bookingId: string;
  bookingDetailsById: any[] = [];
  bookingHeader: string;
  position: string;
  miIcons = AppIcons;
  TRANSPORTATION_TYPE = AppSettings.TRANSPORTATION_TYPE;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;
  geoLocationsData: any = {};
  dispatchBookingDetails;

  constructor(private cs: CommonBindingDataService, private cd: ChangeDetectorRef,
    private router: Router,
    private entityService: EntityService, private route: ActivatedRoute, private configService: ConfigService
  ) { }

  ngOnInit() {
    this.setRoutePath();
    this.bookingHeader = this.cs.getLabel("dispatch.bookings");
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel("dispatch.dispatch"),
        routerLink: "",
        icon: "pi pi-arrow-left",
        iconStyle: { "font-weight": "bold", "margin-right": "10px" },
      },
    ];
  }

  showLeftBookingsDialog(position: string) {
    this.leftDialogPosition = position;
    this.position = position;
    this.leftBookingsSectionVisible = false;
    this.cd.detectChanges();
    this.leftBookingsSectionVisible = true;
  }
  showRightDriversDialog(position: string) {
    this.rightDialogPosition = position;
    this.position = position;
    this.rightDriversSectionVisible = true;
  }

  onShowBookingDetail(event) {
    this.showBookingDetails = event.hasData;
    this.bookingDetailItem = event.eventData;
    this.bookingId = event.eventData.id;
    this.getBookingDetail();
  }

  getBookingDetail() {
    this.entityService.getEntity(this.bookingDetailItem.id, AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((result: any) => {
      const details = {};
      result.attributeCodeValueDtoList.map(data => {
        details[data.attributeCode] = data.attributeValue;
      })
      this.dispatchBookingDetails = details;
      this.bookingDetailsById.splice(0, this.bookingDetailsById.length);
      this.bookingDetailsById.push(details);
      const booking = this.bookingDetailsById[0];
      if (booking) {
        this.geoLocationsData = this.cs.getGeoLocations(booking);
      }
      this.router.navigate(['app/dispatch', this.bookingDetailItem.id]);
    })
  }

  onCloseBookingDetailView(event) {
    this.showBookingDetails = event;
    this.bookingDetailItem.id = null;
    this.router.navigate(['app/dispatch']);
  }
}
