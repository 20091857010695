import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsService } from 'app/modules/settings/settings.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { TabViewModule } from 'primeng/tabview';

export interface RoleLoginOptionInfo {
  roleLoginOptionId: string;
  tenantId: string;
  countryId: string;
  loginOptionId: string;
  roleCode: string;
  defaultPassword: string;
  metaUrl: null;
  appConfiguration: null;
}



@Component({
  selector: 'app-settings-user',
  standalone: true,
  imports: [ButtonModule, InputTextModule, TabViewModule, TranslateModule, ReactiveFormsModule, PasswordModule, FormsModule, InputTextModule],
  templateUrl: './settings-user.component.html',
  styleUrl: './settings-user.component.scss'
})
export class SettingsUserComponent {
  addBtnLabel: string;
  profileForm: FormGroup;
  userData: RoleLoginOptionInfo | null = null;

  constructor(private cs: CommonBindingDataService, private formBuilder: FormBuilder, private settingService: SettingsService,
    private messageService: MessageService,
    private configService: ConfigService,
  ) {
    this.addBtnLabel = this.cs.getLabel('label_save');
  }

  ngOnInit(): void {
    this.initializeForm();
  }
  private initializeForm(): void {
    this.profileForm = this.formBuilder.group({
      defaultPassword: ['', [Validators.required]]
    });

    this.settingService.getRoleLoginOption(AppSettings.ROLE_CODE.ADMIN_USER).subscribe({
      next: (response: RoleLoginOptionInfo) => {
        this.userData = response;
        this.profileForm.controls.defaultPassword.setValue(this.userData[0].defaultPassword);
      },
      error: (err) => {
        console.error('Error fetching role login option', err);
      }
    });
  }


  onCancel() {
    this.profileForm.reset();
    this.profileForm.controls.defaultPassword.setValue(this.userData[0].defaultPassword);
  }


  onAddUser() {
    const userData = {
      "forTenantCode": this.configService.getLoggedInTenantCode(),
      "roleCode": AppSettings.ROLE_CODE.ADMIN_USER,
      "defaultPassword": this.profileForm.value.defaultPassword,
      "appConfiguration": {
        "appCode": "",
        "configurationCode": "",
        "timeout": ""
      }
    }
    this.settingService.setRoleLoginOption(userData).subscribe({
      next: (response) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.authentication.lbl_password_updated_successfully') });
        this.initializeForm();
      },
      error: (err) => {
        console.error('Error fetching role login option', err);
      }
    });
  }
}
