
<!-- calendar code start -->
<div id="shift-cells" class="scroll">
    <div class="flex">
        <div class="driver-list-column pr-0">
            @if (driverList.length === 0) {
                <div class="first-column first-row first-cell pl-3 pt-2 pb-2 align-content-center">
                    <div class="inline-flex">
                        <p-dropdown [options]="listTypeCopy" [(ngModel)]="selectedListType" (onChange)="onListTypeChange($event)" optionLabel="name" optionValue="value">
                            <ng-template  pTemplate="dropdownicon">
                                <i class="mi-lg caret-down-2"></i>
                            </ng-template>
                        </p-dropdown>
                        <div class="filter-az">
                            <span translate>shift.az</span>
                            <i class="mi-lg mi-filter_2"></i>
                        </div>
                    </div>
                </div> 
            }
            @for(driver of driverList; track driver; let j=$index) {
                @if (j === 0) {
                    <div class="first-column first-row first-cell pl-3 pt-2 pb-2 align-content-center">
                        <div class="inline-flex">
                            <p-dropdown [options]="listTypeCopy" [(ngModel)]="selectedListType" (onChange)="onListTypeChange($event)" optionLabel="name" optionValue="value">
                                <ng-template  pTemplate="dropdownicon">
                                    <i class="mi-lg caret-down-2"></i>
                                </ng-template>
                            </p-dropdown>
                            <div class="filter-az" (click)="sortDrivers()">
                                @if (isAscendingData) {
                                    <span translate>shift.za</span>
                                } @else {
                                    <span translate>shift.az</span>
                                }
                                <i class="mi-lg mi-filter_2"></i>
                            </div>
                        </div>
                    </div>
                }
    
                <div class="col-12 flex td">
                    <div class="column-cells pl-3 align-content-center">
                        <div class="inline-flex">
                            <div class="inline-flex">
                                <app-mi-image-container [miImage]="driver?.driverProfileImage" miHeight="38" miWidth="38"></app-mi-image-container>
                            </div>
                           <div class="block w-13rem ml-2" (mouseover)="op.toggle($event); hoveredDriver = driver"  (mouseout)="op.toggle($event)">
    
                            <div class="flex justify-content-between flex-wrap">
                                <div class="flex align-items-center justify-content-center ">
                                    <span class="align-content-center ellipse driver-name"   (mouseover)="op.toggle($event)"  >{{driver.driverName}}</span>
                                </div>
                                <div class="flex align-items-center justify-content-center ">
                                    <i class="mi-lg mi-endofshift"></i>
                                    <span class="total-week-hours ml-1">{{driver.workingHours / 60}} hrs</span>
                                </div>
                            </div>
                                <div class="inline-flex align-items-end">
                                        <span class="group-name">({{driver.driverGroup}})</span>
                                        <span class="ml-2 mr-2 vertical-line">|</span>
                                        <span class="driver-unique-id">{{driver.uniqueCode}}</span>
                                </div>
                           </div>
                        </div>
    
                    </div>
                </div>
            }
        </div>
    
        <div class=" toolbar-scroll pl-0">
            <!-- day row -->
            <div >
                <ul>
                    @for (day of weekDaysList; track day; let i = $index) {
                        <li class="first-row">
                            <div class="day inline-block pt-2 pb-2">
                                <div class="flex align-items-center justify-content-center flex-wrap">
                                    <div [class]="(day.date | date: 'dd MM yyyy') === (currentDay | date: 'dd MM yyyy') ? 'current-day' : 'day-name'" class="mr-2"> {{ day.dayName}} </div>
                                    <div [class]="(day.date | date: 'dd MM yyyy') === (currentDay | date: 'dd MM yyyy') ? 'current-day-number' : 'day-number'" class="ml2"> {{ day.day}} </div>
                                </div>
                                <div class="flex align-items-center justify-content-center flex-wrap">
                                    <div class="mr-2">
                                        <label class="available-value">{{day.available}}</label>
                                        <label class="available-text capitalize ml-1" translate>shift.available</label> </div>
                                        <div class="ml-2">
                                            <label class="available-value">{{day.dayOff}}</label>
                                            <label class="available-text ml-1" translate>shift.dayOff</label>
                                        </div>
                                </div>
                            </div>
                        </li>
                    }
    
                </ul>
            </div>
    
            <!-- shift cells-->
            @for(driver of driverList; track driver; let m=$index) {
                    <ul>
                        @for (day of weekDaysList; track day; let n = $index) {
                            <li>
                                    <div class="shift-cells align-content-center flex align-items-center">
                                        @for (shift of driver.shift; track shift; let k = $index) {
                                            @if ((shift.startDateStr | date: 'dd MM yyyy') === (day.date | date: 'dd MM yyyy')) {  
                                            @if (shift.isLeave) {
                                                    <div class="block shift-block">
                                                        <div class="day-off ml-3 align-content-center">
                                                            <div class="ml-2 uppercase" translate>shift.dayOff </div>
                                                            <div class="ml-2 font-semibold"> {{shift.leaveReason}}</div>
                                                        </div>
                                                    </div>
                                                } @else if (shift.isShiftPublish) {
                                                    <div [class]="shift.isDoubleShift(shift) ? 'shift-block-for-double-shift' : 'shift-block'" class="block">
                                                        @if (shift.endTimeStr) {
                                                            <div [class]="shift.isDoubleShift(shift) ? 'shift-time-double-shift' : 'shift-time'" class="ml-2 align-content-center pointer" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftDriver = driver">
                                                                <div [class]="shift.isDoubleShift(shift) ? 'shift-name-ellipse-for-double-shift' : 'shift-name-ellipse'" class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                <div [class]="shift.isDoubleShift(shift) ? 'shift-name-ellipse-for-double-shift' : 'shift-name-ellipse'" class="ml-2 font-semibold"> {{shift.startTimeStr }} -  {{shift.endTimeStr }}</div>
                                                            </div>
                                                        } @else {
                                                            <div class="shift-time ml-3 align-content-center pointer" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftDriver = driver" [class]="shift.dateDifference > 1 ? 'line-img' : ''" >
                                                                <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                <div class="ml-2 font-semibold"> <span>{{shift.startDateStr  | date: 'd MMM'}} -  {{shift.endDateStr  | date: 'd MMM'}}</span> <span class="dot ml-2"></span> <span class="ml-2">{{shift.startTimeStr}} -  <span translate>shift.noEndTime</span></span> </div>
                                                            </div>
                                                        }
                                                    </div>
                                                } @else {
                                                    <div [style.width.%]="(80 /shift.checkShift(shift))"  (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftDriver = driver">
                                                        <div [class]="shift.isDoubleShift(shift) ? 'unpublish-shift-for-double-shift' : 'unpublish-shift'" class="ml-2 align-content-center pointer">
                                                            <div [class]="shift.isDoubleShift(shift) ? 'shift-name-ellipse-for-double-shift' : 'shift-name-ellipse'" class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                            <div [class]="shift.isDoubleShift(shift) ? 'shift-name-ellipse-for-double-shift' : 'shift-name-ellipse'" class="ml-2 font-semibold"> {{shift.startTimeStr}} -  {{shift.endTimeStr}}</div>
                                                        </div>
                                                    </div>
                                                }
                                            }
                                        }
    
                                    </div>
                            </li>
                        }
                    </ul>
            }
        </div>
    </div>
    @if (driverList.length === 0) {
        <app-no-result-found></app-no-result-found>
    }
</div>

<!-- calendar code end -->


<!-- Driver overlay panels -->
<p-overlayPanel #op showCloseIcon="true" appendTo="body">
    <ng-template pTemplate="content">
        <div class="grid align-items-end">
            <div class="col-1">
                <app-mi-image-container [miImage]="hoveredDriver?.driverProfileImage" miHeight="38" miWidth="38"></app-mi-image-container>
            </div>
            <div class="col-11 pl-4">
                <div class="flex align-items-center">
                    <label class="driver-overlay-name mr-2">{{hoveredDriver.driverName}}</label> <label class="mr-2 align-items-end">(Driver)</label>
                    <span class="group">
                        <i class="mi-lg mi-driver"></i>
                        <span class="ml-1">
                            {{hoveredDriver.driverGroup}}
                        </span>
                    </span>
                </div>
                <div> <label class="unique-id"> {{hoveredDriver.uniqueCode}} </label>
                </div>
            </div>
        </div>
        <div class="grid mt-2">
            <div class="col-4 label-text" translate>shift.status</div>
            <div class="col-7 label-value capitalize">
                <span [class]="hoveredDriver.status.toLowerCase()"></span>
                <span [class]="hoveredDriver.status.toLowerCase"></span> {{hoveredDriver.status}}
            </div>
        </div>
        <hr class="hr">
        <div class="grid  mt-2">
            <div class="col-4 label-text" translate>shift.gender</div>
            <div class="col-8 label-value">{{hoveredDriver.gender}}</div>
        </div>
        <hr class="hr">
        <div class="grid  mt-2">
            <div class="col-4 label-text" translate>shift.contactNumber</div>
            <div class="col-8 label-value">{{hoveredDriver.mobileNumber}}</div>
        </div>
        <hr class="hr">
        <div class="grid  mt-2">
            <div class="col-4 label-text" translate>shift.email</div>
            <div class="col-8 label-value">{{hoveredDriver.email}}</div>
        </div>
        <hr class="hr">
        <div class="grid  mt-2">
            <div class="col-4 label-text" translate>shift.lbl_vehicle</div>
            <div class="col-8 label-value">-</div>
        </div>
    </ng-template>
</p-overlayPanel>


<!-- unpublish, publish shift overlay panel -->
<p-overlayPanel #shiftOverlay showCloseIcon="true">
    <ng-template pTemplate="content">
        <div class="grid align-items-end">
            <div class="col-1">
                <app-mi-image-container [miImage]="publishedUnpublishedShiftDriver?.driverProfileImage" miHeight="38" miWidth="38"></app-mi-image-container>
            </div>
            <div class="col-11 pl-3">
                <div class="flex align-items-end">
                    <label class="driver-overlay-name mr-2 ellipse-for-shift-popup" [pTooltip]="publishedUnpublishedShiftDriver.driverName" tooltipPosition="top" [tooltipDisabled]="publishedUnpublishedShiftDriver.driverName?.length <= 18" >{{publishedUnpublishedShiftDriver.driverName}}</label> <label class="mr-2 align-items-end">(Driver)</label>
                    <span class="group">
                        <i class="mi-lg mi-scheduledbreak"></i>
                        <span class="ml-1" [pTooltip]="hoveredPublishedUnPublishedShift.shiftName" tooltipPosition="top" [tooltipDisabled]="hoveredPublishedUnPublishedShift.shiftName?.length <= 12"> {{hoveredPublishedUnPublishedShift.shiftName | truncate:12}}</span>
                    </span>
                </div>
                <div> <label class="unique-id"> {{publishedUnpublishedShiftDriver.uniqueCode}}</label> </div>
            </div>

        </div>
        @if (hoveredPublishedUnPublishedShift.endTimeStr) {
            <div class="grid">
                <div class="col-4 label-text">
                    <i class="mi-lg calendar_calendar"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startDateStr  | date: 'dd MMM'}} </span>
                </div>
                <div class="col-8 label-value">
                    <i class="mi-lg mi-endofshift"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startTimeStr  }} -  {{hoveredPublishedUnPublishedShift.endTimeStr}}</span>

                </div>
            </div>
        } @else {
            <div class="grid">
                <div class="col-4 label-text">
                    <i class="mi-lg calendar_calendar"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startDateStr  | date: 'd MMM'}} -  {{hoveredPublishedUnPublishedShift.endDateStr  | date: 'd MMM'}} </span>
                </div>
                <div class="col-8 label-value">
                    <i class="mi-lg mi-endofshift"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startTimeStr}} -  <span translate>shift.noEndTime</span></span>
                </div>
            </div>
        }

        <hr class="hr">
        <div class="grid mt-2">
            <div class="col-4 label-text" translate>
                <i class="mi-lg mi-breaks"></i>
                <label class="ml-1" translate>shift.breaks</label>
            </div>
            <div class="col-6 label-value">
                @for (break of hoveredPublishedUnPublishedShift.shiftBreakList; track break; let n = $index) {
                    <div>
                        <i class="mi-lg mi-endofshift"></i>
                        <span class="ml-1">  {{break.breakStartTime}} -  {{break.breakEndTime}}</span>
                    </div>
                }
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
