<div class="flex flex-row justify-content-between card-container" (click)="onCardClick(driverDetail)">
        <div class="flex">
            <div class="flex flex-column mr-3 justify-content-between">
                <div class="image-container mt-1">
                    <span class="mi-lg mi-booking_user wh-18 inline-block vertical-align-text-bottom passenger-manual-image"></span>
                    <span class="mi-lg mi-stops wh-18 label-icon inline-block vertical-align-text-bottom relative top-9 manual-status-icon"
                        [ngClass]="{ 'bg-on-shift': driverDetail.shiftStatus === 'On Shift', 'bg-off-shift': driverDetail.shiftStatus === 'Off Shift' }"></span>
                </div>
                <div class="child-wheelchair-flag flex justify-content-center align-items-center font-12 font-bold mb-1">
                    @if(driverDetail.wheelChair && driverDetail.child){<ng-container >C|W</ng-container>}
                    @if(driverDetail.wheelChair && !driverDetail.child){<ng-container >W</ng-container>}
                    @if(!driverDetail.wheelChair && driverDetail.child){<ng-container>C</ng-container>}
                </div>
            </div>
          
            <div class="flex flex-column">        
              <div class="flex justify-content-start align-items-center">
                
                <span class="font-14 font-semibold default-text-black-color mr-2">{{driverDetail.values.first_name}} {{driverDetail.values.last_name}}</span>
                <span class="font-14 font-medium default-text-black-color mr-2">{{driverDetail.values.unique_id}}</span>
                <span class="font-16 font-semibold default-text-light-gray mr-2">|</span>
                <span class="font-14 font-medium default-text-black-color mr-2">{{driverDetail.relatedData[1].values.name_code}}</span>
                
              </div>
              <div class="flex justify-content-start align-items-center">
                <span class="font-14 font-semibold default-text-black-color mr-2">{{driverDetail.relatedData[0].values.group_color}}</span>
                <span class="font-13 font-medium default-text-black-color mr-2">{{driverDetail.relatedData[1].values.manufacturer}} {{driverDetail.relatedData[1].values.model}}</span>
                <span class="font-13 font-normal default-print-label-color"> ({{driverDetail.relatedData[1].values.vehicle_type}} - {{driverDetail.relatedData[1].values.passenger_capacity}})</span>
              </div>
            </div>
        </div>
    
      
         @if(isBookingScreen){
            <ng-container>
                <div class="flex align-items-center">
                  <p-button [label]="'Assign'" class="assign-btn" (click)="onAssignClick(driverDetail, $event)"></p-button>
                </div>
              </ng-container>
         }
      
         @if(forDetail){
            <ng-container>
                <div (click)="onCloseDetailView()" class="cancel-btn">
                  <i class="mi-lg mi-off_close mt-4 inline-block cursor-pointer wh-24"></i>
                </div>
              </ng-container>
         }
    
         <div class="card flex light-blue-container">
                <div class="font-16 font-bold default-text-black-color mr-2">3 Mins |</div>
                <div class="font-14 font-semibold default-print-label-color passenger-booking-details">(4.87 Miles)</div>
                <div class="shift-toggle font-14 font-semibold flex align-items-center" [ngClass]="{ 'on-shift': driverDetail.shiftStatus === 'On Shift', 'off-shift': driverDetail.shiftStatus === 'Off Shift' }">{{ driverDetail.shiftStatus }}</div>
        </div>
  </div>