import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { AppSettings } from '../../../shared/app.settings';
import { ButtonModule } from 'primeng/button';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-edit-break',
  standalone: true,
  imports: [TranslateModule, DropdownModule, CalendarModule, ButtonModule, FormsModule, ReactiveFormsModule, DatePipe],
  templateUrl: './add-edit-break.component.html',
  styleUrl: './add-edit-break.component.scss',
  providers: [DatePipe]
})
export class AddEditBreakComponent {
  @Input() selectedShiftType: any;
  @Input() form: FormGroup;
  @Input() shiftTemplateBreakList = [];
  @Input() isEdit = false;
  breakReasonActionBtnLabel: any;
  shiftTemplateSlots: any = [];
  breakCount: any;
  SHIFT_TYPE = AppSettings.SHIFT_TYPE;
  btnIcon: any;
  showBreakReasonDropDown: boolean = false;
  cities = [];
  hourFormat = AppSettings.HOUR_FORMAT;
  selectedCity;
  breakReasons = AppSettings.BREAK_REASONS;
  number: any = Number;
  constructor(
    public cs: CommonBindingDataService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.btnIcon = 'pi pi-plus';
    this.breakReasonActionBtnLabel = this.cs.getLabel('shift.lbl_add_reason');
    this.initializeForm();
    this.shiftTemplateSlots = [
      {
        label: 'l1'
      }
    ];
  }

  initializeForm() {
    this.breakFields.clear();
    if (this.isEdit) {
      this.breakCount = this.shiftTemplateBreakList.length;
      this.shiftBreakList();
    } else {
      this.breakCount = this.breakFields.length;
    }

  }

  shiftBreakList() {
    this.shiftTemplateBreakList.forEach(element => {
      const reason = { label: element.reason, value: element.reason };
      this.breakFields.push(
        this.formBuilder.group({
          reasonForBreak: [reason],
          breakStartTime: [element.breakStartTime],
          breakEndTime: [element.breakEndTime],
          shiftBreakId: [element.shiftBreakId],
          shiftTemplateBreakId: [element.shiftTemplateBreakId]
        })
      );
    });
  }

  addMoreSlots() {
    this.breakFields.push(
      this.formBuilder.group({
        reasonForBreak: [],
        breakStartTime: [],
        breakEndTime: []
      })
    );
    this.breakCount = this.breakFields.length;
  }

  removeBreak(index) {
    this.breakFields.removeAt(index);
  }

  addEditReason(event) {
    this.showBreakReasonDropDown = !this.showBreakReasonDropDown;
    this.breakReasonActionBtnLabel = this.cs.getLabel('shift.lbl_edit_reason');
  }

  get breakFields() {
    return this.selectedShiftType === AppSettings.SHIFT_TYPE.MANUALLY ? this.form.get('breakFields') as FormArray : null;
  }
}
