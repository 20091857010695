import { JsonPipe, NgStyle } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DispatchMapComponent } from 'app/modules/dispatch/components/dispatch-map/dispatch-map.component';
import * as _ from 'lodash';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppIcons } from '../../../../shared/app.icons';
import { AppSettings } from '../../../../shared/app.settings';
import { StatusComponent } from '../../../../shared/components/status/status.component';
import { Country } from '../../../../shared/models/country';
import { Status } from '../../../../shared/models/status';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../../shared/services/config.service';
import { EntityService } from '../../../../shared/services/entity.service';
import { SpecificationComponent } from '../../../../vehicles/components/vehicle-details/specification/specification.component';
import type { AttributeData } from '../../../../vehicles/models/attribute.models';
import type { StatusData } from '../../../models/booking.models';
import { BookingService } from '../../../services/booking.service';
import { BookingStatusComponent } from '../../booking-status/booking-status.component';
import { ActionButtonsComponent } from '../../ui-components/action-buttons/action-buttons.component';
import { BookingHeaderComponent } from '../booking-header/booking-header.component';
import { BookingInfoComponent } from '../booking-info/booking-info.component';

@Component({
  selector: 'app-booking-details',
  standalone: true,
  imports: [
    BreadcrumbModule,
    StatusComponent,
    TabViewModule,
    TranslateModule,
    MenuModule,
    ToastModule,
    BookingInfoComponent,
    ButtonModule,
    SpecificationComponent,
    BookingHeaderComponent,
    ActionButtonsComponent,
    JsonPipe,
    NgStyle,
    BookingStatusComponent,
    DispatchMapComponent
  ],
  templateUrl: './booking-details.component.html',
  styleUrl: './booking-details.component.scss'
})
export class BookingDetailsComponent {
  routePath: MenuItem[] = [];
  bookingId: string;
  dataForStatus: Array<Status> = [];
  userId: any = '232323';
  country: Country;
  activeIndex: any = 0;
  data: AttributeData;
  driverAttributeData: any;
  vehicleAttributeData: any;
  bookingAttributeData: any;
  attributeLabels: any;
  vehicleAttributeLength;
  bookingAttributeLength
  attributeValues: any;
  imgFileId: any;
  showStatus: boolean = false;
  bookingDetails: any;
  entityForStatus: any;
  deleteBtnLabel: string;
  reAssign: string;
  relatedData: any;
  vehicleRelatedData: any;
  passengerRelatedData: any;
  driverRelatedData: any;
  isStatusDialogShow: boolean = false;
  bookingStatus: any;
  statusData: StatusData;
  BOOKING_STATUS_LABEL = AppSettings.BOOKING.STATUS_BY_ID;
  miIcons = AppIcons;
  isReady = false;
  bookingStyle: any;
  geoLocationsData: any = {};

  constructor(private route: ActivatedRoute,
    public cs: CommonBindingDataService,
    private configService: ConfigService,
    private entityService: EntityService,
    private bookingService: BookingService,
    private router: Router) {

  }

  ngOnInit() {
    this.setLabels();
    this.initData();
    this.setRoutePath();
  }


  setLabels() {
    this.bookingId = this.route.snapshot.paramMap.get('id');
    this.deleteBtnLabel = this.cs.getLabel('lbl_delete');
    this.reAssign = this.cs.getLabel('bookings.reassign');
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('bookings.header'),
        routerLink: '../current-bookings',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: this.cs.getLabel('bookings.details'),
        routerLink: '../' + this.bookingId,
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  initData() {
    const apiCalls = [];
    apiCalls.push(this.getBookingDetails());
    apiCalls.push(this.getAttributeData());
    apiCalls.push(this.getDriverData());
    apiCalls.push(this.setBookingStatus());

    forkJoin(apiCalls).subscribe(() => {
      this.isReady = true;
      this.bookingStyle = this.getStatusStyle(this.attributeValues['booking_status']);

    });
  }



  getAttributeData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    return this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING,  AppSettings.VIEW_CODE.DETAIL_VIEW).pipe(
      tap(res => {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.bookingAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.bookingAttributeLength = this.bookingAttributeData.tabs.length;
        this.driverAttributeData = _.find(res.relatedAttributes, { entityCode: 'driver' });


      }));
  }

  getDriverData() {
    return this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING,  AppSettings.VIEW_CODE.DRIVER_DETAILS_ON_BOOKING_VIEW).pipe(
      tap(() => {

      }));
  }

  getBookingDetails() {
    return this.entityService.getEntity(this.bookingId, AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.DETAIL_VIEW).pipe(
      tap((result: any) => {
        this.bookingDetails = result;
        this.attributeValues = this.cs.getOrgAttributeValues(result);

        this.relatedData = _.groupBy(result?.relatedData, 'entityCode');

        this.vehicleRelatedData = this.formattedArray(this.relatedData?.vehicle || []);
        this.passengerRelatedData = this.formattedArray(this.relatedData?.booking_pass || []);
        this.driverRelatedData = this.relatedData?.driver ? this.formattedArray(this.relatedData?.driver || []) : [];
        this.showStatus = true;
        this.passengerRelatedData.forEach(rowData => {
          if (rowData?.passenger_profile_image && (rowData?.passenger_profile_image[0])) {
            this.getFile(rowData)
          }
        });
        this.geoLocationsData = this.cs.getGeoLocations(this.attributeValues);
      }))
  }


  getFile(passenger) {

    if (passenger?.passenger_profile_image) {
      this.entityService.getFile(passenger.passenger_profile_image[0], AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = function() {
          const base64data = reader.result;
          passenger.passenger_profile_image = base64data;
        }
      })
    } else {
      return AppSettings.DEFAULT_LOADER_IMAGE;
    }
  }

  formattedArray(originalArray) {
    return originalArray.map(item => {
      const newObj = { entityId: item.entityId };
      item.attributeCodeValueDtoList.forEach(attr => {
        newObj[attr.attributeCode] = attr.attributeValue;
      });
      return newObj;
    });

  }

  showStatusDialog(event: MouseEvent, rowData) {
    event.stopPropagation();
    event.preventDefault();
    this.isStatusDialogShow = true;
    this.statusData = {
      bookingId: this.bookingId,
      currentStatus: rowData.booking_status || 'NEW_REQUEST',
      bookingStatusList: this.bookingStatus || []
    }
  }



  setBookingStatus() {
    return this.bookingService.getBookingStatus().pipe(
      tap((res) => {
        this.bookingStatus = res;
      }))
  }



  getStatusStyle(status): object {
    const selectedBookingStatus = this.findStatusObj(status) || { backgroundColorCode: "#ddeeff", textColorCode: "#1e90ff" };
    return { 'background-color': selectedBookingStatus?.backgroundColorCode || "#ddeeff", 'color': selectedBookingStatus?.textColorCode || "#1e90ff" };
  }



  private findStatusObj(selectedStatus) {
    return this.bookingStatus.find(ele => ele.bookingCode === selectedStatus);
  }

  onReplace() {
    this.router.navigate(['/app/bookings/edit/' + this.bookingId]);
  }

  onUpdatedStatus() {
    this.initData();
  }

  onHideStatusDialog() {

  }

}
