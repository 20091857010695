<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white default-text-light-black-color cursor-pointer border-button"
        [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button class="flex align-items-center justify-content-center cursor-pointer"
        [label]="bookingBtnLabel | translate" (click)="onNextBtnClick()"></p-button>
</div>
<p-tabView [(activeIndex)]="activeIndex">
    @for (tab of bookingAttributeData?.tabs; track tab; let i = $index) {
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
                <i class="mi-lg" [class]="tab.tabCode"></i>
                <span>{{ cs.getLabelValue('booking.tabs.'+tab.tabCode+'.label',attributeLabels,tab.tabCode) }}</span>
            </div>
        </ng-template>
        <div class="grid m-0 p-0">
            <div class="col col-7 p-0 justify-content-center">
                <div class="booking-container ">
                    <app-booking-form
                        [attributeLabels]="attributeLabels"
                        [attributeValues]="attributeValues"
                        [moduleName]="moduleName"
                        [groups]="tab.groups"
                        [petGroup]="bookingPetInfo"
                        [isEdit]="isEdit"
                        (saveData)="onSaveBooking($event)"
                        [submitBtnText]="bookingBtnLabel" 
                        [saveBtnId]="'saveBookingId'"></app-booking-form>
                </div>
            </div>
            <div class="col col-5 p-0 tab-group">
                <div class="passenger-container">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 align-content-end md:col-12">
                            <app-select-passengers [bookingPass]="bookingPass" [bookingBookingPass]="bookingBookingPass" [addedPassengerList]="addedPassengerList"
                                [passengerView]="passengerView"
                                (passengerList)="onPassengerList($event)"></app-select-passengers>
                        </div>
                        <div class="field col-12 align-content-end md:col-12">
                            @if (passengerAddressVisible){
                                <app-booking-passenger-address (onToggleOverlay)="handleOverlayToggle()" [passengerAddressVisible]="passengerAddressVisible" [passengerId]="passengerId"></app-booking-passenger-address>
                            }
                            <p-button [label]="'lbl_add_address' | translate" class="float-r default-text-blue-color font-14 cursor-pointer font-semibold p-1 mb-2 add-new" [outlined]="true"
                                icon="pi pi-plus-circle" (click)="passengerSidebarOpen()"></p-button>
                        </div>
                    </div>
                </div>
            </div>
   
        </div>



    </p-tabPanel>
    }
</p-tabView>
<router-outlet></router-outlet>