import { ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MiFormComponent } from 'app/modules/shared/components/mi-form/mi-form.component';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import * as _ from 'lodash';

@Component({
  selector: 'app-settings-general-settings',
  standalone: true,
  imports: [InputTextModule, FormsModule, ReactiveFormsModule, TranslateModule, ButtonModule, MiFormComponent, TabViewModule],
  templateUrl: './settings-general-settings.component.html',
  styleUrl: './settings-general-settings.component.scss'
})
export class SettingsGeneralSettingsComponent {
  addressEntityIdList = [];
  defaultLocationList = AppSettings.DEFAULT_ADDRESS_LIST_FOR_ORGANIZATION;

  attributeLabels;
  attributeValues;
  generalAttributeData;
  moduleName: string;
  country: Country;
  language: Language;
  generalSettingsModuleName = AppSettings.ENTITY_TYPE.GENERAL_SETTINGS;
  generalFormGroup: FormGroup;

  previousBtnLabel: any = this.cs.getLabel('lbl_cancel');
  saveGeneralSettingsBtnLabel: any = this.cs.getLabel('lbl_save');
  generalSettingsData: any;
  generalSettingsEntityData: any;
  generalSettingEntityId: any;
  addressData: any[] = [];
  addressFields = 9;
  startIndexToPickOneRecordFromAddressArray: any;
  endIndexToPickOneRecordFromAddressArray: any;
  attributeIdForAddress: any;
  attributeIdForAddressInGeneralSettings: any;
  activeIndex: number = 0;
  relationshipDataForAddress: any[] = [];
  timeZones: any[] = [];
  entityId: any;
  generalSettingDetails: any;
  showForm: boolean = false;
  userId: any;
  userDetails: any;
  attributeIdForLocationGeoLocation: any;

  constructor(
    public cs: CommonBindingDataService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private configService: ConfigService,
    private messageService: MessageService,
    private entityService: EntityService) { }


  ngOnInit() {
    this.getGeneralSettings();
    this.getTimeZones();
    this.setAppSettings();
    setTimeout(() => {
      this.getAttributesForGeneralSetting();
    }, 500);

    this.showForm = true;
    this.setGeneralSettingsEntityData();
    const profileData = JSON.parse(localStorage.getItem(AppSettings.PROFILE_DATA));
    this.userId = profileData.userId;
    if (this.userId) {
      this.getUserDetails(this.userId);
    }
  }

  getUserDetails(userId) {
    this.entityService.getUserDetails(userId, this.configService.getForTenantCode()).subscribe(result => {
      this.userDetails = result;
    })
  }

  getGeneralSettings() {
    this.entityService.getEntityDetailsByView(AppSettings.ENTITY_TYPE.GENERAL_SETTINGS, {}).subscribe({
      next: (response: any) => {
        this.entityId = response.entityId;
        if (this.entityId) {
          this.getEntityDetails();
        }
      },
      error: (error) => {
        this.showForm = true;
      }
    })
  }

  getEntityDetails() {
    this.entityService.getEntity(this.entityId, AppSettings.ENTITY_TYPE.GENERAL_SETTINGS, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe({
      next: (response: any) => {
        this.generalSettingDetails = response;
        this.generalSettingEntityId = response.entityId;
        this.attributeValues = this.cs.getOrgAttributeValues(response);
        const addressIdArray = this.generalSettingDetails.attributeCodeValueDtoList.find(ele => ele.attributeCode === AppSettings.FIELDS.ADDRESS_SECTION);
        this.addressEntityIdList = (addressIdArray && addressIdArray.attributeValue.length > 0) ? addressIdArray.attributeValue : [];
        this.getAddress();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    })
  }

  getAddress() {
    if (this.addressEntityIdList.length > 0) {
      this.defaultLocationList = [];
      const that = this;
      this.addressEntityIdList.forEach(function(entityId, i) {
        that.entityService.getEntity(entityId, AppSettings.ENTITY_TYPE.GENERAL_SETTING_ADDRESS, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((res: any) => {
          that.setAttributeValuesForAddressEditMode(res, i);
          const field = res.attributeCodeValueDtoList.find(element => element.attributeCode === AppSettings.FIELDS.SELECT_LOCATION_TYPE);
          const label = field ? field.attributeValue : '';
          that.defaultLocationList[i] = { labelKey: label, labelValue: label, entityId: that.addressEntityIdList[i], index: i };
          if (i === that.addressEntityIdList.length - 1) {
            that.showForm = true;
            console.log(that.defaultLocationList);
          }
        });
      });
    }
  }

  setAttributeValuesForAddressEditMode(data, addressCount) {
    for (const labelsObj of data.attributeCodeValueDtoList) {
      this.attributeValues[addressCount + '_' + labelsObj.attributeCode] = labelsObj.attributeValue;
    }
  }

  getTimeZones() {
    this.entityService.getTimeZones().subscribe({
      next: (result: any) => {
        result.forEach(element => {
          this.timeZones.push({ labelKey: element.gmtOffset, labelValue: element.timezone })
        });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    })
  }

  onCancel() {

  }

  onNextBtnClick() {
    const btn = document.getElementById('general');
    btn.click();
  }

  private setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  getAttributesForGeneralSetting() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_TYPE.GENERAL_SETTINGS,  AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.attributeLabels = this.cs.getAttributeLabels(res);
        this.generalAttributeData = this.cs.getOrganizedAttribute(res);
       
        let tab = this.generalAttributeData.tabs.find(ele => ele.tabCode === 'general_settings');
        let group = tab.groups.find(ele => ele.code === 'address');
        let index = group.fields.findIndex(ele => ele.attributeCode === 'select_location_address');
        this.attributeIdForLocationGeoLocation = group.fields.find(ele => ele.attributeCode === 'select_location_geolocation')?.attributeId;
        this.attributeIdForAddress = group.fields.find(ele => ele.attributeCode === 'select_location_address')?.attributeId;
        this.attributeIdForAddressInGeneralSettings = this.getAttributeIdForGroupCode('address');
        group.fields.splice(index, 1);
        const regionalSettingGroup = tab.groups.find(ele => ele.code === 'regional_settings');
        const timeZoneField = regionalSettingGroup.fields.find(ele => ele.attributeCode === 'time_zone');
        timeZoneField.presetValues = this.timeZones;
      }
    });
  }

  getAttributeIdForGroupCode(groupCode: string): string | undefined {
    const group = _.flatMap(this.generalAttributeData.tabs, 'groups').find(group => group.code === groupCode);
    return group ? group.relation.ownerAttributeId : undefined;
  }

  setGeneralSettingsEntityData() {
    this.generalSettingsEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.GENERAL_SETTINGS
    }
  }


  setGeneralSettingData(key, attributeValue) {
    if (!AppSettings.SPACERS_ATTRIBUTE_CODES_FOR_PASSENGER_DETAILS.includes(key)) {
      if (attributeValue) {
        this.generalSettingsData.data.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
  }

  setAttributeValuesForAddress(key, attributeValue) {
    const keyId = key.substring(key.indexOf('_') + 1);
    if (!AppSettings.SPACERS_ATTRIBUTE_CODES_FOR_STAY_HOME_ADDRESS.includes(keyId)) {
      if (keyId === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_GEOLOCATION) {
        this.setAddress(keyId, attributeValue?.geoLocation);
        this.addressData.push({
          attributeId: this.attributeIdForAddress,
          attributeValue: attributeValue?.address
        });
      } else {
        this.setAddress(keyId, attributeValue);
      }
    }
  }

  setAddress(keyId, attributeValue) {
    this.addressData.push({
      attributeCode: keyId,
      attributeValue: attributeValue
    });
  }

  saveGeneralSettings(generalSettingsFormData) {
    this.generalSettingsData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.GENERAL_SETTINGS,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: []
    };

    for (let k = 0; k < this.defaultLocationList.length; k++) {
      generalSettingsFormData[`${k}_${AppSettings.ADDRESS_FIELDS.SELECT_LOCATION_TYPE}`] = this.defaultLocationList[k].labelKey
    }

    for (const [key, value] of Object.entries(generalSettingsFormData)) {
      const attributeValue = value;
      const arr = key.split('_');
      if (parseInt(arr[0]) >= 0) {
        this.setAttributeValuesForAddress(key, attributeValue);
      } else {
        this.setGeneralSettingData(key, attributeValue);
      }
    }
    this.addressData = this.cs.mapAttributeIdsForRelatedData(this.addressData, this.generalAttributeData.tabs);
    this.generalSettingsData.data = this.cs.mapAttributeIds(this.generalSettingsData.data, this.generalAttributeData.tabs);
    this.saveAddress();
  }

  saveAddress() {
    const numberOfAddressRecords = this.addressData.length / this.addressFields;
    const addressEntitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.GENERAL_SETTING_ADDRESS
    };

    for (let i = 0; i < numberOfAddressRecords; i++) {
      const address = this.createAddressObject(i);

      if (this.addressEntityIdList.length > 0) {
        this.saveAddressAttributeIfExist(numberOfAddressRecords, i, address, addressEntitiesData);
      } else {
        this.createAndSaveAddressEntityIfNotExist(addressEntitiesData, i, numberOfAddressRecords, address);
      }
    }
  }

  private createAddressObject(index: number) {
    const start = index * this.addressFields;
    const end = start + this.addressFields;
    return {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.GENERAL_SETTING_ADDRESS,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: this.addressData.slice(start, end)
    };
  }

  private saveAddressAttributeIfExist(numberOfAddressRecords: number, index: number, address: any, addressEntitiesData: any) {
    this.saveAddressAttribute(numberOfAddressRecords, index, address, this.addressEntityIdList[index], addressEntitiesData);
  }

  private createAndSaveAddressEntityIfNotExist(addressEntitiesData: any, index: number, numberOfAddressRecords: number, address: any) {
    this.createAndSaveAddressEntity(addressEntitiesData, index, numberOfAddressRecords, address);
  }


  saveAddressAttribute(numberOfAddress, index, address, entityId, addressEntitiesData) {
    if (entityId) {
      this.entityService.saveAttributeData(addressEntitiesData.entityCode, entityId, address).subscribe((res: any) => {
        this.relationshipDataForAddress.push(
          {
            entityRelationshipConfigId: this.generalAttributeData.tabs[0].groups.find(ele => ele.code === 'address').relation.entityRelationshipConfigId,
            otherEntityId: entityId
          }
        );
        if (index === (numberOfAddress - 1)) {
          this.generalSettingsData.data.push({
            attributeId: this.attributeIdForAddress,
            attributeValue: this.addressEntityIdList
          });
          this.saveGeneralSettingsToDb(this.generalSettingsData.entityCode)
        }
      });
    } else {
      this.createAndSaveAddressEntity(addressEntitiesData, index, numberOfAddress, address);
    }

  }

  createAndSaveAddressEntity(addressEntitiesData, index, numberOfAddress, address) {
    this.entityService.createEntities(addressEntitiesData.entityCode, addressEntitiesData).subscribe({
      next: (res: any) => {
        const entityId = res.entityId;
        this.addressEntityIdList.push(res.entityId);
        this.relationshipDataForAddress.push(
          {
            entityRelationshipConfigId: this.generalAttributeData.tabs[0].groups.find(ele => ele.code === 'address').relation.entityRelationshipConfigId,
            otherEntityId: entityId
          }
        );
        if (index === (numberOfAddress - 1)) {
          this.generalSettingsData.data.push({
            attributeId: this.attributeIdForAddressInGeneralSettings,
            attributeValue: this.addressEntityIdList
          });
          this.saveGeneralSettingsToDb(this.generalSettingsEntityData.entityCode)
        }
        this.entityService.saveAttributeData(addressEntitiesData.entityCode, entityId, address).subscribe((res: any) => {
        });
      },
      error: (error) => {
        console.error('something wrong occurred: ' + error);
      }
    });
  }

  saveGeneralSettingsToDb(entityCode) {
    this.generalSettingsData.data = this.cs.mapAttributeIds(this.generalSettingsData.data, this.generalAttributeData.tabs);
    this.generalSettingsData.relationshipData = this.relationshipDataForAddress;
    if (this.generalSettingEntityId) {
      this.saveGeneralSettingsApiCall(entityCode);
    } else {
      this.createGeneralSettingsEntity();
    }
  }

  createGeneralSettingsEntity() {
    this.entityService.createEntities(this.generalSettingsEntityData.entityCode, this.generalSettingsEntityData).subscribe({
      next: (res: any) => {
        this.generalSettingEntityId = res.entityId;
        this.saveGeneralSettingsApiCall(this.generalSettingsEntityData.entityCode);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  saveGeneralSettingsApiCall(entityCode) {
    this.entityService.saveAttributeData(entityCode, this.generalSettingEntityId, this.generalSettingsData).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.lbl_general_settings_saved_successfully') });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }
}
