<p-sidebar
[(visible)]="visible"
[position]="rightDialogPosition"
>
  <ng-template pTemplate="header">
    @if(!showDriverDetails){
      <app-dialog-header class="flex-grow-1" [headerLabel]="assignDriverHeader" [position]="position" [moduleName]="'driver'" [view]="'DRIVER_FILTER_ON_DISPATCH_VIEW'" 
        (onValueChange)="onDriverFilterValueChange($event)">
      </app-dialog-header>
    }
    @if(showDriverDetails){
      <app-dialog-header class="flex-grow-1 p-0 -ml-3 -mr-3 -mt-3" [hideSearchFilters]="true" [forDetail]="true" [driverDetail]="driverDetailItem" (closeBtnClicked)="onCloseDriverDetailView($event)"
       [headerLabel]="assignDriverHeader"></app-dialog-header>
    }
  </ng-template>
  <ng-template pTemplate="content">
    @if(!showDriverDetails){<app-assign-driver (showDriversDetails)="onShowDriverDetail($event)" [bookingId]="bookingId"></app-assign-driver>}
    @if(showDriverDetails){<app-driver-details [driverDetailItem]="driverDetailItem"></app-driver-details>}
  </ng-template>
</p-sidebar>