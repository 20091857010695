import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { MiErrorComponent } from '../../../shared/components/mi-error/mi-error.component';
import { MiFieldsComponent } from '../../../shared/components/mi-fields/mi-fields.component';
import { MiTooltipComponent } from '../../../shared/components/mi-fields/mi-tooltip/mi-tooltip.component';
import { SelectButtonWithAddNewComponent } from '../../../shared/components/mi-fields/select-button-with-add-new/select-button-with-add-new.component';
import * as _ from 'lodash';

import { GeoPointComponent } from '../../../shared/components/mi-fields/geo-point/geo-point.component';
import { AppSettings } from '../../../shared/app.settings';
import { NgClass } from '@angular/common';
import { GeoTransportComponent } from 'app/modules/shared/components/mi-fields/geo-transport/geo-transport.component';

@Component({
  selector: 'app-stay-home-address',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MiFieldsComponent,
    MiErrorComponent,
    ButtonModule,
    MiTooltipComponent,
    TranslateModule,
    SelectButtonWithAddNewComponent,
    GeoPointComponent,
    NgClass,
    GeoTransportComponent,
],
  templateUrl: './stay-home-address.component.html',
  styleUrl: './stay-home-address.component.scss'
})
export class StayHomeAddressComponent {
  @Input() group: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() miFormGroup: FormGroup;
  @Input() attributeValues;
  @Input() showImg: boolean = false;
  @Input() addressEntityIdList: any = [];
  @Input() defaultLocationList: any = [];
  @Input() isShowBookingMap: boolean;

  @Output() formGroupEmit: EventEmitter<any> = new EventEmitter<any>();

  selectedAddress;
  formGroupFields = [];
  addressFields: any = [];
  locationType = [];
  showForm: boolean = true;
  fieldsCopy: any;
  lastSelectedButton: any;
  pKeyFilter: any = 'alphanum';
  currentSelectedCode: any;

  constructor(
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.fieldsCopy = _.cloneDeep(this.group.fields);
    this.moduleName = this.moduleName === AppSettings.ENTITY_CODE.PASSENGERS ? AppSettings.ENTITY_CODE.PASSENGER_ADDRESS : this.moduleName === AppSettings.ENTITY_TYPE.GENERAL_SETTINGS ? AppSettings.ENTITY_TYPE.GENERAL_SETTING_ADDRESS : this.moduleName;
    this.selectedAddress = this.defaultLocationList[0]?.labelKey;
    this.setFormFields();
    this.formGroupEmit.emit(this.miFormGroup);
  }

  setFormFields() {
    this.group.fields = [];
    for (let index = 0; index < this.defaultLocationList.length; index++) {
      const fields = _.cloneDeep(this.fieldsCopy);
      for (const field of fields) {
        this.currentSelectedCode = field.inputCode;
        const validators = this.addValidator(field.validation);
        field.attributeCodeOneToMultiple = index + '_' + field.attributeCode;
        field.isOneToMultiple = true;
        field.fieldName = field.attributeCode;
        field.locationType = this.defaultLocationList[index].labelKey;
        const value = this.attributeValues ? this.attributeValues[field.attributeCodeOneToMultiple] : '';
        if (this.attributeValues && this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_GEOLOCATION}`]) {
          this.setPresetValuesForSelectLocationField(field, index, validators);
        }
        this.miFormGroup.registerControl(field.attributeCodeOneToMultiple, new FormControl(value, validators));
        this.group.fields.push(field);
      }
    }
  }

  setPresetValuesForSelectLocationField(field, index, validators) {
    if (field.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_GEOLOCATION) {
      if (field.presetValues) {
        const element = field.presetValues.find(ele => ele.labelKey === this.attributeValues[field?.attributeCodeOneToMultiple]);
        if (element === undefined) {
          const address = {
            labelKey: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS}`],
            labelValue: {
              address: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS}`],
              geoLocation: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_GEOLOCATION}`]
            }

          }
          field.presetValues.push(address);
          this.miFormGroup.registerControl(field.attributeCodeOneToMultiple, new FormControl(address.labelValue, validators));
        }

      } else {
        field.presetValues = [];
        const element = field.presetValues.find(ele => ele.labelKey === this.attributeValues[field?.attributeCodeOneToMultiple]);
        if (element === undefined) {
          const address = {
            labelKey: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS}`],
            labelValue: {
              address: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS}`],
              geoLocation: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_GEOLOCATION}`]
            }

          }
          field.presetValues.push(address);
          this.miFormGroup.registerControl(field.attributeCodeOneToMultiple, new FormControl(address.labelValue, validators));
        }
      }
    }
  }

  onNewAddressAdd(event) {
    const newAddressGroupFields = _.cloneDeep(this.fieldsCopy);
    for (const field of newAddressGroupFields) {
      this.currentSelectedCode = field.inputCode;
      const validators = this.addValidator(field.validation);
      field.attributeCodeOneToMultiple = (this.defaultLocationList.length - 1) + '_' + field.attributeCode;
      field.isOneToMultiple = true;
      field.fieldName = field.attributeCode;
      field.locationType = event;
      const value = this.attributeValues ? this.attributeValues[field?.attributeCodeOneToMultiple] : '';
      this.miFormGroup.registerControl(field.attributeCodeOneToMultiple, new FormControl(value, validators));
      this.group.fields.push(field);
    }
  }

  onSelectButtonChange(event) {
    this.selectedAddress = event;
  }

  onAddressDelete(event) {
    this.group.fields.forEach(element => {
      if (element.locationType === event) {
        this.miFormGroup.removeControl(element.attributeCode);
      }
    });
    const index = this.defaultLocationList.findIndex(ele => ele.labelValue === event);
    const entityIdToDelete = this.defaultLocationList[index].entityId;
    this.defaultLocationList.splice(index, 1);
    if (entityIdToDelete) {
      const index = this.addressEntityIdList.findIndex(ele => ele === entityIdToDelete);
      this.addressEntityIdList.splice(index, 1);
    }
    this.group.fields = this.group.fields.filter(ele => ele.locationType !== event);
    this.selectedAddress = this.defaultLocationList[0].labelKey;
    this.cd.detectChanges();
  }

  private addValidator(rules) {
    const stringValues = ['inputString', 'text', 'textArea', 'inputMobile'];
    if (!rules) {
      return [];
    }
    const validators = [];
    (Object.keys(rules).map((rule) => {
      const isString = stringValues.includes(this.currentSelectedCode);
      if (rule === "required" && rules.required == true) {
        validators.push(Validators.required);
      }

      if (rule === "min" && rules.min && !isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.min(rules[rule] ? rules[rule] : 10))
        }
      }

      if (rule === "max" && rules.max && !isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.max(rules[rule] ? rules[rule] : 50));
        }
      }

      if (rule === "min" && rules.min && isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.minLength(rules[rule] ? rules[rule] : 10));
        }
      }

      if (rule === "max" && rules.max && isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.maxLength(rules[rule] ? rules[rule] : 50));
        }
      }

      if (rule === "pattern" && rules.pattern) {
        if (rules[rule] !== null) {
          const validatorRule = new RegExp([rules[rule]].join(''));
          validators.push(Validators.pattern(rules[rule] ? validatorRule : ''));
        }

      }
      if (rule === "unique" && rules.unique == true) {
        validators.push(Validators.pattern(''));

      }
    }));

    return validators;
  }
}
