import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupColorComponent } from 'app/modules/manage-groups/components/group-color/group-color.component';
import { ManageZonesService } from 'app/modules/manage-zones/services/manage-zones.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { filter, takeWhile } from 'rxjs/operators';
import { AppSettings } from '../../../app.settings';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { MessageService } from '../../../services/message.service';
import { ListResponse } from 'app/modules/vehicles/models/listResponse.models';
import { ConfigService } from 'app/modules/shared/services/config.service';

@Component({
  selector: 'app-mi-select',
  templateUrl: './mi-select.component.html',
  styleUrls: ['./mi-select.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, MultiSelectModule, GroupColorComponent, CommonModule]
})
export class MiSelectComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('miSelect') miSelect!: MultiSelect;
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() multiSelectDisplay: string = 'comma';
  alive = true;
  placeholder: any;
  filterIcon = 'pi pi-search';
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: this.configService.getForTenantCode(),
    attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.COLOR
  };

  constructor(
    private messageService: MessageService,
    private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private entityService: EntityService,
    private manageZonesService: ManageZonesService, private configService:ConfigService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
    if (this.field.attributeCode === 'dispatch_zone' || this.field.attributeCode === 'region_geofence') {
      this.getZoneList();
    }
    if (this.field.attributeCode === 'group_color') {
      this.getColorList();
    }
  }


  ngAfterViewInit(): void {
    this.listenForLinkData();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  listenForLinkData() {
    if (!this.field?.link) {
      return;
    }
    this.messageService.message$.pipe(
      filter(v => v.link === this.field.link),
      takeWhile(() => this.alive)
    ).subscribe((v) => {
      this.field.options = v.data
    })
  }

  changedValue(value: string) {
    if (!this.field.provideData) {
      return;
    }
    this.messageService.messageSubject.next({
      link: this.field.attributeCode,
      data: this.field.provideData.filter(v => v.sourceValue === value)
    })
  }

  changedMultiValue(value) {
    if (this.field.attributeCode === AppSettings.PASSENGER_PRIVILEGE_SETTINGS_SHOW_LIST_OF_PREDEFINED_LOCATIONS_ATTRIBUTE_CODE) {
      this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : AppSettings.PASSENGER_PRIVILEGE_SETTINGS_SELECTED_LOCATIONS_ATTRIBUTE_CODE].setValue(value.join(', '));
    }
  }

  onFilter(event) {
    if (event.filter) {
      this.filterIcon = 'pi pi-times'
    } else {
      this.filterIcon = 'pi pi-search';
    }
  }

  onFilterIconClick() {
    if (this.filterIcon.includes('times')) {
      this.miSelect.filterValue = null;
      this.filterIcon = 'pi pi-search';
    }
  }

  getZoneList() {
    const ZoneItemPagination = {
      limit: 100,
      offset: 0,
      searchStr: "",
      defaultSortColumn: "updatedAt",
      defaultSortType: "desc",
    };
    this.manageZonesService.getZonesList(ZoneItemPagination).subscribe((zoneData) => {
      const zonesName = zoneData.data.map(res => ({
        labelKey: `${res.zoneName} (${res.aliasName})`,
        labelValue: `${res.zoneName} (${res.aliasName})`
      }));
      this.field.presetValues = zonesName;
    });
  }

  getColorList() {
    this.entityService.searchAttributeSettings(AppSettings.ENTITY_TYPE.DRIVER_GROUP, this.entityData).subscribe((res: ListResponse) => {
      this.field.presetValues = this.cs.getTableDataForSettings(res);
    })
  }
}

