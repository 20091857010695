import { DatePipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { Tooltip, TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-manual-dispatch-details',
  standalone: true,
  imports: [
    BreadcrumbModule,
    TranslateModule,
    DatePipe,
    DialogModule,
    ButtonModule,
    TooltipModule,
  ],
  templateUrl: './manual-dispatch-details.component.html',
  styleUrl: './manual-dispatch-details.component.scss'
})
export class ManualDispatchDetailsComponent {
  routePath: MenuItem[];
  TRANSPORTATION_TYPE = AppSettings.TRANSPORTATION_TYPE;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;
  miIcons = AppIcons;

  bookingDetails = {
    actionStatus: null,
    deleted: false,
    entityCode: 'booking',
    id: '01J9TRNG70CHKYVJ6MG19W0WPM',
    relatedData: null,
    values: {
      infant_carrier: 3,
      wheelchair: 1,
      owner_pass_type: 'Coach',
      booking_status: '2',
      booking_dropoff_location_address: '06174 Francie Locks, South Nicolasstad, MN 99664',
      booking_pickup_location_address: 'Apt. 377 4062 Schinner Extension, Bruenton, MT 62052',
      booking_stops_location_address: ['Suite 257 42032 Ferry Lodge, Browntown, AL 41985'],
      booster: 2,
      child_seat: 1,
      luggage: 1,
      number_of_passenger: 12,
      owner_pass_full_name: 'Audria Prohaska',
      owner_pass_unique_id: 'PAX6629435',
      pickup_date: 1728712168119,
      pickup_time: 51384773595374,
      transportation_type: 'Airport',
      additional_vehicle_requirement: 2,
      booking_note: 'Hello! How are you?'
    }
  };


  getTotalChildSeats(): number {
    const { child_seat, infant_carrier, booster } = this.bookingDetails.values;
    return (child_seat || 0) + (infant_carrier || 0) + (booster || 0);
  }

  // bookingDetails = {
  //   actionStatus: null,
  //   deleted: false,
  //   entityCode: 'booking',
  //   id: '01J9TRYRWEJ00NFNNEJSMC5T63',
  //   relatedData: null,
  //   values: {
  //     infant_carrier: 0,
  //     wheelchair: 1,
  //     owner_pass_type: 'Player',
  //     booking_status: '11',
  //     booking_dropoff_location_address: 'Apt. 348 417 Runte Cove, New Michaletown, CT 50869',
  //     booking_pickup_location_address: 'Apt. 487 289 Little Viaduct, Port Doriestad, MI 37911',
  //     booking_stops_location_address: ['Suite 701 1956 Fadel Valleys, Annikaside, TN 34780'],
  //     booster: 0,
  //     child_seat: 1,
  //     luggage: 1,
  //     number_of_passenger: 1,
  //     owner_pass_full_name: 'James Walker',
  //     owner_pass_unique_id: 'PAX2316155',
  //     pickup_date: 1728627769684,
  //     pickup_time: 53823875452055,
  //     transportation_type: 'Car',
  //   }
  // };

}
