<div class="grid mt-2 ml-3 overview-details">
    <div class="col-6 md:col-12 lg:col-6 pl-0">

        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>DETAILS</label>
                </div>
                <div (click)="redirectToEdit()" class="edit flex justify-content-center mb-3">
                    <label class="card-action"  translate>vehicle.lbl_edit</label>
                    <i class="mi-lg edit_pencil"></i>
                </div>
            </div>
            @for (field of details.fields; track field; let i = $index) {
            @if(field.attributeCode === 'mobile_number') {
                <div class="grid">
                    <div class="col-6 pt-1">
                        <label class="detail-heading" translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    </div>
                    <div class="col-6 pb-1">
                        @if (countryCodeForMobileNumber) {
                            <app-mi-mobile-value [attributeValues]="attributeValues" [countryCodeForMobileNumber]="countryCodeForMobileNumber" [field]="field"></app-mi-mobile-value>
                        }
                       
                    </div>
                </div>
                <hr class="hr-line">
            } @else  if(field.attributeCode === 'emergency_contact_number') {
                <div class="grid">
                    <div class="col-6 pt-1">
                        <label class="detail-heading" translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    </div>
                    <div class="col-6 pb-1">
                        @if (countryCodeForEmergencyContact) {
                        <app-mi-mobile-value [attributeValues]="attributeValues" [countryCodeForMobileNumber]="countryCodeForEmergencyContact" [field]="field"></app-mi-mobile-value>
                        }
                    </div>
                </div>
                <hr class="hr-line">
            }  @else  if(field.attributeCode === 'nationality') {
                <div class="grid">
                    <div class="col-6 pt-1">
                        <label class="detail-heading" translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    </div>
                    <div class="col-6 pb-1">
                        @if(countryFlagForNationality) {
                            <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + countryFlagForNationality?.toLowerCase()" class="width-18"/>
                        }
                        <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                    </div>
                </div>
                <hr class="hr-line">
            } @else if (field.attributeCode === 'passenger_status') {
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6 pb-1 flex">
                    <div class="status">
                        <span [class]="attributeValues[field.attributeCode]?.toLowerCase()"></span>
                    </div>

                    <label class="detail-value">{{ attributeValues[field.attributeCode] ?
                        attributeValues[field.attributeCode] : ('lbl_empty' | translate) }}</label>
                </div>
            </div>
            <hr class="hr-line">
            } @else if (field.attributeCode === 'passenger_profile_image') {
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6 pb-1">
                    @if (passengerProfileImage) {
                    <!-- <img class="passenger-profile-image" [src]="passengerProfileImage || defaultLoaderImg"> -->
                    <app-mi-image-container [miImage]="passengerProfileImage" miHeight="100" miWidth="100" ></app-mi-image-container>
                    } @else {
                    <span>{{ 'lbl_empty' | translate }}</span>
                    }

                </div>
            </div>

            } @else if (field.attributeCode === 'stay_home_address_section') {

            } @else if (field.attributeCode === 'last_name') {

            } @else if (field.attributeCode === 'date_of_birth') {
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value">{{ dateOfBirth }}</label>
                </div>
            </div>
            <hr class="hr-line">
            }  @else if (field.attributeCode === 'first_name') {
                <div class="grid">
                    <div class="col-6 pt-1">
                        <label class="detail-heading"
                            translate>lbl_name</label>
                    </div>
                    <div class="col-6 pb-1">
                        <label class="detail-value">{{ attributeValues['first_name'] + ' ' +  attributeValues['last_name']}}</label>
                    </div>
                </div>
                <hr class="hr-line">
            } @else if (field.attributeCode === 'passenger_type') {
                <div class="grid">
                    <div class="col-6 pt-1">
                        <label class="detail-heading"
                            translate>lbl_type</label>
                    </div>
                    <div class="col-6 pb-1">
                        <label class="detail-value">{{ attributeValues['passenger_type']}}</label>
                    </div>
                </div>
                <hr class="hr-line">
                <div class="grid">
                    <div class="col-6 pt-1">
                        <label class="detail-heading"
                            translate>lbl_watchlist</label>
                    </div>
                    <div class="col-6 pb-1">
                        <label class="detail-value">{{ attributeValues['watchlist']}}</label>
                        (@if(attributeValues['vip'] === 'Yes') {
                           <label class="detail-value">VIP</label>
                        }
                        @if(attributeValues['seeded'] === 'Yes') {
                            <label class="detail-value">Seeded</label>
                        })
                    </div>
                </div>
                <hr class="hr-line">
            } @else {
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value">{{ attributeValues[field.attributeCode] ?
                        attributeValues[field.attributeCode] : ('lbl_empty' | translate) }}</label>
                </div>
            </div>
            <hr class="hr-line">
            }
            }
        </div>
    </div>
    <div class="col-6 md:col-12 lg:col-6">
        <div class="card p-fluid mr-2">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>passengers.lbl_stay_home_address</label>
                </div>
            </div>
            @if (showAddressButtons) {
            <div class="col-12 pl-0 pt-0 pb-3">
                <p-selectButton [options]="locationTypeList" [(ngModel)]="selectedButton" (onChange)="onChange()"
                    optionLabel="labelKey" optionValue="labelValue" unselectable="true" class="w-full">
                </p-selectButton>
            </div>
            }
            <!-- Google map integration -->
            <div class="map-div" id="map"></div>
            @if (showAddressButtons) {
                <div class="col-12">
                    @for (address of stayHomeAddressArray; track address; let j = $index) {
                        @if (address.location === selectedButton) {
                                @if (address.address.attributeCode !== ADDRESS_FIELDS.SELECT_LOCATION_TYPE) {
                                    @if (address.addressDTO) {
                                        @if (address.addressDTO?.state) {
                                            <div class="col-12">
                                                <label class="value">{{ address.addressDTO?.state.attributeValue }}</label>
                                                <span class="relative span-comma">,</span>
                                                <label class="value">{{ address.addressDTO?.country.attributeValue }}</label>
                                            </div>
                                        }

                                        @if (address.addressDTO?.select_location_address) {
                                            <div class="col-12">
                                                <label class="address-line-1">{{ address.addressDTO?.select_location_address.attributeValue }}</label>
                                            </div>
                                        }
                                        
                                        @if (address.addressDTO?.nearby_landmark) {
                                            <div class="col-12">
                                                <label class="field">Nearby Landmark</label>
                                                <label>&nbsp;&nbsp; - &nbsp;&nbsp;</label>
                                                <label class="value">{{ address.addressDTO?.nearby_landmark.attributeValue }}</label>
                                            </div>
                                        }

                                        @if (address.addressDTO?.instructions) {
                                            <div class="col-12">
                                                <label class="field">Instructions</label>
                                                <label>&nbsp;&nbsp; - &nbsp;&nbsp;</label>
                                                <label class="value">{{ address.addressDTO?.instructions.attributeValue }}</label>
                                            </div>
                                        }
                                    }
                                }
                        }
                    }
                </div>
            }
        </div>
    </div>
</div>
<hr class="ml-3 mr-3">
<div class="col-12 text-center">
    <p-button icon="pi pi-arrow-up" (click)="scrollToTop()" [iconPos]="iconPos"
        class="mr-2 border-600 bg-primary-reverse text-color" label="Back to Top"></p-button>
</div>