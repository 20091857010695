<p-dialog header="header" class="manage-column-dialog" [(visible)]="isShow" [modal]="true" [style]="{ 'max-width': '100%', 'min-width': '60vw', 'height': 'auto' }"   [breakpoints]="{'960px': '80vw', '640px': '90vw'}" 
  [draggable]="false" [resizable]="false" (onHide)="onHide()" responsive="true">
    <ng-template pTemplate="header">
      <span class="text-xl font-bold pl-2 default-text-black-color" translate>manage_column_header</span>
    </ng-template>


    <p class="m-0 pl-2 pt-2 pb-2 default-text-black-color" translate>manage_column_sub_header</p>


    <div cdkDropListGroup class="grid col-12">
      <div pDraggable dragHandle=".p-panel-header" class="col-12 md:col-6">
        <p-panel>
          <ng-template pTemplate="header">
            <div class="flex default-text-black-color"><span class="font-bold">{{'manage_column_available_columns' | translate}}</span>
              <div>&nbsp;({{columns?.length}})</div>
            </div>
          </ng-template>
          <div>
            <span class="p-input-icon-left w-full">
                <i class="{{miIcons.SEARCH}} inline-block wh-10 left-22 relative"></i>
                <input type="text" pInputText (input)="onSearch($event, columns)" [placeholder]="'lbl_search' | translate" />
            </span>
          </div>
          <div class="manage-column-container">
            <div  class="manage-column-list">
              @for (col of columns; track col.header;let i = $index) {
                <div class="manage-column-box">
                <p-checkbox [label]="col.header" [(ngModel)]="col.selected" [binary]="true" (onChange)="pushToVisibleColumns(col,i)"></p-checkbox>
              </div>
              }
              @if (columns?.length === 0) {
                <div class="w-full pt-0 pr-2 pb-0 pl-3">
                  <app-no-result-found></app-no-result-found>    
                </div>         
            }
            </div>
          </div>
        </p-panel>
      </div>

      <div pDraggable dragHandle=".p-panel-header" class="col-12 md:col-6">
        <p-panel>
          <ng-template pTemplate="header">
            <div class="flex justify-content-between w-full">
              <div class="flex default-text-black-color"><span class="font-bold">{{'manage_column_visible_columns' | translate}}</span>
                <div>&nbsp;({{selectedColumns?.length}})</div>
              </div>
              <div class="restore-button default-text-blue-color cursor-pointer" translate (click)="restoreDefault()">lbl_restore_defaults</div>
            </div>
            
          </ng-template>
          <div class="manage-column-container mt-0">
            <div cdkDropList [cdkDropListData]="selectedColumns" class="visible-column-list"
              (cdkDropListDropped)="drop1($event)">

              @for (column of selectedColumns; track column.header; let i = $index ) {
                <div>
                  <div class="manage-column-box align-items-center" cdkDrag>
                    <div class="flex align-items-center justify-content-center">
                      <div class="{{miIcons.GRID_VERTICAL}} wh-24 mr-1"></div>
                      {{ column.header }}
                    </div>
                    <div class="pi pi-times-circle cursor-pointer" (click)="removeFromVisibleColumn(i,column)"></div>
                  </div>
                </div>
              }
            </div>
          </div>
        </p-panel>
      </div>


    </div>

    <ng-template pTemplate="footer" class="pr-5">
      <div class="col-12 relative text-right pr-0 pt-2 pb-2">
        <button pButton class="mr-2 bg-white text-color-secondary border-button" (click)="onHide($event)" [label]="'cancel' | translate"></button>
        <p-button (onClick)="onManageColumn()" [label]="'manage_column_apply_changes' | translate"></p-button>
      </div>
    </ng-template>


</p-dialog>
