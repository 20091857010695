import { ChangeDetectorRef, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MiFormComponent } from 'app/modules/shared/components/mi-form/mi-form.component';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';

@Component({
  selector: 'app-settings-export-account-data',
  standalone: true,
  imports: [InputTextModule, FormsModule, ReactiveFormsModule, TranslateModule, ButtonModule, MiFormComponent, TabViewModule],
  templateUrl: './settings-export-account-data.component.html',
  styleUrl: './settings-export-account-data.component.scss'
})
export class SettingsExportAccountDataComponent {
  exportAccountDataAttributeData: any;
  attributeLabelsForExportAccountDataSettings = {};
  country: Country;
  language: Language;
  exportDataAccountSettingsModuleName = AppSettings.ENTITY_TYPE.EXPORT_ACCOUNT_DATA_SETTINGS;
  exportSettingsEntityData: any;
  exportSettingEntityId: any;
  exportSettingsData: any;

  previousBtnLabel: any = this.cs.getLabel('lbl_cancel');
  saveExportSettingsBtnLabel: any = this.cs.getLabel('settings.lbl_save_export_settings');
  activeIndex = 0;
  attributeValues: any;

  entityId: any;
  showForm: boolean = false;

  constructor(
    private configService: ConfigService,
    public cs: CommonBindingDataService,
    private messageService: MessageService,
    private entityService: EntityService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.getExportAccountDataSettings();
    this.setAppSettings();
    setTimeout(() => {
      this.getAttributesForExportAccountDataSetting();
    }, 500);

    this.setExportSettingsEntityData();

  }

  getExportAccountDataSettings() {
    this.entityService.getEntityDetailsByView(AppSettings.ENTITY_TYPE.EXPORT_ACCOUNT_DATA_SETTINGS, {}).subscribe({
      next: (response: any) => {
        this.entityId = response.entityId;
        this.getEntityDetails();
      },
      error: (error) => {
        this.showForm = true;
      }
    })
  }

  getEntityDetails() {
    this.entityService.getEntity(this.entityId, AppSettings.ENTITY_TYPE.EXPORT_ACCOUNT_DATA_SETTINGS, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe({
      next: (response: any) => {
        this.attributeValues = this.cs.getOrgAttributeValues(response);
        this.showForm = true;
      }
    })
  }

  private setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  onCancel() {

  }

  onNextBtnClick() {
    const btn = document.getElementById('export');
    btn.click();
  }


  getAttributesForExportAccountDataSetting() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_TYPE.EXPORT_ACCOUNT_DATA_SETTINGS,  AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.attributeLabelsForExportAccountDataSettings = this.cs.getAttributeLabels(res);
        this.exportAccountDataAttributeData = this.cs.getOrganizedAttribute(res);

      }
    });
  }

  setExportSettingsEntityData() {
    this.exportSettingsEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.EXPORT_ACCOUNT_DATA_SETTINGS
    }
  }

  saveExportSettings(event) {
    const ExportAccountDataSettingsFormData = event;
    if (this.entityId) {
      this.exportSettingEntityId = this.entityId;
      this.saveExportAccountData(this.exportSettingsEntityData.entityCode, ExportAccountDataSettingsFormData);
    } else {
      this.entityService.createEntities(this.exportSettingsEntityData.entityCode, this.exportSettingsEntityData).subscribe({
        next: (res: any) => {
          this.exportSettingEntityId = res.entityId;
          this.saveExportAccountData(this.exportSettingsEntityData.entityCode, ExportAccountDataSettingsFormData);
        },
        error: (error) => {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
        }
      });
    }

  }

  saveExportAccountData(entityCode, ExportAccountDataSettingsFormData) {
    this.exportSettingsData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.EXPORT_ACCOUNT_DATA_SETTINGS,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: []
    };
    for (const [key, value] of Object.entries(ExportAccountDataSettingsFormData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.exportSettingsData.data.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.exportSettingsData.data = this.cs.mapAttributeIds(this.exportSettingsData.data, this.exportAccountDataAttributeData.tabs);
    console.log(this.exportSettingsData);
    this.entityService.saveAttributeData(entityCode, this.exportSettingEntityId, this.exportSettingsData).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.lbl_export_settings_saved_successfully') });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }
}
